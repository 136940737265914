/*======================================================================================================================================== 
																	LOGO
Creates component to hold the logo																	
========================================================================================================================================*/

import React from "react";
import img from "../../media/RectangleLogo.jpg";
import classes from "./Logo.module.css";

const logo = () => {
	return (
		<div className={classes.Logo}>
			<img src={img} alt="" />
		</div>
	);
};

export default logo;
