/*======================================================================================================================================== 
														MAIN APP COMPONENT
main app component with routes for each component to be rendered in the app								

														DEPENDENCIES:
- react-router				- firebase				
- react-router-dom			- compress.js
- redux						- bootstrap
- react-redux				
========================================================================================================================================*/
import React from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import AuthWrapper from "../components/authWrapper/authWrapper";
import { Component } from "react";
import { connect } from "react-redux";
import "./App.css";
import * as actions from "../store/actions/index";
import Landing from "../components/Landing/Landing";
import MeatStore from "./MeatStore/MeatStore";
import Orders from "./Orders/Orders";
import AuthStore from "./AuthStore/AuthStore";
import Checkout from "./Checkout/Checkout";
import Contact from "./Contact/Contact";
import AdminConsole from "./AdminConsole/AdminConsole";
import MessengerCustomerChat from "react-messenger-customer-chat";

class App extends Component {
  state = {
    statuses: [
      "allOrders",
      "newOrder",
      "awaitingPayment",
      "followUp",
      "completed",
      "inTransit",
      "cancelled",
    ],
  };
  componentDidMount() {
    this.props.listen();
  }

  componentWillUnmount() {
    this.props.detachListen();
  }

  render() {
    return (
      <div className="App">
        <MessengerCustomerChat
          pageId="2023506791311843"
          appId="2924379604539650"
        />
        <Switch>
          <Route exact path="/login" component={AuthWrapper} />

          <Route exact path="/" render={(props) => <Landing {...props} />} />
          <>
            <Route
              exact
              path="/orders"
              render={(props) => <Orders {...props} />}
            />
            <Route
              exact
              path="/auth-store"
              render={(props) => <AuthStore {...props} />}
            />
            {this.state.statuses.map((status) => {
              const filter = status === "allOrders" ? "" : status;
              return (
                <Route
                  exact
                  key={status}
                  path={`/orders/${status}`}
                  render={(props) => <Orders {...props} preFilter={filter} />}
                />
              );
            })}
            <Route
              path="/admin-console"
              render={(props) => (
                <AdminConsole isSignedIn={this.props.isSignedIn} {...props} />
              )}
            />
            <Route path="/contact" component={Contact} />
            <Route
              path="/checkout"
              render={(props) => <Checkout {...props} />}
            />

            <Route
              path="/store"
              render={(props) => (
                <MeatStore
                  logout={this.props.logout}
                  isSignedIn={this.props.isSignedIn}
                  {...props}
                />
              )}
            />
          </>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isSignedIn: state.auth.isSignedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listen: () => dispatch(actions.listen()),
    detachListen: () => dispatch(actions.detachListen()),
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
