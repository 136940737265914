/*======================================================================================================================================== 
															NAVIGATION
Creates nav component to be rendered with working dropdown nav item
Requires bootstrap															
========================================================================================================================================*/
import React, { Component } from "react";
import {
	Button,
	Navbar,
	Nav,
	Container /* , NavDropdown */,
} from "react-bootstrap";
import { connect } from "react-redux";
import Logo from "../Logo/Logo";
import classes from "./Navigation.module.css";
import Backdrop from "../../hoc/Backdrop/Backdrop";
import { NavLink } from "react-router-dom";
import * as actions from "../../store/actions/index";
import { Link } from "react-router-dom";
import LanguageToggler from "../LanguageToggler/LanguageToggler";
import { languageHandler } from "../../util/language/functions";

class Navigation extends Component {
	state = {
		drawerOpen: false,
		alex: "false",
	};

	drawerClosedHandler = () => {
		this.setState({ alex: false });
	};

	navbarToggled = () => {
		let x = this.state.alex;
		x = !this.state.alex;
		this.setState({ alex: x });
	};

	componentDidMount() {
		this.setState({ alex: false });
	}

	render() {
		//conditionally renders the logout button if the user is authenticated//
		let authLinks = null;
		let logoutLink = null;
		// this NavLink may need to be relooked at because we want th onClick to redirect the user after logging out
		if (this.props.isSignedIn) {
			authLinks = (
				<>
					<NavLink
						className={"m-2 nav-item-custom"}
						to="/auth-store"
						exact
						onClick={this.drawerClosedHandler}
					>
						Auth Store
					</NavLink>
					<NavLink
						className={"m-2 nav-item-custom"}
						to="/orders"
						exact
						onClick={this.drawerClosedHandler}
					>
						Orders
					</NavLink>
					<NavLink
						className={"m-2 nav-item-custom"}
						to="/admin-console"
						exact
						onClick={this.drawerClosedHandler}
					>
						Admin Console
					</NavLink>
				</>
			);

			if (this.props.isSignedIn) {
				logoutLink = (
					<Button onClick={this.props.onLogout} className={classes.NavBar}>
						Logout
					</Button>
				);
			}
		}

		return (
			<React.Fragment>
				<Navbar className={classes.NavBar}>
					<Navbar.Brand
						className={classes.NavBar + " navbar-brand mx-auto"}
						href="/"
					>
						<Logo />
					</Navbar.Brand>
				</Navbar>
				<Navbar
					className={classes.NavBar}
					collapseOnSelect
					expanded={this.state.alex}
					expand="md"
				>
					<Navbar.Toggle
						className={classes.NavBarToggle}
						aria-controls="responsive-navbar-nav"
						onClick={this.navbarToggled}
					/>
					<Navbar.Collapse
						id="responsive-navbar-nav"
						className={classes.NavBar}
					>
						<Nav className={"m-auto"}>
							<NavLink
								className={"m-2 nav-item-custom"}
								to="/"
								exact
								onClick={this.drawerClosedHandler}
							>
								{languageHandler("navigation", "home", this.props.language)}
							</NavLink>

							<NavLink
								className={"m-2 nav-item-custom"}
								to="/contact"
								onClick={this.drawerClosedHandler}
							>
								{languageHandler("navigation", "contact", this.props.language)}
							</NavLink>
							<NavLink
								className={"m-2 nav-item-custom"}
								to="/store"
								onClick={this.drawerClosedHandler}
							>
								{languageHandler("navigation", "store", this.props.language)}
							</NavLink>

							{authLinks}
						</Nav>
						{logoutLink}
					</Navbar.Collapse>
				</Navbar>
				<Container fluid className="text-center bg-primary">
					<LanguageToggler />
				</Container>

				<Backdrop
					show={this.state.alex}
					clicked={this.drawerClosedHandler}
				></Backdrop>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isSignedIn: state.auth.isSignedIn,
		logoutLoading: state.auth.logoutLoading,
		logoutError: state.auth.logoutError,
		language: state.store.language,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLogout: () => dispatch(actions.logout()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
