/*======================================================================================================================================== 
                                                                    SPINNER
UI spinner component for loading state                                                                    
========================================================================================================================================*/
import React from 'react';
import classes from './Spinner.module.css'

const spinner = () => (
    <div className={classes.Loader}> Loading </div>
);

export default spinner;