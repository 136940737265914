import React, { Component } from "react";
import { Button, Container, Row, Col, Alert } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";

import { sendEmailHandler } from "../../util/mail/mail";
import { languageHandler } from "../../util/language/functions";
import { connect } from "react-redux";
import schema from "../../util/language/schema.json";

class ContactForm extends Component {
  state = {
    ContactInfo: {
      Name: "",
      Email: "",
      CellphoneNumber: "",
      PaymentMethod: "",
      Address: {
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        ChineseAddress: "",
        County: "",
      },
      Message: "",
    },
    touched: {
      Name: false,
      Email: false,
      CellphoneNumber: false,
      Message: false,
      AddressLine1: false,
      AddressLine2: false,
      AddressLine3: false,
      ChineseAddress: false,
      County: false,
    },
    messageSubmitted: false,
  };

  onInputChangeHandler = (event) => {
    const inputForm = { ...this.state.ContactInfo };
    const stateValue = event.target.name;
    inputForm[stateValue] = event.target.value;
    this.setState({ ContactInfo: inputForm });
    this.getAlert();
  };

  checkIsValid = (event) => {
    event.preventDefault();
    let hasBeenTouched = { ...this.state.touched };
    hasBeenTouched[event.target.name] = true;
    this.setState({ touched: hasBeenTouched });
  };

  onAddressChangeHandler = (event) => {
    const inputForm = { ...this.state.ContactInfo };
    const address = { ...this.state.ContactInfo.Address };
    const stateValue = event.target.name;
    address[stateValue] = event.target.value;
    inputForm.Address = address;
    this.setState({ ContactInfo: inputForm });
    this.getAlert();
  };

  sendEmailHandler = () => {
    let templateParams = {
      from_name: this.state.ContactInfo.Name,
      message: this.state.ContactInfo.Message,
      reply_to: this.state.ContactInfo.Email,
    };
    sendEmailHandler(templateParams, "template_1mmdmxq");
    window.alert("your message was submitted");
    this.setState({ messageSubmitted: true });
  };

  getAlert = () => {
    let alert = [];
    let finalText = "";

    if (this.state.ContactInfo.Name === "") {
      alert.push(languageHandler("alert", "name-alert", this.props.language));
    }
    if (this.state.ContactInfo.Email === "") {
      alert.push(languageHandler("alert", "email-alert", this.props.language));
    }
    if (this.state.ContactInfo.CellphoneNumber === "") {
      alert.push(
        languageHandler("alert", "cell-number-alert", this.props.language)
      );
    }

    if (
      this.props.shippingOption !== "PickUp" &&
      this.state.ContactInfo.Address.County === ""
    ) {
      alert.push(languageHandler("alert", "county-alert", this.props.language));
    }

    if (this.state.ContactInfo.PaymentMethod === "") {
      alert.push(
        languageHandler("alert", "payment-method-alert", this.props.language)
      );
    }

    if (alert.length > 1) {
      let alertText1 = alert.splice(0, alert.length - 1).join(", ");
      let alertText2 = alert[alert.length - 1];
      finalText = "*"
        .concat(alertText1)
        .concat(" ")
        .concat(languageHandler("alert", "conjunction", this.props.language))
        .concat(" ")
        .concat(alertText2)
        .concat(" ")
        .concat(
          languageHandler(
            "alert",
            "multiple-required-alert",
            this.props.language
          )
        );
    } else if (alert.length === 1) {
      finalText = alert[0]
        .concat(" ")
        .concat(
          languageHandler("alert", "single-required-alert", this.props.language)
        );
    }

    if (alert.length !== 0) {
      return <Alert variant="danger">{finalText}</Alert>;
    }
  };

  componentDidMount = () => {
    this.getAlert();
  };

  getClassName = (value) => {
    switch (value) {
      case "Name":
        return this.state.ContactInfo.Name === "" && this.state.touched.Name
          ? "form-control border-red"
          : "form-control";

      case "Email":
        return this.state.ContactInfo.Email === "" && this.state.touched.Email
          ? "form-control border-red"
          : "form-control";

      case "CellphoneNumber":
        return this.state.ContactInfo.CellphoneNumber === "" &&
          this.state.touched.CellphoneNumber
          ? "form-control border-red"
          : "form-control";

      case "Message":
        return this.state.ContactInfo.Message === "" &&
          this.state.touched.Message
          ? "form-control border-red"
          : "form-control";

      case "AddressLine2":
        return this.state.ContactInfo.Address.AddressLine2 === "" &&
          this.state.touched.AddressLine2
          ? "form-control border-red"
          : "form-control";

      case "AddressLine3":
        return this.state.ContactInfo.Address.AddressLine3 === "" &&
          this.state.touched.AddressLine3
          ? "form-control border-red"
          : "form-control";

      /* case "City":
				return this.state.ContactInfo.Address.City === "" &&
					this.state.touched.City
					? "form-control border-red"
					: "form-control"; */

      case "County":
        return this.state.ContactInfo.Address.County === "" &&
          this.state.touched.County
          ? "form-control border-red"
          : "form-control";

      default:
        return "form-control";
    }
  };

  paymentMethodWarning = (
    <span className="text-danger ml-2">
      *
      {languageHandler(
        "contact",
        "form-payment-placeholder",
        this.props.language
      )}
    </span>
  );
  render() {
    let isPickupValid =
      this.state.ContactInfo.Name !== "" &&
      this.state.ContactInfo.Email !== "" &&
      this.state.ContactInfo.CellphoneNumber !== "" &&
      this.state.ContactInfo.PaymentMethod !== "";

    let isValid =
      this.state.ContactInfo.PaymentMethod !== "" &&
      /* this.state.ContactInfo.Address.AddressLine2 !== "" &&
			this.state.ContactInfo.Address.AddressLine3 !== "" &&
			this.state.ContactInfo.Address.City !== "" && */
      this.state.ContactInfo.Address.County !== "" &&
      this.state.ContactInfo.CellphoneNumber !== "";
    if (this.state.ContactInfo.PaymentMethod !== "") {
      this.paymentMethodWarning = null;
    } // What it do

    let invalid =
      this.props.shippingOption === "RegularAM" ||
      this.props.shippingOption === "RegularPM"
        ? !isValid
        : !isPickupValid;

    let countyOptions = schema["contact"]["form-county-options"].map(
      (option) => {
        return (
          <option key={option[0]} value={option[0]}>
            {this.props.language === 0 ? option[0] : option[1]}
          </option>
        );
      }
    );

    return (
      <Container className="text-dark bg-white checkout-container contact-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <form>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={!this.props.isInContactUs ? 6 : 12}
                  lg={!this.props.isInContactUs ? 6 : 12}
                  xl={!this.props.isInContactUs ? 6 : 12}
                >
                  {!this.props.isInContactUs ? (
                    <h2>
                      {languageHandler(
                        "contact",
                        "form-contact-info-heading",
                        this.props.language
                      )}
                    </h2>
                  ) : null}
                  {this.props.isInContactUs ? (
                    <>
                      <p className="text-primary text-center">
                        {languageHandler(
                          "contact",
                          "form-paragraph-text",
                          this.props.language
                        )}
                      </p>
                    </>
                  ) : null}
                  <div className="form-group">
                    <label>
                      {languageHandler(
                        "contact",
                        "form-name-label",
                        this.props.language
                      )}
                    </label>
                    <input
                      className={this.getClassName("Name")} /* {`form-control ${
												this.state.ContactInfo.Name === "" && "border-red"
											}`} */
                      type="text"
                      name="Name"
                      placeholder={languageHandler(
                        "contact",
                        "form-email-placeholder",
                        this.props.language
                      )}
                      value={this.state.ContactInfo.Name}
                      onChange={this.onInputChangeHandler}
                      onClick={(event) => this.checkIsValid(event)}
                    ></input>
                  </div>

                  <div className="form-group">
                    <label>
                      {languageHandler(
                        "contact",
                        "form-email-label",
                        this.props.language
                      )}
                    </label>
                    <input
                      className={this.getClassName("Email")}
                      type="email"
                      name="Email"
                      placeholder={languageHandler(
                        "contact",
                        "form-email-placeholder",
                        this.props.language
                      )}
                      value={this.state.ContactInfo.Email}
                      onChange={this.onInputChangeHandler}
                      onClick={(event) => this.checkIsValid(event)}
                    ></input>
                  </div>

                  {this.props.isInContactUs ? (
                    <div className="form-group">
                      <label>
                        {languageHandler(
                          "contact",
                          "form-contact-message-label",
                          this.props.language
                        )}
                      </label>
                      <textarea
                        className={this.getClassName("Message")}
                        type="text"
                        name="Message"
                        placeholder={languageHandler(
                          "contact",
                          "form-email-placeholder",
                          this.props.language
                        )}
                        value={this.state.ContactInfo.Message}
                        onChange={this.onInputChangeHandler}
                        onClick={(event) => this.checkIsValid(event)}
                      ></textarea>
                    </div>
                  ) : null}

                  {!this.props.isInContactUs ? (
                    <>
                      <div className="form-group">
                        <label>
                          {languageHandler(
                            "contact",
                            "form-number-label",
                            this.props.language
                          )}
                        </label>
                        <input
                          className={this.getClassName("CellphoneNumber")}
                          type="text"
                          name="CellphoneNumber"
                          placeholder={languageHandler(
                            "contact",
                            "form-email-placeholder",
                            this.props.language
                          )}
                          value={this.state.ContactInfo.CellphoneNumber}
                          onChange={this.onInputChangeHandler}
                          onClick={(event) => this.checkIsValid(event)}
                        ></input>
                      </div>

                      <div className="form-group">
                        <label>
                          {languageHandler(
                            "contact",
                            "form-payment-label",
                            this.props.language
                          )}
                        </label>
                        <select
                          className={`form-control ${
                            this.state.ContactInfo.PaymentMethod === "" &&
                            "border-red"
                          }`}
                          type="select"
                          name="PaymentMethod"
                          placeholder={languageHandler(
                            "contact",
                            "form-payment-placeholder",
                            this.props.language
                          )}
                          value={this.state.ContactInfo.PaymentMethod}
                          onChange={this.onInputChangeHandler}
                        >
                          {" "}
                          <option disabled value="">
                            {languageHandler(
                              "contact",
                              "form-payment-placeholder",
                              this.props.language
                            )}
                          </option>
                          {this.props.shippingOption &&
                          this.props.shippingOption !== "PickUp" ? (
                            <option value="Cash on Delivery">
                              {languageHandler(
                                "contact",
                                "form-payment-options-cod",
                                this.props.language
                              )}
                            </option>
                          ) : (
                            <option value="Cash on Delivery">
                              {languageHandler(
                                "contact",
                                "form-payment-options-cop",
                                this.props.language
                              )}
                            </option>
                          )}
                          <option value="Bank Transfer">
                            {languageHandler(
                              "contact",
                              "form-payment-options-bank",
                              this.props.language
                            )}
                          </option>
                        </select>{" "}
                        {/* {this.paymentMethodWarning} */}
                      </div>
                      <div className="form-group">
                        <label>
                          {languageHandler(
                            "contact",
                            "form-checkout-message-label",
                            this.props.language
                          )}
                        </label>
                        <textarea
                          className={this.getClassName("Message")}
                          type="text"
                          name="Message"
                          placeholder={languageHandler(
                            "contact",
                            "form-checkout-message-placeholder",
                            this.props.language
                          )}
                          value={this.state.ContactInfo.Message}
                          onChange={this.onInputChangeHandler}
                        ></textarea>
                      </div>
                    </>
                  ) : null}
                </Col>

                {!this.props.isInContactUs &&
                this.props.shippingOption !== "PickUp" ? (
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h2>
                      {languageHandler(
                        "contact",
                        "form-shipping-address-heading",
                        this.props.language
                      )}
                    </h2>
                    {this.props.language === 0 && (
                      <>
                        {" "}
                        <div className="form-group">
                          <label>
                            {languageHandler(
                              "contact",
                              "form-address1-label",
                              this.props.language
                            )}
                          </label>
                          <input
                            className={`form-control`}
                            type="text"
                            name="AddressLine1"
                            placeholder="eg. floor 2, no. 10"
                            value={this.state.ContactInfo.Address.AddressLine1}
                            onChange={this.onAddressChangeHandler}
                          ></input>
                        </div>
                        <div className="form-group">
                          <label>
                            {languageHandler(
                              "contact",
                              "form-address2-label",
                              this.props.language
                            )}
                          </label>
                          <input
                            className={this.getClassName("AddressLine2")}
                            type="text"
                            name="AddressLine2"
                            placeholder="eg. Section 2, 227 Zhongshan Road"
                            value={this.state.ContactInfo.Address.AddressLine2}
                            onChange={this.onAddressChangeHandler}
                            /* onClick={(event) => this.checkIsValid(event)} */
                          ></input>
                        </div>
                        <div className="form-group">
                          <label>
                            {languageHandler(
                              "contact",
                              "form-address3-label",
                              this.props.language
                            )}
                          </label>
                          <input
                            className={this.getClassName("AddressLine3")}
                            type="text"
                            name="AddressLine3"
                            placeholder="eg. Daan District or Hualien City"
                            value={this.state.ContactInfo.Address.AddressLine3}
                            onChange={this.onAddressChangeHandler}
                            /* onClick={(event) => this.checkIsValid(event)} */
                          ></input>
                        </div>
                      </>
                    )}

                    {/* <div className="form-group">
											<label>County</label>
											<input
												className={this.getClassName("County")}
												type="text"
												name="County"
												placeholder="(required)"
												value={this.state.ContactInfo.Address.County}
												onChange={this.onAddressChangeHandler}
												onClick={(event) => this.checkIsValid(event)}
											></input>
										</div> */}
                    <div className="form-group">
                      <label>
                        {languageHandler(
                          "contact",
                          "form-county-label",
                          this.props.language
                        )}
                      </label>
                      <select
                        name="County"
                        type="select"
                        value={this.state.ContactInfo.Address.County}
                        placeholder={languageHandler(
                          "contact",
                          "form-county-placeholder",
                          this.props.language
                        )}
                        className={this.getClassName("County")}
                        onChange={this.onAddressChangeHandler}
                        onClick={(event) => this.checkIsValid(event)}
                      >
                        <option disabled value="">
                          {languageHandler(
                            "contact",
                            "form-county-placeholder",
                            this.props.language
                          )}
                        </option>
                        {countyOptions}
                      </select>
                    </div>

                    <div className="form-group">
                      <label>
                        {languageHandler(
                          "contact",
                          "form-chinese-address-label",
                          this.props.language
                        )}
                      </label>
                      <input
                        className={this.getClassName("ChineseAddress")}
                        type="text"
                        name="ChineseAddress"
                        placeholder={languageHandler(
                          "contact",
                          "form-optional-placeholder",
                          this.props.language
                        )}
                        value={this.state.ContactInfo.Address.ChineseAddress}
                        onChange={this.onAddressChangeHandler}
                      ></input>
                    </div>

                    {/* replacing with message on other column for service request to allow a order message 
										<div className="form-group">
											<label>
												{languageHandler(
													"contact",
													"form-checkout-message-label",
													this.props.language
												)}
											</label>
											<textarea
												className={this.getClassName("Message")}
												type="text"
												name="Message"
												placeholder={languageHandler(
													"contact",
													"form-checkout-message-placeholder",
													this.props.language
												)}
												value={this.state.ContactInfo.Message}
												onChange={this.onInputChangeHandler}
											></textarea>
										</div> */}
                  </Col>
                ) : null}
              </Row>
            </form>
          </Col>
        </Row>
        {this.props.isInContactUs ? (
          <Row className="justify-content-center">
            <Button
              className="m-2"
              variant="success"
              type="submit"
              onClick={this.sendEmailHandler}
            >
              {languageHandler(
                "contact",
                "form-submit-message-button-text",
                this.props.language
              )}
            </Button>
          </Row>
        ) : null}
        {!this.props.isInContactUs ? (
          <>
            {<Row className="justify-content-center">{this.getAlert()}</Row>}
            <Row className="justify-content-center">
              <Button className="m-2" variant="danger" type="submit">
                <Link className="text-white text-decoration-none" to="/store">
                  {languageHandler(
                    "contact",
                    "form-return-button-text",
                    this.props.language
                  )}
                </Link>
              </Button>

              <Button
                className="m-2"
                variant="success"
                type="submit"
                disabled={
                  this.props.shippingOption === "RegularAM" ||
                  this.props.shippingOption === "RegularPM"
                    ? !isValid
                    : !isPickupValid
                }
                onClick={(event, contactDetails) =>
                  this.props.handleSubmitOrder(event, this.state.ContactInfo)
                }
              >
                {languageHandler(
                  "contact",
                  "form-submit-order-button-text",
                  this.props.language
                )}
              </Button>
            </Row>
          </>
        ) : null}
        {this.state.messageSubmitted ? <Redirect to="/" /> : null}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.store.language,
  };
};

export default connect(mapStateToProps)(ContactForm);
