/*======================================================================================================================================== 
														AUTH ACTIONS
actions for auth login to be used in the login component 														
========================================================================================================================================*/
import * as actionTypes from "./action-types";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
} from "firebase/auth";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

//action creator for auth Success case
export const authSuccess = (user) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    user: user,
  };
};

//action creator for auth fail case

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

//logs the user out automatically after the data is stored on the local storage
/* 
export const checkAuthTimeout = (expirationTime) => {
	return (dispatch) => {
		setTimeout(() => {
			dispatch(logout());
		}, expirationTime * 1000);
	};
}; */

//action creator, clears the local storage and logs the user out when executed

//running async code and sending a post to the SIGN IN end point for firebase. We capture the token and user ID from the successful post.
export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());
    //create the auth data object to post to firebase as required. This email and password is coming from the login form inputs.
    const fauth = getAuth();
    setPersistence(fauth, browserSessionPersistence)
      .then(() => {
        signInWithEmailAndPassword(fauth, email, password)
          .then((user) => {
            return dispatch(authSuccess(user));
          })
          .catch((error) => {
            return dispatch(authFail(error));
          });
      })
      .catch((error) => dispatch(authFail(error)));

    /* firebase
			.auth()
			.setPersistence(firebase.auth.Auth.Persistence.SESSION)
			.then(() => {
				// Existing and future Auth states are now persisted in the current
				// session only. Closing the window would clear any existing state even
				// if a user forgets to sign out.
				// ...
				// New sign-in will be persisted with session persistence.
				return firebase
					.auth()
					.signInWithEmailAndPassword(email, password)
					.then((user) => {
						dispatch(authSuccess(user));
					})
					.catch((error) => dispatch(authFail(error)));
			})
			.catch((error) => {
				// Handle Errors here.

				dispatch(authFail(error));
			}); */
  };
};

export const logoutStart = () => {
  return {
    type: actionTypes.LOGOUT_START,
    logoutLoading: true,
  };
};

//action creator for auth Success case
export const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    isSignedIn: false,
    logoutLoading: false,
  };
};

//action creator for auth fail case

export const logoutFail = (error) => {
  return {
    type: actionTypes.LOGOUT_FAIL,
    error: error,
    logoutLoading: false,
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(logoutStart());
    const fauth = getAuth();
    signOut(fauth)
      .then(() => dispatch(logoutSuccess()))
      .catch((error) => dispatch(authFail(error)));
    /* firebase
			.auth()
			.signOut()
			.then(() => {
				dispatch(logoutSuccess());
			})
			.catch((error) => {
				dispatch(authFail(error));
			}); */
  };
};

export const listeningStart = () => {
  return {
    type: actionTypes.LISTENING_START,
    listening: true,
  };
};

//action creator for auth Success case
export const listeningSuccess = (user) => {
  return {
    type: actionTypes.LISTENING_SUCCESS,
    isSignedIn: !!user,
    user: user,
  };
};

export const listen = () => {
  return (dispatch) => {
    dispatch(listeningStart());
    const fauth = getAuth();
    onAuthStateChanged(fauth, (user) => {
      if (user) {
        dispatch(listeningSuccess(user));
      } else {
        dispatch(listeningFail("No user returned from firebase"));
      }
    });
    /* firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				dispatch(listeningSuccess(user));
			} else {
				dispatch(listeningFail("No user returned from firebase"));
			}
		}); */
  };
};

//action creator for auth fail case

export const listeningFail = (error) => {
  return {
    type: actionTypes.LISTENING_FAIL,
    error: error,
    listening: false,
  };
};

export const detachListen = () => {
  return (dispatch) => {
    const fauth = getAuth();
    onAuthStateChanged(
      fauth,
      null,
      (error) => dispatch(listeningFail(error)),
      () => dispatch(detachListeningSuccess())
    );
    /* firebase
			.auth()
			.onAuthStateChanged()
			.then(dispatch(detachListeningSuccess))
			.catch((error) => {
				dispatch(listeningFail(error));
			}); */
  };
};

export const detachListeningSuccess = () => {
  return {
    type: actionTypes.DETACH_LISTENING_SUCCESS,
    isSignedIn: false,
    user: null,
  };
};

/* 
//authChechState executed at mounting to check if the stored token is still valid. if it is, then we are logged in and the new expiration date is set.
//if there is no token or the token is expired. Logout is executed.
export const authCheckState = () => {
	return (dispatch) => {
		const token = localStorage.getItem("token");
		if (!token) {
			dispatch(logout());
		} else {
			const expirationDate = new Date(localStorage.getItem("expirationDate"));
			if (expirationDate <= new Date()) {
				dispatch(logout());
			} else {
				const userId = localStorage.getItem("userId");
				dispatch(authSuccess(token, userId));
				dispatch(
					checkAuthTimeout(
						(expirationDate.getTime() - new Date().getTime()) / 1000
					)
				);
			}
		}
	};
}; */
