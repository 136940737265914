/*======================================================================================================================================== 
													STORE REDUCER
Reducer functions to get products in the store as well as to add new products to the store
========================================================================================================================================*/

import * as actionTypes from "../actions/action-types";
import { updateObject } from "../utility";

const initialState = {
	products: {
		index: {
			Id: null,
			Image: "",
			Price: null,
			Qty: null,
			SalesPercentage: null,
			Title: "",
			Type: "",
		},
	},
	loading: true,
	error: null,
	keyArr: [],
	uniqueProdTypeArr: [],
	basket: [],
	basketItemQty: 0,
	orderPosted: false,
	orderPosting: false,
	orderPostError: null,
	totalPrice: 0,
	language: 0,
};

//language translations function
const toggleLanguage = (state, action) => {
	return updateObject(state, {
		language: action.language,
	});
};

// cart functions
const incrementCartItem = (state, action) => {
	return updateObject(state, {
		basket: action.basket,
		basketItemQty: action.basketItemQty,
		totalPrice: action.totalPrice,
	});
};

const decrementCartItem = (state, action) => {
	return updateObject(state, {
		basket: action.basket,
		basketItemQty: action.basketItemQty,
		totalPrice: action.totalPrice,
	});
};

const removeCartItem = (state, action) => {
	return updateObject(state, {
		basket: action.basket,
		basketItemQty: action.basketItemQty,
		totalPrice: action.totalPrice,
	});
};

const emptyCart = (state, action) => {
	return updateObject(state, {
		basket: action.basket,
		basketItemQty: action.basketItemQty,
		totalPrice: 0,
	});
};

const addToCartClicked = (state, action) => {
	return updateObject(state, {
		basket: action.basket,
		basketItemQty: action.basketItemQty,
		totalPrice: action.totalPrice,
	});
};

//fetch product functions
const fetchProductsStart = (state, action) => {
	return updateObject(state, {
		loading: true,
		orderPostError: null,
		orderPosted: false,
	});
};

const fetchProductsSuccess = (state, action) => {
	return updateObject(state, {
		products: action.products,
		keyArr: action.keyArr,
		uniqueProdTypeArr: action.uniqueProdTypeArr,
		loading: false,
	});
};

const fetchProductsFail = (state, action) => {
	return updateObject(state, {
		error: true,
	});
};

//submit order functions
const submitOrderStart = (state, action) => {
	return updateObject(state, {
		orderPostError: null,
		orderPosted: false,
		orderPosting: true,
	});
};
const submitOrderSuccess = (state, action) => {
	return updateObject(state, {
		orderPosted: true,
		orderPosting: false,
		basket: [],
		totalPrice: 0,
		orderPostError: null,
	});
};
const submitOrderFail = (state, action) => {
	return updateObject(state, {
		orderPosted: false,
		orderPostError: action.error,
		orderPosting: false,
	});
};

//update total price functions
const updateTotalPrice = (state, action) => {
	return updateObject(state, {
		totalPrice: action.totalPrice,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_PRODUCTS_START:
			return fetchProductsStart(state, action);
		case actionTypes.FETCH_PRODUCTS_SUCCESS:
			return fetchProductsSuccess(state, action);
		case actionTypes.FETCH_PRODUCTS_FAIL:
			return fetchProductsFail(state, action);
		case actionTypes.ADD_TO_CART:
			return addToCartClicked(state, action);
		case actionTypes.INCREMENT_CART_ITEM:
			return incrementCartItem(state, action);
		case actionTypes.DECREMENT_CART_ITEM:
			return decrementCartItem(state, action);
		case actionTypes.REMOVE_CART_ITEM:
			return removeCartItem(state, action);
		case actionTypes.EMPTY_CART:
			return emptyCart(state, action);
		case actionTypes.SUBMIT_ORDER_START:
			return submitOrderStart(state, action);
		case actionTypes.SUBMIT_ORDER_SUCCESS:
			return submitOrderSuccess(state, action);
		case actionTypes.SUBMIT_ORDER_FAIL:
			return submitOrderFail(state, action);
		case actionTypes.TOGGLE_LANGUAGE:
			return toggleLanguage(state, action);
		case actionTypes.UPDATE_TOTAL_PRICE:
			return updateTotalPrice(state, action);

		default:
			return state;
	}
};

export default reducer;
