import React from "react";
import Jumbotron from "../Jumbotron/Jumbotron";
import classes from "./Landing.module.css";
import firevid from "../../assets/images/fire.mp4";
import { languageHandler } from "../../util/language/functions";
import { connect } from "react-redux";

const landing = (props) => {
	return (
		<div className={classes.Landing}>
			<video className={classes.LandingVideo} loop autoPlay muted>
				<source src={firevid} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
			<Jumbotron
				style={{ zIndex: "1000" }}
				heading={languageHandler("landing", "title", props.language)} // must check the font for this
				text={languageHandler("landing", "subtitle", props.language)}
				{...props}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		language: state.store.language,
	};
};

export default connect(mapStateToProps, null)(landing);
