import React from "react";
import classes from "./Footer.module.css";
import {
	Envelope,
	Facebook,
	TelephoneFill,
	HouseDoorFill,
} from "react-bootstrap-icons";

import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { languageHandler } from "../../util/language/functions";
import { connect } from "react-redux";

const Footer = (props) => {
	return (
		<>
			<Container>
				<Row className={"justify-content-center text-center pt-5"}>
					<h3 className="text-white">
						{languageHandler("footer", "title", props.language)}
					</h3>
				</Row>
				<Row className="justify-content-center pt-3 pb-5 ">
					<Link className="mr-3" to="/">
						<div className={`${classes.TelephoneContainer} bg-white`}>
							<HouseDoorFill color="#911024" size={35} />
						</div>
					</Link>
					<a
						className="mr-3"
						target="_blank"
						rel="noreferrer"
						href="https://www.facebook.com/braaiguytw"
					>
						<Facebook color="#FFFFFF" size={50} />
					</a>
					<a
						className="mr-3"
						target="_blank"
						rel="noreferrer"
						href="mailto:derik@thebraaiguy.com"
					>
						<div className={`${classes.TelephoneContainer} bg-white`}>
							<Envelope color="#911024" size={35} />
						</div>
					</a>
					<a
						className="mr-3"
						target="_blank"
						rel="noreferrer"
						href="tel:00886958055358"
					>
						<div className={`${classes.TelephoneContainer} bg-white`}>
							<TelephoneFill color="#911024" size={35} />
						</div>
					</a>
				</Row>
			</Container>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		language: state.store.language,
	};
};

export default connect(mapStateToProps)(Footer);
