/*======================================================================================================================================== 
                                            ACTION TYPES
Action types for all actions 
========================================================================================================================================*/
export const TOGGLE_LANGUAGE = "TOGGLE_LANGUAGE";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const LISTENING_START = "LISTENING_START";
export const LISTENING_SUCCESS = "LISTENING_SUCCESS";
export const LISTENING_FAIL = "LISTENING_FAIL";

export const DETACH_LISTENING_SUCCESS = "DETACH_LISTENING_SUCCESS";

export const FETCH_ORDERS_START = "FETCH_ORDERS_START";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";

export const FETCH_PRODUCTS_START = "FETCH_PRODUCTS_START";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAIL = "FETCH_PRODUCTS_FAIL";

export const ADD_NEW_PRODUCT_SUCCESS = "ADD_NEW_PRODUCT_SUCCESS";
export const ADD_NEW_PRODUCT_FAIL = "ADD_NEW_PRODUCT_FAIL";
export const ADD_NEW_PRODUCT_START = "ADD_NEW_PRODUCT_START";

export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAIL = "EDIT_PRODUCT_FAIL";
export const EDIT_PRODUCT_START = "EDIT_PRODUCT_START";

export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";
export const DELETE_PRODUCT_START = "DELETE_PRODUCT_START";

export const ADD_TO_CART = "ADD_TO_CART";
export const INCREMENT_CART_ITEM = "INCREMENT_CART_ITEM";
export const DECREMENT_CART_ITEM = "DECREMENT_CART_ITEM";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const EMPTY_CART = "EMPTY_CART";

export const SUBMIT_ORDER_START = "SUBMIT_ORDER_START";
export const SUBMIT_ORDER_SUCCESS = "SUBMIT_ORDER_SUCCESS";
export const SUBMIT_ORDER_FAIL = "SUBMIT_ORDER_FAIL";

export const UPDATE_ORDER_STATUS_START = "UPDATE_ORDER_STATUS_START";
export const UPDATE_ORDER_STATUS_FAIL = "UPDATE_ORDER_STATUS_FAIL";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";

export const ADD_PROMO_CODE_SUCCESS = "ADD_PROMO_CODE_SUCCESS";
export const ADD_PROMO_CODE_FAIL = "ADD_PROMO_CODE_FAIL";
export const ADD_PROMO_CODE_START = "ADD_PROMO_CODE_START";

export const FETCH_PROMO_CODE_SUCCESS = "FETCH_PROMO_CODE_SUCCESS";
export const FETCH_PROMO_CODE_FAIL = "FETCH_PROMO_CODE_FAIL";
export const FETCH_PROMO_CODE_START = "FETCH_PROMO_CODE_START";

export const EDIT_PROMO_CODE_SUCCESS = "EDIT_PROMO_CODE_SUCCESS";
export const EDIT_PROMO_CODE_FAIL = "EDIT_PROMO_CODE_FAIL";
export const EDIT_PROMO_CODE_START = "EDIT_PROMO_CODE_START";

export const DELETE_PROMO_CODE_SUCCESS = "DELETE_PROMO_CODE_SUCCESS";
export const DELETE_PROMO_CODE_FAIL = "DELETE_PROMO_CODE_FAIL";
export const DELETE_PROMO_CODE_START = "DELETE_PROMO_CODE_START";

export const ADD_FILTER_SUCCESS = "ADD_FILTER_SUCCESS";
export const ADD_FILTER_FAIL = "ADD_FILTER_FAIL";
export const ADD_FILTER_START = "ADD_FILTER_START";

export const FETCH_FILTERS_SUCCESS = "FETCH_FILTERS_SUCCESS";
export const FETCH_FILTERS_FAIL = "FETCH_FILTERS_FAIL";
export const FETCH_FILTERS_START = "FETCH_FILTERS_START";

export const DELETE_FILTER_SUCCESS = "DELETE_FILTER_SUCCESS";
export const DELETE_FILTER_FAIL = "DELETE_FILTER_FAIL";
export const DELETE_FILTER_START = "DELETE_FILTER_START";
export const UPDATE_TOTAL_PRICE = "UPDATE_TOTAL_PRICE";
