/*======================================================================================================================================== 
															SPINNER 
spinner component to show loading state to user if content is loading
========================================================================================================================================*/
import React from "react";
import classes from "./Spinner.module.css";

const Spinner = () => {
	return <div className={classes.Loader}>Loading...</div>;
};

export default Spinner;
