/*======================================================================================================================================== 
                                                        PRODUCT MODAL
shows a modal with the product info of the clicked on product                                                
========================================================================================================================================*/
import React from "react";
import { Modal } from "react-bootstrap";
import AddToCart from "../../containers/AddToCart/AddToCart";
import { connect } from "react-redux";
import { languageHandler } from "../../util/language/functions";

const productModal = (props) => {
	const prodObj = props.products;
	const prodKey = props.prodKey;

	return (
		<Modal
			show={props.show}
			onHide={props.handleClose}
			animation={false}
			className={"d-flex justify-content-center"}
		>
			<Modal.Header closeButton>
				<Modal.Title className="text-primary">
					{" "}
					<h3>
						{props.prodKey && props.language === 0
							? Array.isArray(prodObj[prodKey].Title)
								? prodObj[prodKey].Title[0]
								: prodObj[prodKey].Title
							: props.prodKey && props.language === 1
							? Array.isArray(prodObj[prodKey].Title)
								? prodObj[prodKey].Title[1]
								: prodObj[prodKey].Title
							: null}
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{/* <div  className={"d-flex justify-content-center"}>
              <strong>{props.prodKey ? prodObj[prodKey].Title : null}</strong>
            </div> */}

				<div className={"d-flex justify-content-center"}>
					{props.prodKey ? (
						<img
							src={prodObj[prodKey].Image}
							alt={"product"}
							style={{ maxWidth: "150px", margin: "0 auto" }}
						/>
					) : null}
				</div>

				<div>
					<h5>
						<strong className="text-primary ">
							{props.prodKey && props.language === 0
								? Array.isArray(prodObj[prodKey].Title)
									? prodObj[prodKey].Title[0]
									: prodObj[prodKey].Title
								: props.prodKey && props.language === 1
								? Array.isArray(prodObj[prodKey].Title)
									? prodObj[prodKey].Title[1]
									: prodObj[prodKey].Title
								: null}
						</strong>
					</h5>
					<div>
						<h5>
							<strong className="text-primary">
								&#78;&#84;&#36; {props.prodKey ? prodObj[prodKey].Price : null}
							</strong>
						</h5>
					</div>
					{props.prodKey && props.language === 0
						? Array.isArray(prodObj[prodKey].Description)
							? prodObj[prodKey].Description[0]
							: prodObj[prodKey].Description
						: props.prodKey && props.language === 1
						? Array.isArray(prodObj[prodKey].Description)
							? prodObj[prodKey].Description[1]
							: prodObj[prodKey].Description
						: null}
				</div>
				<div className={"text-primary"}>
					{props.prodKey && prodObj[prodKey].Qty < 5 ? (
						<div>
							*
							{languageHandler(
								"product-modal",
								"stock-warning-text",
								props.language
							)}
						</div>
					) : null}
				</div>
			</Modal.Body>
			<AddToCart
				productId={props.prodKey ? prodObj[prodKey].Id : null}
				//addToCartClicked={(prodKey, qty, basket) => props.addToCartClicked(prodKey, qty, basket)}
				//basket={props.basket}
				prodKey={props.prodKey}
				handleClose={props.handleClose}
				updateStoreState={props.updateStoreState}
				stateUpdate={props.stateUpdate}
			/>
			<Modal.Footer>
				{/* <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button> */}
			</Modal.Footer>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		language: state.store.language,
		basket: state.store.basket,
		totalPrice: state.store.totalPrice,
	};
};

export default connect(mapStateToProps)(productModal);
