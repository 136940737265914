/*======================================================================================================================================== 
                                                        ORDERS REDUCER
reducer for updating the orders to the state                                                        
========================================================================================================================================*/
import * as actionTypes from "../actions/action-types";
import { updateObject } from "../utility";

const initialState = {
	orders: null,
	updatingStatus: false,
	loading: true,
	error: null,
};

const fetchOrdersStart = (state, action) => {
	return updateObject(state, { loading: true });
};

const fetchOrdersFail = (state, action) => {
	return updateObject(state, { error: true });
};

const fetchOrdersSuccess = (state, action) => {
	return updateObject(state, {
		orders: action.orders,
		loading: false,
	});
};

const updateOrderStatusStart = (state, action) => {
	return updateObject(state, { updatingStatus: true });
};

const updateOrderStatusFail = (state, action) => {
	return updateObject(state, { updatingStatus: false });
};

const updateOrderStatusSuccess = (state, action) => {
	return updateObject(state, {
		orders: action.orders,
		updatingStatus: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_ORDERS_START:
			return fetchOrdersStart(state, action);
		case actionTypes.FETCH_ORDERS_SUCCESS:
			return fetchOrdersSuccess(state, action);
		case actionTypes.FETCH_ORDERS_FAIL:
			return fetchOrdersFail(state, action);
		case actionTypes.UPDATE_ORDER_STATUS_START:
			return updateOrderStatusStart(state, action);
		case actionTypes.UPDATE_ORDER_STATUS_FAIL:
			return updateOrderStatusFail(state, action);
		case actionTypes.UPDATE_ORDER_STATUS_SUCCESS:
			return updateOrderStatusSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
