/*======================================================================================================================================== 
													STORE REDUCER
Reducer functions to get products in the store as well as to add new products to the store
========================================================================================================================================*/

import * as actionTypes from "../actions/action-types";
import { updateObject } from "../utility";

const initialState = {
	adding: false,
	editing: false,
	deleting: false,
	fetching: false,
	promoCodes: {},
	addingFilter: false,
	deletingFilter: false,
	fetchtingFilters: false,
	filters: [],
};

// adding new product functions
const addNewProductStart = (state, action) => {
	return updateObject(state, {
		adding: true,
	});
};

const addNewProductSuccess = (state, action) => {
	return updateObject(state, {
		adding: false,
	});
};

const addNewProductFail = (state, action) => {
	return updateObject(state, {
		adding: true,
	});
};

// edit product functions
const editProductStart = (state, action) => {
	return updateObject(state, {
		editing: true,
	});
};

const editProductSuccess = (state, action) => {
	return updateObject(state, {
		editing: false,
	});
};

const editProductFail = (state, action) => {
	return updateObject(state, {
		editing: true,
	});
};

// deleting product functions
const deleteProductStart = (state, action) => {
	return updateObject(state, {
		deleting: true,
	});
};

const deleteProductSuccess = (state, action) => {
	return updateObject(state, {
		deleting: false,
	});
};

const deleteProductFail = (state, action) => {
	return updateObject(state, {
		deleting: true,
	});
};

// fetching new promo code functions
const fetchPromoCodeStart = (state, action) => {
	return updateObject(state, {
		fetching: true,
	});
};

const fetchPromoCodeSuccess = (state, action) => {
	return updateObject(state, {
		fetching: false,
		promoCodes: action.promoCodes,
	});
};

const fetchPromoCodeFail = (state, action) => {
	return updateObject(state, {
		fetching: true,
	});
};

// adding new product functions
const addPromoCodeStart = (state, action) => {
	return updateObject(state, {
		adding: true,
	});
};

const addPromoCodeSuccess = (state, action) => {
	return updateObject(state, {
		adding: false,
	});
};

const addPromoCodeFail = (state, action) => {
	return updateObject(state, {
		adding: true,
	});
};

const addFilterStart = (state, action) => {
	return updateObject(state, {
		addingFilter: true,
	});
};
const addFilterSuccess = (state, action) => {
	return updateObject(state, {
		addingFilter: false,
	});
};
const addFilterFail = (state, action) => {
	return updateObject(state, {
		addingFilter: false,
	});
};

const deleteFilterStart = (state, action) => {
	return updateObject(state, {
		deletingFilter: true,
	});
};
const deleteFilterSuccess = (state, action) => {
	return updateObject(state, {
		deletingFilter: false,
	});
};
const deleteFilterFail = (state, action) => {
	return updateObject(state, {
		deletingFilter: false,
	});
};

const fetchFiltersStart = (state, action) => {
	return updateObject(state, {
		fetchingFilters: true,
	});
};
const fetchFiltersSuccess = (state, action) => {
	return updateObject(state, {
		fetchingFilters: false,
		filters: action.filters,
	});
};
const fetchFiltersFail = (state, action) => {
	return updateObject(state, {
		fetchtingFilters: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_NEW_PRODUCT_START:
			return addNewProductStart(state, action);
		case actionTypes.ADD_NEW_PRODUCT_SUCCESS:
			return addNewProductSuccess(state, action);
		case actionTypes.ADD_NEW_PRODUCT_FAIL:
			return addNewProductFail(state, action);
		case actionTypes.EDIT_PRODUCT_START:
			return editProductStart(state, action);
		case actionTypes.EDIT_PRODUCT_SUCCESS:
			return editProductSuccess(state, action);
		case actionTypes.EDIT_PRODUCT_FAIL:
			return editProductFail(state, action);
		case actionTypes.DELETE_PRODUCT_START:
			return deleteProductStart(state, action);
		case actionTypes.DELETE_PRODUCT_SUCCESS:
			return deleteProductSuccess(state, action);
		case actionTypes.DELETE_PRODUCT_FAIL:
			return deleteProductFail(state, action);
		case actionTypes.ADD_PROMO_CODE_START:
			return addPromoCodeStart(state, action);
		case actionTypes.ADD_PROMO_CODE_SUCCESS:
			return addPromoCodeSuccess(state, action);
		case actionTypes.ADD_PROMO_CODE_FAIL:
			return addPromoCodeFail(state, action);
		case actionTypes.FETCH_PROMO_CODE_START:
			return fetchPromoCodeStart(state, action);
		case actionTypes.FETCH_PROMO_CODE_SUCCESS:
			return fetchPromoCodeSuccess(state, action);
		case actionTypes.FETCH_PROMO_CODE_FAIL:
			return fetchPromoCodeFail(state, action);

		case actionTypes.FETCH_FILTERS_START:
			return fetchFiltersStart(state, action);
		case actionTypes.FETCH_FILTERS_SUCCESS:
			return fetchFiltersSuccess(state, action);
		case actionTypes.FETCH_FILTERS_FAIL:
			return fetchFiltersFail(state, action);
		case actionTypes.ADD_FILTER_START:
			return addFilterStart(state, action);
		case actionTypes.ADD_FILTER_SUCCESS:
			return addFilterSuccess(state, action);
		case actionTypes.ADD_FILTER_FAIL:
			return addFilterFail(state, action);
		case actionTypes.DELETE_FILTER_START:
			return deleteFilterStart(state, action);
		case actionTypes.DELETE_FILTER_SUCCESS:
			return deleteFilterSuccess(state, action);
		case actionTypes.DELETE_FILTER_FAIL:
			return deleteFilterFail(state, action);
		default:
			return state;
	}
};

export default reducer;
