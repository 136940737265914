import React, { useState, useEffect } from "react";
import {
	Card,
	Table,
	Dropdown,
	Col,
	Row,
	Container,
	FormControl,
	Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import { sendEmailHandler } from "../../../../util/mail/mail";
import ReactDOMServer from "react-dom/server";
import EmailTemplates from "../../../../util/mail/emailTemplates";

const OrderSummaryCard = (props) => {
	const [hasMessage, setHasMessage] = useState(false);
	const [orderMessage, setOrderMessage] = useState("");
	useEffect(() => {
		if (props.orderMessage) {
			setHasMessage(true);
			setOrderMessage(props.orderMessage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const messageChangeHandler = (e) => {
		setOrderMessage(e.target.value);
	};

	const updateOrderStatus = (event, newStatus, isMessageUpdate) => {
		event.preventDefault();
		const updatedOrder = {
			order: {
				basket: props.basket,
				contactInfo: props.contactInfo,
				status: !isMessageUpdate ? newStatus : props.status,
				totalPrice: props.totalPrice,
				orderDate: props.orderDate,
				paymentMethod: props.paymentMethod,
				shippingOption: props.shippingOption,
				orderReference: props.orderReference,
				promoCode: props.promoCode,
				discount: props.discount,
				orderMessage,
			},

			orderKey: props.orderKey,
		};
		const emailLogoImage = ReactDOMServer.renderToStaticMarkup(
			<img
				alt="The Braai Guy"
				src="https://thebraaiguy.com/static/media/RectangleLogo.2b9b054a.jpg"
			/>
		);

		props.updateOrderStatus(updatedOrder, props.orderKey);
		if (!isMessageUpdate) {
			if (
				newStatus === "inTransit" ||
				newStatus === "newOrder" ||
				newStatus === "completed" ||
				newStatus === "cancelled"
			) {
				let templateParams = {
					from_name: "The Braai Guy",
					to_name: props.order.contactInfo.Name,
					message: ReactDOMServer.renderToStaticMarkup(
						<EmailTemplates
							orderReference={props.order.orderReference}
							status={newStatus}
							to_name={props.order.contactInfo.Name}
							totalPrice={props.order.totalPrice}
							paymentMethod={props.order.paymentMethod}
							contactInfo={props.order.contactInfo}
							promoCode={props.promoCode}
							discount={props.discount}
							basket={props.basket}
							shippingOption={props.order.shippingOption}
						/>
					),
					reply_to: "derik@thebraaiguy.com",
					to_email: props.order.contactInfo.Email,
					logo: emailLogoImage,
				};
				sendEmailHandler(templateParams, "template_op0advk");
			}
		}
		setTimeout(() => {
			props.history.push("/");
			props.history.push(
				`/orders/${
					props.currentFilter === undefined ? "allOrders" : props.currentFilter
				}`
			);
		}, 500);
	};

	let basketMap =
		props.basket !== undefined &&
		props.basket.map((item) => {
			return (
				<tr key={item.product.Id}>
					<td>{props.basket.indexOf(item) + 1}</td>
					<td>{item.product.Title}</td>
					<td>{item.qty}</td>
					<td>
						{Math.ceil(
							item.product.Price * ((100 - item.product.SalesPercentage) / 100)
						)}
					</td>
				</tr>
			);
		});

	let shippingRow = null;
	let grandTotalPrice = null;

	switch (props.shippingOption) {
		case "RegularAM":
			shippingRow = (
				<tr>
					<td>{props.basket.length + 1}</td>
					<td>
						<strong>Regular Morning Shipping</strong>
					</td>

					<td>1</td>
					<td>160</td>
				</tr>
			);
			grandTotalPrice =
				props.totalPrice +
				160 -
				Math.floor((props.totalPrice / 100) * props.discount);
			break;
		case "RegularPM":
			shippingRow = (
				<tr>
					<td>{props.basket.length + 1}</td>
					<td>
						<strong>Regular Afternoon Shipping</strong>
					</td>

					<td>1</td>
					<td>160</td>
				</tr>
			);
			grandTotalPrice =
				props.totalPrice +
				160 -
				Math.floor((props.totalPrice / 100) * props.discount);
			break;

		case "PickUp":
			shippingRow = (
				<tr>
					<td>{props.basket.length + 1}</td>
					<td>
						<strong>Self Pick Up</strong>
					</td>
					<td>1</td>
					<td>0</td>
				</tr>
			);
			grandTotalPrice =
				props.totalPrice -
				Math.floor((props.totalPrice / 100) * props.discount);
			break;
		default:
			shippingRow = (
				<tr>
					<td>{props.basket.length + 1}</td>
					<td className="text-danger">
						<strong>No Shipping option</strong>
					</td>
					<td>***</td>
					<td>***</td>
				</tr>
			);
			grandTotalPrice =
				props.totalPrice +
				0 -
				Math.floor((props.totalPrice / 100) * props.discount);
	}

	let discountRow = null;

	if (props.discount && props.discount !== 0) {
		discountRow = (
			<tr>
				<td>*</td>
				<td className="text-danger">
					<strong>
						Promo {props.discount}% {props.promoCode}
					</strong>
				</td>
				<td>1</td>
				<td>{Math.floor((props.totalPrice / 100) * props.discount)}</td>
			</tr>
		);
	}

	const basketTable = (
		<Table striped bordered hover size="sm">
			<thead>
				<tr>
					<th>#</th>
					<th>Product Name</th>
					<th>Quantity</th>
					<th>Price</th>
				</tr>
			</thead>
			<tbody>
				{basketMap}
				{discountRow}
				{shippingRow}
				<tr>
					<td></td>
					<td>
						<strong>Total Price</strong>
					</td>
					<td></td>
					<td>{grandTotalPrice}</td>
				</tr>
			</tbody>
		</Table>
	);
	const customerDetails = (
		<Container fluid>
			<Row>
				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
					Name:
				</Col>
				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
					{props.contactInfo.Name}
				</Col>
			</Row>
			<Row className="row">
				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
					Email:
				</Col>
				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
					{props.contactInfo.Email}
				</Col>
			</Row>
			<Row className="row">
				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
					Tel Number:
				</Col>
				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
					{props.contactInfo.CellphoneNumber}
				</Col>
			</Row>
			<Row className="row">
				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
					Addess 1:
				</Col>
				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
					{props.contactInfo.Address.AddressLine1}
				</Col>
			</Row>
			<Row className="row">
				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
					Address 2:
				</Col>
				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
					{props.contactInfo.Address.AddressLine2}
				</Col>
			</Row>
			<Row className="row">
				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
					Address 3:
				</Col>
				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
					{props.contactInfo.Address.AddressLine3}
				</Col>
			</Row>

			<Row className="row">
				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
					County:
				</Col>
				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
					{props.contactInfo.Address.County}
				</Col>
			</Row>
			<Row className="row">
				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
					Chinese Address:
				</Col>
				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
					{props.contactInfo.Address.ChineseAddress}
				</Col>
			</Row>
			<Row className="row">
				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
					Msg:
				</Col>
				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
					<p>{props.contactInfo.Message}</p>
				</Col>
			</Row>
			<Row className="row text-center">
				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
					<strong>{props.paymentMethod}</strong>
				</Col>
			</Row>
		</Container>
	);

	return (
		<Card className={"container-fluid"}>
			<Row>
				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
					<Card.Title className="text-center">Order Summary</Card.Title>
				</Col>
			</Row>
			<Row>
				<Col xs={12} sm={12} md={6} lg={6} xl={6}>
					{basketTable}
				</Col>
				<Col xs={12} sm={12} md={6} lg={6} xl={6}>
					{customerDetails}
				</Col>
			</Row>
			<Row className="justify-content-center">
				{hasMessage ? (
					<>
						<FormControl
							className="mb-1"
							style={{ backgroundColor: "yellow" }}
							as="textarea"
							name="orderMessage"
							value={orderMessage}
							onChange={(e) => messageChangeHandler(e)}
						></FormControl>
						<Button
							onClick={(event, newStatus, isMessageUpdate) =>
								updateOrderStatus(event, null, true)
							}
							variant="secondary"
						>
							Save Message
						</Button>
					</>
				) : (
					<Button onClick={() => setHasMessage(!hasMessage)} variant="success">
						Add Message
					</Button>
				)}
			</Row>

			<Dropdown className="text-center m-2">
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					Edit order status
				</Dropdown.Toggle>

				<Dropdown.Menu>
					<Dropdown.Item
						as="button"
						onClick={(event, newStatus, isMessageUpdate) =>
							updateOrderStatus(event, "newOrder", false)
						}
					>
						New Order
					</Dropdown.Item>
					<Dropdown.Item
						as="button"
						onClick={(event, newStatus, isMessageUpdate) =>
							updateOrderStatus(event, "awaitingPayment", false)
						}
					>
						Awaiting Payment
					</Dropdown.Item>
					<Dropdown.Item
						as="button"
						onClick={(event, newStatus, isMessageUpdate) =>
							updateOrderStatus(event, "followUp", false)
						}
					>
						Follow Up
					</Dropdown.Item>
					<Dropdown.Item
						as="button"
						onClick={(event, newStatus, isMessageUpdate) =>
							updateOrderStatus(event, "inTransit", false)
						}
					>
						In Transit
					</Dropdown.Item>
					<Dropdown.Item
						as="button"
						onClick={(event, newStatus, isMessageUpdate) =>
							updateOrderStatus(event, "cancelled", false)
						}
					>
						Cancelled
					</Dropdown.Item>

					<Dropdown.Item
						as="button"
						onClick={(event, newStatus, isMessageUpdate) =>
							updateOrderStatus(event, "completed", false)
						}
					>
						completed
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		products: state.store.products,
		orders: state.orders.orders,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateOrderStatus: (order, orderKey) => {
			dispatch(actions.updateOrderStatus(order, orderKey));
		},
		fetchOrders: () => {
			dispatch(actions.fetchOrders());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummaryCard);
