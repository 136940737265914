import React from "react";
import { Bag, /* BagPlus, BagDash, */ BagFill } from "react-bootstrap-icons";
import classes from "./CartButton.module.css";
import { connect } from "react-redux";
import { Button, Badge } from "react-bootstrap";

const bagIcon = (props) => {
	if (props.basket.length === 0) {
		return (
			<div className="container-fluid text-center">
				<Button onClick={props.onClick} variant="primary">
					<Bag className={classes.Fa} style={{ fontSize: "24px" }}>
						&#xf07a;
					</Bag>{" "}
					<Badge variant="light">{props.basket.length}</Badge>
				</Button>
			</div>
		);
	} else {
		return (
			<div className="container-fluid text-center">
				<Button onClick={props.onClick} variant="primary">
					<BagFill className={classes.Fa} style={{ fontSize: "24px" }}>
						&#xf07a;
					</BagFill>
					<Badge variant="light">{props.basketItemQty}</Badge>
				</Button>
			</div>
		);
	}
};
const mapStateToProps = (state) => {
	return {
		basketItemQty: state.store.basketItemQty,
	};
};

export default connect(mapStateToProps)(bagIcon);
