/*======================================================================================================================================== 
                                                           AUTH STORE
store front component with product cards for each product in the store
has the add product card if the user is authenticated with different buttons to edit product qty in the store
========================================================================================================================================*/
import React, { Component } from "react";
import classes from "./AuthStore.module.css";

import Navigation from "../../components/Navigation/Navigation";
import Title from "../../components/UI/Title/Title";
import Cards from "../../components/UI/Cards/Cards";
import AdminCard from "../../components/UI/Cards/Card/AdminCard";
import Spinner from "../../components/UI/Spinner/Spinner";
import AddProductCard from "../AddProductCard/AddProductCard";
import ProductModal from "../../components/ProductModal/ProductModal";
import { Button, Container, Dropdown, DropdownButton } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { Redirect } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

class AuthStore extends Component {
	state = {
		products: {
			index: {
				Id: "",
				Image: "",
				Price: 0,
				Qty: 0,
				SalesPercentage: 0,
				Title: "",
				Type: "",
			},
		},
		basket: {},
		loading: true,
		showAddProdModal: false,
		showProductModal: false,
		ProductModalKey: null,
		productType: "",
		keyArr: [],
		uniqueProdTypeArr: [],
		keysToRender: [],
		sortClicked: false,
		editItemKey: null,
	};

	componentDidMount() {
		this.props.onFetchProducts();
		this.props.onFetchFilters();
	}

	//==================================================

	// handles Add Product Modal Close
	handleAddProdModalClose = () => {
		this.setState({ showAddProdModal: false });
	};
	// handles modal open
	showAddProductModal = (objKey) => {
		this.setState({ showAddProdModal: true, editItemKey: objKey });
	};
	// closes ProductModal
	handleProdModalClose = () => {
		this.setState({ showProductModal: false });
	};
	// opens ProductModal
	handleProdModalOpen = (objKeys) => {
		this.setState({ showProductModal: true, productModalKey: objKeys });
	};

	showProductsHandler = (type) => {
		if (type === "") {
			this.setState({
				keysToRender: this.props.keyArr,
			});
		} else {
			this.setState({ productType: type });
			let keysToRender = [];
			for (let values in this.props.keyArr) {
				if (
					this.props.products[this.props.keyArr[values]].Type.trim() === type
				) {
					keysToRender.push(this.props.keyArr[values]);
				}
			}
			this.setState({ keysToRender: keysToRender });
		}
		this.setState({ sortClicked: true });
	};

	render() {
		let addProductModal = null;

		if (this.state.showAddProdModal) {
			addProductModal = (
				<AddProductCard
					handleClose={this.handleAddProdModalClose}
					type="Add Product"
					show={this.state.showAddProdModal}
					{...this.props}
					storeProducts={this.props.products}
					objKey={this.state.editItemKey}
				/>
			);
		}

		let cards = <Spinner />;
		if (!this.props.loading) {
			cards = (
				<Cards
					products={this.props.products}
					type={this.state.productType}
					showEditProductModal={(objKey) => this.showAddProductModal(objKey)}
					showProductModal={(objKeys) => this.handleProdModalOpen(objKeys)}
					show={this.state.showProductModal}
					keyArr={this.props.keyArr}
					uniqueProdTypeArr={this.props.uniqueProdTypeArr}
					sortClicked={this.state.sortClicked}
					keysToRender={this.state.keysToRender}
					btnType={null}
				/>
			);
		}

		let filterButtons, filterOptions;
		if (!this.props.loading) {
			filterButtons =
				this.props.filters &&
				this.props.filters.map((filter) => {
					return (
						<Button
							key={filter.filterKey}
							onClick={() => this.showProductsHandler(filter.value[0])}
						>
							{" "}
							{this.props.language === 0 ? filter.value[0] : filter.value[1]}
						</Button>
					);
				});

			filterOptions =
				this.props.filters &&
				this.props.filters.map((filter) => {
					return (
						<Dropdown.Item
							key={filter.filterKey}
							onClick={() => this.showProductsHandler(filter.value[0])}
						>
							{this.props.language === 0 ? filter.value[0] : filter.value[1]}
						</Dropdown.Item>
					);
				});
		}
		const mainContent = (
			<div className={classes.MeatStore}>
				<Navigation />
				<Title title="Store Admin" />
				<div
					className={
						classes.FilterButtons + " container-fluid text-center mb-2"
					}
				>
					<Button onClick={(type) => this.showProductsHandler("")}>
						All Products
					</Button>
					{filterButtons}
				</div>

				<div
					className={
						classes.FilterDropdown + " container-fluid text-center mb-2"
					}
				>
					<DropdownButton id="dropdown-basic-button" title="Filter">
						<Dropdown.Item
							onClick={(type) => {
								this.showProductsHandler("");
							}}
						>
							All Products
						</Dropdown.Item>
						{filterOptions}
					</DropdownButton>
				</div>
				<Container fluid>{cards}</Container>

				<AdminCard title="Add New Product">
					<Button
						variant={"success"}
						onClick={(objKey) => this.showAddProductModal(null)}
					>
						Add New
					</Button>
				</AdminCard>
				{addProductModal}
				<ProductModal
					show={this.state.showProductModal}
					handleClose={this.handleProdModalClose}
					{...this.props}
					prodKey={this.state.productModalKey}
					addToCartClicked={(item) => {
						this.addItemToBasketHandler(item);
					}}
					basket={this.state.basket}
					modalType={null}
				/>
				<Footer />
			</div>
		);

		return (
			<>{this.props.isSignedIn ? mainContent : <Redirect to="/login" />}</>
		);
	}
}
// maps state to props from redux
const mapStateToProps = (state) => {
	return {
		products: state.store.products,
		uniqueProdTypeArr: state.store.uniqueProdTypeArr,
		keyArr: state.store.keyArr,
		loading: state.store.loading,
		isSignedIn: state.auth.isSignedIn,
		filters: state.admin.filters,
		language: state.store.language,
	};
};
// maps actions to props from redux
const mapDispatchToProps = (dispatch) => {
	return {
		onFetchFilters: () => dispatch(actions.fetchFilters()),
		onFetchProducts: () => dispatch(actions.fetchProducts()),
		//onSignOut: () => dispatch(actions.logout),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthStore);
