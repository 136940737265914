// iface is the first key properties of the json object.
// These are string values such as "landing" or "navigation"
// Content is the actual text element you are trying to recieve text for
// Language is a string value
// language indices current [english, chinese]
import schema from "./schema.json";

export function languageHandler(iface, content, language) {
	if (typeof iface !== "string")
		return console.error(
			"iface param must be a string, check schema.json for accepted iface values"
		);
	if (typeof content !== "string")
		return console.error(
			"content param must be a string, check schema.json for accepted content values"
		);
	if (typeof language !== "number")
		return console.error(
			"language param must be a number, check schema.json file to see the index of the language you are looking for"
		);
	let value = schema[`${iface}`][`${content}`][language];
	if (!value) return console.error("No value returned from schema");
	return value;
}
