import React, { Component } from "react";
import Navigation from "../../components/Navigation/Navigation";
import ContactForm from "../ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";
import { connect } from "react-redux";
import { languageHandler } from "../../util/language/functions";

class Contact extends Component {
	state = {};
	render() {
		return (
			<>
				<Navigation />
				<div className="container-fluid bg-primary">
					<h3 className="text-white">
						{languageHandler("contact", "title", this.props.language)}
					</h3>
					<ContactForm isInContactUs={true} />

					<Footer />
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		language: state.store.language,
	};
};

export default connect(mapStateToProps)(Contact);
