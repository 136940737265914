/*======================================================================================================================================== 
																LOGIN 
takes login actions and state from redux
validates inputs for login																
========================================================================================================================================*/
import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Login.module.css";
import * as actions from "../../../store/actions/index";
import Spinner from "../../../components/Spinner/Spinner";
import { Redirect } from "react-router-dom";

class Login extends Component {
	state = {
		controls: {
			email: {
				elementType: "input",
				elementConfig: {
					type: "email",
					placeholder: "Email Address",
				},
				value: "",
				validation: {
					required: true,
					isEmail: true,
				},
				valid: false,
				touched: false,
			},
			password: {
				elementType: "input",
				elementConfig: {
					type: "password",
					placeholder: "Password",
				},
				value: "",
				validation: {
					required: true,
					minLength: 6,
				},
				valid: false,
				touched: false,
			},
		},
	};

	//validity checking rules

	checkValidity(value, rules) {
		let isValid = true;

		if (rules.required) {
			isValid = value.trim() !== "" && isValid;
		}

		if (rules.minLength) {
			isValid = value.length >= rules.minLength && isValid;
		}

		if (rules.maxLength) {
			isValid = value.length <= rules.maxLength && isValid;
		}

		if (rules.isEmail) {
			const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
			isValid = pattern.test(value) && isValid;
		}

		return isValid;
	}

	//setting up two-way binding with validity checking

	inputChangedHandler = (event, controlName) => {
		const updatedControls = {
			...this.state.controls,
			[controlName]: {
				...this.state.controls[controlName],
				value: event.target.value,
				valid: this.checkValidity(
					event.target.value,
					this.state.controls[controlName].validation
				),
				touched: true,
			},
		};
		this.setState({ controls: updatedControls });
	};

	//firing the auth method when submitting the file
	submitHandler = (event) => {
		event.preventDefault();
		this.props.onAuth(
			this.state.controls.email.value,
			this.state.controls.password.value
		);
	};

	render() {
		// creates an array of form controls that allow us to use mapping
		const formElementsArray = [];
		for (let key in this.state.controls) {
			formElementsArray.push({
				id: key,
				config: this.state.controls[key],
			});
		}

		//maps the form control array into form controls
		let form = formElementsArray.map((formElement) => (
			<div key={formElement.id} className="form-group">
				<label>{formElement.id}</label>
				<input
					type={formElement.config.elementConfig.type}
					className="form-control"
					placeholder={formElement.config.elementConfig.placeholder}
					value={formElement.config.value}
					touched={formElement.config.touched.toString()}
					onChange={(event) => this.inputChangedHandler(event, formElement.id)}
				/>
			</div>
		));

		// conditionally renders the error message if we have an error message
		let errorMessage = null;

		if (this.props.error) {
			errorMessage = <p className="text-danger">{this.props.error.message}</p>;
		}

		// conditionally renders the login inputs if we are not logged in
		let login,
			redirect = null;

		if (!this.props.user) {
			login = (
				<form onSubmit={this.submitHandler}>
					{form}
					<button type="submit" className="btn btn-primary btn-block">
						Submit
					</button>
				</form>
			);
		} else if (this.props.user) {
			redirect = <Redirect to="/auth-store" />;
		} else return null;

		// conditionally renders the spinner if we are in a loading state
		let loadingOrNot = <Spinner />;

		if (!this.props.loading) {
			loadingOrNot = (
				<div>
					{login}
					
				</div>
			);
		}

		return (
			<div className={classes.Login}>
				<h1 className="text-primary">The Braai Guy</h1>
				<h3>{errorMessage}</h3>
				{loadingOrNot}
				{redirect}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		//isAuthenticated: state.auth.token !== null,
		error: state.auth.error,
		loading: state.auth.loading,
		user: state.auth.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAuth: (email, password) => dispatch(actions.auth(email, password)),
		//onLogout: () => dispatch(actions.logout()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
