/*======================================================================================================================================== 
                                                                TITLE
title component to show title for the page. can be used for other titles 
========================================================================================================================================*/
import classes from './Title.module.css';
import React from 'react';

const title = (props) => (
    <div className={classes.Title}>
        <h1>{props.title}</h1>
    </div>
);

export default title;