import { Alert, Button } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";

const ErrorComponent = ({
	errorCode,
	errorMessage,
	instruction,
	linkRoute,
	linkDestination,
	submitFailedOrder,
	failedOrderSent,
}) => {
	return (
		<Alert variant="danger">
			<Alert.Heading>Error {errorCode}</Alert.Heading>
			<p>{errorMessage}</p>
			<p>{instruction}</p>
			<Link to={linkRoute} className="text-light ">
				<Button variant="outline-primary" size="sm">
					Return to {linkDestination}
				</Button>
				{failedOrderSent ? null : (
					<Button
						onClick={submitFailedOrder}
						variant="outline-primary"
						size="sm"
					>
						Report this failed order
					</Button>
				)}
			</Link>
		</Alert>
	);
};

export default ErrorComponent;
