/*======================================================================================================================================== 
															AUTH WRAPPER
creates a wrapper for the auth component
========================================================================================================================================*/

import React from "react";
import classes from "./authWrapper.module.css";
import Login from "../../containers/auth/Login/Login";

const authWrapper = () => {
	return (
		<div className={classes.authWrapper}>
			<div className={classes.authOuter}>
				<div className={classes.authInner}>
					<Login />
				</div>
			</div>
		</div>
	);
};

export default authWrapper;
