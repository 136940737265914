/*======================================================================================================================================== 
                                                            STORE
store front component with product cards for each product in the store
has the add product card if the user is authenticated with different buttons to edit product qty in the store
========================================================================================================================================*/
import React, { Component } from "react";
import classes from "./MeatStore.module.css";

import Navigation from "../../components/Navigation/Navigation";
import Title from "../../components/UI/Title/Title";
import Cards from "../../components/UI/Cards/Cards";
import Spinner from "../../components/UI/Spinner/Spinner";
import ProductModal from "../../components/ProductModal/ProductModal";
import { Button, Dropdown, DropdownButton, Container } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Cart from "../Cart/Cart";
import CartButton from "../../components/UI/CartButton/CartButton";
import Footer from "../../components/Footer/Footer";
import schema from "../../util/language/schema.json";
import { languageHandler } from "../../util/language/functions";

class meatStore extends Component {
	state = {
		products: {
			index: {
				Id: null,
				Image: "",
				Price: 5,
				Qty: null,
				SalesPercentage: null,
				Title: "",
				Type: "",
			},
		},
		basket: {},
		loading: true,
		showAddProdModal: false,
		showProductModal: false,
		showCartModal: false,
		ProductModalKey: null,
		productType: "",
		keyArr: [],
		uniqueProdTypeArr: [],
		keysToRender: [],
		sortClicked: false,
		shouldUpdate: false,
	};

	componentDidMount() {
		this.props.onFetchProducts();
		this.props.onFetchFilters();
		if (Array.isArray(this.props.basket) && this.props.basket.length === 0)
			return null;
		this.props.basket.map((basketItem) => {
			const thisProdQty = this.props.products[basketItem.key[0]].Qty;
			const itemPrice = +this.props.products[basketItem.key[0]].Price;
			const salesPercentage =
				+this.props.products[basketItem.key[0]].SalesPercentage;
			if (thisProdQty < basketItem.qty) {
				this.props.removeCartItem(
					basketItem.key[0],
					this.props.basket,
					(itemPrice * (100 - salesPercentage)) / 100,
					+this.props.totalPrice
				);
			} else return null;
			return null;
		});
		this.updateStoreState();
	}

	//==================================================

	// closes ProductModal
	handleProdModalClose = () => {
		this.setState({ showProductModal: false });
		this.forceUpdate();
	};
	// opens ProductModal
	handleProdModalOpen = (objKeys) => {
		this.setState({ showProductModal: true, productModalKey: objKeys });
	};
	handleCartOpen = () => {
		this.setState({
			showCartModal: true,
		});
	};
	handleCartClose = () => {
		this.setState({
			showCartModal: false,
		});
		this.forceUpdate();
	};

	showProductsHandler = (type) => {
		if (type === "") {
			this.setState({
				keysToRender: this.props.keyArr,
			});
			this.forceUpdate();
		} else {
			this.setState({ productType: type });
			let keysToRender = [];
			for (let values in this.props.keyArr) {
				if (
					this.props.products[this.props.keyArr[values]].Type.trim() === type
				) {
					keysToRender.push(this.props.keyArr[values]);
				}
			}
			this.setState({ keysToRender: keysToRender });
			this.forceUpdate();
		}
		this.setState({ sortClicked: true });
	};
	addToCartClicked = (prodKey, qty, basket, itemPrice, totalPrice) => {
		//let oldBasket = this.props.basket;
		this.props.onAddToCartClicked(prodKey, qty, basket, itemPrice, totalPrice);
		this.setState({ shouldUpdate: true });
		this.setState({ shouldUpdate: false });
		this.setState({ showProductModal: false });
		this.updateStoreState();
	};

	updateStoreState = () => {
		this.setState({ shouldUpdate: true });
		this.setState({ shouldUpdate: false });
		setTimeout(() => {
			this.handleProdModalClose();
		}, 200);
	};

	render() {
		let cards = <Spinner />;
		if (!this.props.loading) {
			cards = (
				<Cards
					updateStoreState={this.updateStoreState}
					shouldUpdate={this.state.shouldUpdate}
					products={this.props.products}
					type={this.state.productType}
					showProductModal={(objKeys) => this.handleProdModalOpen(objKeys)}
					show={this.state.showProductModal}
					keyArr={this.props.keyArr}
					uniqueProdTypeArr={this.props.uniqueProdTypeArr}
					sortClicked={this.state.sortClicked}
					keysToRender={this.state.keysToRender}
					basket={this.props.basket}
					addToCartClicked={(prodKey, qty, basket, itemPrice, totalPrice) =>
						this.addToCartClicked(prodKey, qty, basket, itemPrice, totalPrice)
					}
					btnType="user"
				/>
			);
		}

		let filterButtons, filterOptions;
		if (!this.props.loading) {
			filterButtons =
				this.props.filters &&
				this.props.filters.map((filter) => {
					return (
						<Button
							key={filter.filterKey}
							onClick={() => this.showProductsHandler(filter.value[0])}
						>
							{" "}
							{this.props.language === 0 ? filter.value[0] : filter.value[1]}
						</Button>
					);
				});

			filterOptions =
				this.props.filters &&
				this.props.filters.map((filter) => {
					return (
						<Dropdown.Item
							key={filter.filterKey}
							onClick={() => this.showProductsHandler(filter.value[0])}
						>
							{this.props.language === 0 ? filter.value[0] : filter.value[1]}
						</Dropdown.Item>
					);
				});
		}

		// Conditionally render the Product Modal

		let productModal = null;

		if (this.state.showProductModal) {
			productModal = (
				<ProductModal
					show={true}
					handleClose={this.handleProdModalClose}
					{...this.props}
					prodKey={this.state.productModalKey}
					updateStoreState={this.updateStoreState}
					//addToCartClicked={(prodKey, qty, basket) =>
					//	this.addToCartClicked(prodKey, qty, basket)
					//}
					//basket={this.state.basket}
					modalType="user"
				/>
			);
		}

		// Conditionally render Cart Modal

		let shoppingCartModal = null;

		if (this.state.showCartModal) {
			shoppingCartModal = (
				<Cart
					show={true}
					hide={this.handleCartClose}
					updateStoreState={this.updateStoreState}
					{...this.props}
					// increment={(prodKey, qty, basket) =>
					//	this.addToCartClicked(prodKey, qty, basket)
					//}
				/>
			);
		}

		return (
			<div className={`${classes.MeatStore}`}>
				<div className="container-fluid p-5">
					<Navigation />
				</div>

				<Title title={languageHandler("store", "title", this.props.language)} />

				<CartButton onClick={this.handleCartOpen} basket={this.props.basket} />

				<div
					className={
						classes.FilterButtons + " container-fluid text-center mb-2"
					}
				>
					<Button
						onClick={(type) => {
							this.showProductsHandler("");
						}}
					>
						{languageHandler("store", "all-filter-text", this.props.language)}
					</Button>
					{filterButtons}
				</div>
				<div
					className={
						classes.FilterDropdown + " container-fluid text-center mb-2"
					}
				>
					<DropdownButton
						id="dropdown-basic-button"
						title={languageHandler(
							"store",
							"filter-label-text",
							this.props.language
						)}
					>
						<Dropdown.Item
							onClick={(type) => {
								this.showProductsHandler("");
							}}
						>
							{languageHandler("store", "all-filter-text", this.props.language)}
						</Dropdown.Item>
						{filterOptions}
					</DropdownButton>
				</div>

				<Container fluid className=" pl-xl-5 pr-xl-5">
					{cards}
				</Container>
				{productModal}
				{shoppingCartModal}
				<Footer />
			</div>
		);
	}
}
// maps state to props from redux
const mapStateToProps = (state) => {
	return {
		products: state.store.products,
		uniqueProdTypeArr: state.store.uniqueProdTypeArr,
		keyArr: state.store.keyArr,
		loading: state.store.loading,
		basket: state.store.basket,
		totalPrice: state.store.totalPrice,
		language: state.store.language,
		filters: state.admin.filters,
	};
};
// maps actions to props from redux
const mapDispatchToProps = (dispatch) => {
	return {
		onFetchFilters: () => dispatch(actions.fetchFilters()),
		onFetchProducts: () => dispatch(actions.fetchProducts()),
		onAddToCartClicked: (prodKey, qty, basket, itemPrice, totalPrice) =>
			dispatch(actions.addToCart(prodKey, qty, basket, itemPrice, totalPrice)),
		removeCartItem: (prodKey, basket, itemPrice, totalPrice) =>
			dispatch(actions.removeCartItem(prodKey, basket, itemPrice, totalPrice)),
		//submittedClicked: (obj) => dispatch(actions.submitNewProduct(obj))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(meatStore);
