import React from "react";
import { languageHandler } from "../language/functions";

function emailTemplates(props) {
  const getMessage = (status) => {
    let message = null;
    switch (status) {
      case "newOrder":
        let paramBasket = props.basket.map((item) => {
          return (
            <tr key={item.product.Title[0]}>
              <td>
                {item.qty} x{" "}
                {Array.isArray(item.product.Title)
                  ? item.product.Title[0]
                  : item.product.Title}
              </td>
              <td>
                {Math.ceil(
                  (item.product.Price * (100 - item.product.SalesPercentage)) /
                    100
                ) * item.qty}
              </td>
            </tr>
          );
        });
        let paramBasketTable = (
          <div>
            <table>
              {paramBasket}
              <tr>
                <td>{languageHandler("email", "promo-discount-text", 0)}</td>
                <td>
                  {" "}
                  - &#78;&#84;&#36;
                  {Math.floor((props.totalPrice / 100) * props.discount)}
                </td>
              </tr>
              <tr>
                <td>
                  1 x{" "}
                  {props.shippingOption === "PickUp"
                    ? languageHandler("email", "shipping-option-self", 0)
                    : null}
                  {props.shippingOption === "RegularAM"
                    ? languageHandler("email", "shipping-option-morning", 0)
                    : null}
                  {props.shippingOption === "RegularPM"
                    ? languageHandler("email", "shipping-option-afternoon", 0)
                    : null}
                </td>
                <td> {props.shippingOption === "PickUp" ? 0 : 160}</td>
              </tr>
              <tr>
                <td>{languageHandler("checkout", "total-price", 0)}</td>
                <td>
                  <strong>NTD </strong>
                  <strong>
                    {props.shippingOption === "PickUp"
                      ? props.totalPrice -
                        Math.floor((props.totalPrice / 100) * props.discount)
                      : props.totalPrice +
                        160 -
                        Math.floor((props.totalPrice / 100) * props.discount)}
                  </strong>
                </td>
              </tr>
            </table>

            {props.shippingOption === "PickUp" ? (
              <div>
                <h3>{languageHandler("email", "message-option-pickup", 0)}</h3>
                <p>{languageHandler("email", "message-pickup-text", 0)}</p>

                <p>
                  <strong>
                    {languageHandler(
                      "email",
                      "message-pickup-address-change",
                      0
                    )}
                  </strong>
                </p>
                <a
                  title="Link to The Braai Guy pickup location"
                  href="https://maps.app.goo.gl/WJLuHu7EvvhpikXa7"
                >
                  {languageHandler("email", "message-pickup-link-text", 0)}
                </a>
                {/* 
                //* service request 2022-04-25 addition of maps link for new address
                <p>{languageHandler("address", "address-line-1", 0)}</p>
                <p>{languageHandler("address", "address-line-2", 0)}</p>
                <p>{languageHandler("address", "address-line-3", 0)}</p>
                <p>973</p> */}
                <p>{languageHandler("email", "message-please-contact", 0)}</p>
              </div>
            ) : null}

            {props.contactInfo.PaymentMethod === "Bank Transfer" ? (
              <div>
                <h3>{languageHandler("banking", "bank-details-title", 0)}</h3>
                <table>
                  <tr>
                    <th>{languageHandler("banking", "bank-name-label", 0)}:</th>
                    <th>{languageHandler("banking", "bank-name-text", 0)}</th>
                  </tr>
                  <tr>
                    <th>
                      {languageHandler("banking", "acc-number-label", 0)}:
                    </th>
                    <th>0325 968 059 356</th>
                  </tr>
                  <tr>
                    <th>{languageHandler("banking", "bank-code-label", 0)}:</th>
                    <th>808</th>
                  </tr>
                  <tr>
                    <th>
                      {languageHandler("banking", "payment-ref-label", 0)}:
                    </th>
                    <th>{props.orderNumber}</th>
                  </tr>
                </table>
                <p>
                  {languageHandler(
                    "email",
                    "message-reference-number",
                    0
                  ).replace("ORDER_NUMBER", props.orderNumber)}
                </p>

                <p>
                  {languageHandler("email", "message-transfer-confirmation", 0)}
                </p>
              </div>
            ) : null}
          </div>
        );
        message = (
          <div>
            <p>
              {languageHandler("email", "message-order-received", 0).replace(
                "ORDER_NUMBER",
                props.orderNumber
              )}
            </p>
            <p>{paramBasketTable}</p>
            <p>
              {languageHandler("email", "message-thanks-customer", 0).replace(
                "CUSTOMER_NAME",
                props.to_name
              )}
            </p>
            {languageHandler("email", "message-contact-socials", 0)}
          </div>
        );
        return message;
      case "inTransit":
        message = (
          <div>
            <p>{languageHandler("email", "message-order-sent", 0)}</p>

            {props.paymentMethod === "Cash on Delivery" ? (
              <p>
                {languageHandler("email", "message-payment-amount", 0).replace(
                  "PAYMENT_AMOUNT",
                  `${
                    Math.ceil(
                      (props.totalPrice / 100) * (100 - props.discount)
                    ) + 160
                  }`
                )}
                {/* The total payment due at the point of delivery is NTD{" "}
								{Math.ceil((props.totalPrice / 100) * (100 - props.discount)) +
									160} */}
              </p>
            ) : null}

            <p>{languageHandler("email", "message-keep-phone", 0)}</p>
            <p>{languageHandler("email", "message-not-received", 0)}</p>
            <p>{languageHandler("email", "message-best-wishes", 0)}</p>
          </div>
        );
        return message;
      case "completed":
        message = (
          <div>
            <p>{languageHandler("email", "message-order-completed", 0)}</p>
            <p>{languageHandler("email", "message-best-wishes", 0)}</p>
          </div>
        );
        return message;
      case "cancelled":
        message = (
          <div>
            <p>{languageHandler("email", "message-order-cancelled", 0)}</p>
            <p>
              {languageHandler("email", "message-reference-number", 0).replace(
                "ORDER_NUMBER",
                props.orderReference
              )}
            </p>
            <p>{languageHandler("email", "message-best-wishes", 0)}</p>
          </div>
        );
        return message;
      case "failedOrder": {
        let paramBasket = props.basket.map((item) => {
          return (
            <tr key={item.product.Title[0]}>
              <td>
                {item.qty} x{" "}
                {Array.isArray(item.product.Title)
                  ? item.product.Title[0]
                  : item.product.Title}
              </td>
              <td>
                {Math.ceil(
                  (item.product.Price * (100 - item.product.SalesPercentage)) /
                    100
                ) * item.qty}
              </td>
            </tr>
          );
        });
        let paramBasketTable = (
          <div>
            <table>
              {paramBasket}
              <tr>
                <td>{languageHandler("email", "promo-discount-text", 0)}</td>
                <td>
                  {" "}
                  - &#78;&#84;&#36;
                  {Math.floor((props.totalPrice / 100) * props.discount)}
                </td>
              </tr>
              <tr>
                <td>
                  1 x{" "}
                  {props.shippingOption === "PickUp"
                    ? languageHandler("email", "shipping-option-self", 0)
                    : null}
                  {props.shippingOption === "RegularAM"
                    ? languageHandler("email", "shipping-option-morning", 0)
                    : null}
                  {props.shippingOption === "RegularPM"
                    ? languageHandler("email", "shipping-option-afternoon", 0)
                    : null}
                </td>
                <td> {props.shippingOption === "PickUp" ? 0 : 160}</td>
              </tr>
              <tr>
                <td>{languageHandler("checkout", "total-price", 0)}</td>
                <td>
                  <strong>NTD </strong>
                  <strong>
                    {props.shippingOption === "PickUp"
                      ? props.totalPrice -
                        Math.floor((props.totalPrice / 100) * props.discount)
                      : props.totalPrice +
                        160 -
                        Math.floor((props.totalPrice / 100) * props.discount)}
                  </strong>
                </td>
              </tr>
            </table>
          </div>
        );
        message = (
          <div>
            <p>
              An order has failed to be posted to your database and the customer
              has reported this error. You can find below the customers order
              and contact information. You should report this issue to your
              service provider.
            </p>
            <p>{paramBasketTable}</p>
            <p>
              The contact details for the customer are below, and they have
              recieved a message to let them know that they will be contacted to
              confirm their order.
            </p>
            <p>Name: {props.contactInfo.Name}</p>
            <p>Email: {props.contactInfo.Email}</p>
            <p>Contact Number: {props.contactInfo.CellphoneNumber}</p>
            <p>Address line 1: {props.contactInfo.Address.AddressLine1}</p>
            <p>Address line 2: {props.contactInfo.Address.AddressLine2}</p>
            <p>Address line 3: {props.contactInfo.Address.AddressLine3}</p>
            <p>County: {props.contactInfo.Address.County}</p>
            <p>Chinese Address: {props.contactInfo.Address.ChineseAddress}</p>
            <p>Payment Method: {props.order.PaymentMethod}</p>
            <p>Promo Code: {props.order.promoCode}</p>
            <p>Shipping Option: {props.order.shippingOption}</p>
          </div>
        );
        return message;
      }

      default:
        message = (
          <div>
            <p>
              {languageHandler("email", "message-order-received", 0).replace(
                "ORDER_NUMBER",
                props.orderNumber
              )}
            </p>
            <p>{paramBasketTable}</p>
            <p>
              {languageHandler("email", "message-thanks-customer", 0).replace(
                "CUSTOMER_NAME",
                props.to_name
              )}
            </p>
            {languageHandler("email", "message-contact-socials", 0)}
          </div>
        );
        return message;
    }
  };

  const getMessageChinese = (status) => {
    let message = null;
    switch (status) {
      case "failedOrder":
        return "";
      case "newOrder":
        let paramBasket = props.basket.map((item) => {
          return (
            <tr key={item.product.Title[1]}>
              <td>
                {item.qty} x{" "}
                {Array.isArray(item.product.Title)
                  ? item.product.Title[1]
                  : item.product.Title}
              </td>
              <td>
                {Math.ceil(
                  (item.product.Price * (100 - item.product.SalesPercentage)) /
                    100
                ) * item.qty}
              </td>
            </tr>
          );
        });
        let paramBasketTable = (
          <div>
            <table>
              {paramBasket}
              <tr>
                <td>{languageHandler("email", "promo-discount-text", 1)}</td>
                <td>
                  {" "}
                  - &#78;&#84;&#36;
                  {Math.floor((props.totalPrice / 100) * props.discount)}
                </td>
              </tr>
              <tr>
                <td>
                  1 x{" "}
                  {props.shippingOption === "PickUp"
                    ? languageHandler("email", "shipping-option-self", 1)
                    : null}
                  {props.shippingOption === "RegularAM"
                    ? languageHandler("email", "shipping-option-morning", 1)
                    : null}
                  {props.shippingOption === "RegularPM"
                    ? languageHandler("email", "shipping-option-afternoon", 1)
                    : null}
                </td>
                <td> {props.shippingOption === "PickUp" ? 0 : 160}</td>
              </tr>
              <tr>
                <td>{languageHandler("checkout", "total-price", 1)}</td>
                <td>
                  <strong>NTD </strong>
                  <strong>
                    {props.shippingOption === "PickUp"
                      ? Math.ceil(
                          (props.totalPrice / 100) * (100 - props.discount)
                        )
                      : Math.ceil(
                          (props.totalPrice / 100) * (100 - props.discount)
                        ) + 160}
                  </strong>
                </td>
              </tr>
            </table>

            {props.shippingOption === "PickUp" ? (
              <div>
                <h3>{languageHandler("email", "message-option-pickup", 1)}</h3>
                <p>{languageHandler("email", "message-pickup-text", 1)}</p>
                <p>
                  <strong>
                    {languageHandler(
                      "email",
                      "message-pickup-address-change",
                      1
                    )}
                  </strong>
                </p>
                <a
                  title="Link to The Braai Guy pickup location"
                  href="https://maps.app.goo.gl/WJLuHu7EvvhpikXa7"
                >
                  {languageHandler("email", "message-pickup-link-text", 1)}
                </a>
                {/* 
                //* service request change mentioned above
                <p>{languageHandler("address", "address-line-1", 1)}</p>
                <p>{languageHandler("address", "address-line-2", 1)}</p>
                <p>{languageHandler("address", "address-line-3", 1)}</p>
                <p>973</p> */}
                <p>{languageHandler("email", "message-please-contact", 1)}</p>
              </div>
            ) : null}

            {props.contactInfo.PaymentMethod === "Bank Transfer" ? (
              <div>
                <h3>
                  {languageHandler("banking", "bank-details-title", 1)}The Braai
                  Guy Banking Details
                </h3>
                <table>
                  <tr>
                    <th>
                      {languageHandler("banking", "bank-name-label", 1)}Bank
                      Name:
                    </th>
                    <th>
                      {languageHandler("banking", "bank-name-text", 1)}Esun
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {languageHandler("banking", "acc-number-label", 1)}Account
                      Number:
                    </th>
                    <th>0325 968 059 356</th>
                  </tr>
                  <tr>
                    <th>
                      {languageHandler("banking", "bank-code-label", 1)}Branch
                      Code:
                    </th>
                    <th>808</th>
                  </tr>
                  <tr>
                    <th>
                      {languageHandler("banking", "payment-ref-label", 1)}
                      Payment Reference:
                    </th>
                    <th>{props.orderNumber}</th>
                  </tr>
                </table>
                <p>
                  {languageHandler(
                    "email",
                    "message-reference-number",
                    1
                  ).replace("ORDER_NUMBER", props.orderNumber)}
                </p>

                <p>
                  {languageHandler("email", "message-transfer-confirmation", 1)}
                </p>
              </div>
            ) : null}
          </div>
        );
        message = (
          <div>
            <p>
              {languageHandler("email", "message-order-received", 1).replace(
                "ORDER_NUMBER",
                props.orderNumber
              )}
            </p>
            <p>{paramBasketTable}</p>
            <p>
              {languageHandler("email", "message-thanks-customer", 1).replace(
                "CUSTOMER_NAME",
                props.to_name
              )}
            </p>
            {languageHandler("email", "message-contact-socials", 1)}
          </div>
        );
        return message;
      case "inTransit":
        message = (
          <div>
            <p>{languageHandler("email", "message-order-sent", 1)}</p>

            {props.paymentMethod === "Cash on Delivery" ? (
              <p>
                {languageHandler("email", "message-payment-amount", 1).replace(
                  "PAYMENT_AMOUNT",
                  `${
                    Math.ceil(
                      (props.totalPrice / 100) * (100 - props.discount)
                    ) + 160
                  }`
                )}
                {/* The total payment due at the point of delivery is NTD{" "}
								{Math.ceil((props.totalPrice / 100) * (100 - props.discount)) +
									160} */}
              </p>
            ) : null}

            <p>{languageHandler("email", "message-keep-phone", 1)}</p>
            <p>{languageHandler("email", "message-not-received", 1)}</p>
            <p>{languageHandler("email", "message-best-wishes", 1)}</p>
          </div>
        );
        return message;
      case "completed":
        message = (
          <div>
            <p>{languageHandler("email", "message-order-completed", 1)}</p>
            <p>{languageHandler("email", "message-best-wishes", 1)}</p>
          </div>
        );
        return message;
      case "cancelled":
        message = (
          <div>
            <p>{languageHandler("email", "message-order-cancelled", 1)}</p>
            <p>
              {languageHandler("email", "message-reference-number", 1).replace(
                "ORDER_NUMBER",
                props.orderReference
              )}
            </p>
            <p>{languageHandler("email", "message-best-wishes", 1)}</p>
          </div>
        );
        return message;
      default:
        message = (
          <div>
            <p>
              {languageHandler("email", "message-order-received", 1).replace(
                "ORDER_NUMBER",
                props.orderNumber
              )}
            </p>
            <p>{paramBasketTable}</p>
            <p>
              {languageHandler("email", "message-thanks-customer", 1).replace(
                "CUSTOMER_NAME",
                props.to_name
              )}
            </p>
            {languageHandler("email", "message-contact-socials", 1)}
          </div>
        );
        return message;
    }
  };

  return (
    <>
      <div>{getMessage(props.status)}</div>
      <hr />
      <div>{getMessageChinese(props.status)}</div>
    </>
  );
}

export default emailTemplates;
