import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import * as actions from "../../store/actions/index";

const LanguageToggler = (props) => {
	return (
		<div className="pt-3 pb-3">
			<Button
				onClick={() => props.toggleLanguage(0)}
				variant={props.language === 0 ? "info" : "outline-info"}
			>
				English
			</Button>
			<Button
				onClick={() => props.toggleLanguage(1)}
				variant={props.language === 1 ? "info" : "outline-info"}
			>
				中文
			</Button>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		language: state.store.language,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleLanguage: (language) => dispatch(actions.toggleLanguage(language)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageToggler);
