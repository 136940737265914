// PRODUCT CARDS
/*======================================================================================================================================== 
                                                        PRODUCT CARD MAP
maps products in store to each card and passes the props for that product to each individual card component            


========================================================================================================================================*/
import React from "react";
import Card from "./Card/Card";
import classes from "./Cards.module.css";
import * as actions from "../../../store/actions/index";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";

const cards = (props) => {
	const obj = props.products;
	let card = props.keyArr.map((prod) => {
		return (
			<Card
				show={props.show}
				shouldUpdate={props.shouldUpdate}
				salesPercentage={obj[prod].SalesPercentage}
				updateStoreState={props.updateStoreState}
				btnType={props.btnType}
				className={classes.Card}
				key={obj[prod].Id}
				title={obj[prod].Title}
				price={obj[prod].Price}
				desc={obj[prod].Title}
				id={obj[prod].Id}
				property={obj[prod]}
				image={obj[prod].Image}
				objKey={prod}
				showEditProductModal={(objKey) => props.showEditProductModal(objKey)}
				showProductModal={(objKey) => props.showProductModal(objKey)}
				/* addToCartClicked={(prodKey, qty, basket) =>
					props.addToCartClicked(prodKey, qty, basket)
				} */
				{...props}
			/>
		);
	});
	if (props.sortClicked) {
		card = props.keysToRender.map((prod) => {
			return (
				<Card
					show={props.show}
					shouldUpdate={props.shouldUpdate}
					salesPercentage={obj[prod].SalesPercentage}
					updateStoreState={props.updateStoreState}
					btnType={props.btnType}
					className={classes.Card}
					key={obj[prod].Id}
					title={obj[prod].Title}
					price={obj[prod].Price}
					desc={obj[prod].Title}
					id={obj[prod].Id}
					property={obj[prod]}
					image={obj[prod].Image}
					objKey={prod}
					showEditProductModal={(objKey) => props.showEditProductModal(objKey)}
					showProductModal={(objKey) => props.showProductModal(objKey)}
					/* addToCartClicked={(prodKey, qty, basket) =>
						props.addToCartClicked(prodKey, qty, basket)
					} */
					{...props}
				/>
			);
		});
	} else {
		card = props.keyArr.map((prod) => {
			return (
				<Card
					show={props.show}
					shouldUpdate={props.shouldUpdate}
					salesPercentage={obj[prod].SalesPercentage}
					updateStoreState={props.updateStoreState}
					btnType={props.btnType}
					className={classes.Card}
					key={obj[prod].Id}
					title={obj[prod].Title}
					price={obj[prod].Price}
					desc={obj[prod].Title}
					id={obj[prod].Id}
					property={obj[prod]}
					image={obj[prod].Image}
					objKey={prod}
					showEditProductModal={(objKey) => props.showEditProductModal(objKey)}
					showProductModal={(objKey) => props.showProductModal(objKey)}
					/* addToCartClicked={(prodKey, qty, basket) =>
						props.addToCartClicked(prodKey, qty, basket)
					} */
					basket={props.basket}
					{...props}
				/>
			);
		});
	}

	return (
		<Row
			className={`justify-content-center pl-sm-0 pr-sm-0 pl-md-5 pr-md-5 pl-lg-5 pr-lg-5 ${classes.Cards}`}
		>
			{card}
		</Row>
	);
};

const mapStateToProps = (state) => {
	return {
		totalPrice: state.store.totalPrice,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddToCartClicked: (prodKey, qty, basket, itemPrice, totalPrice) =>
			dispatch(actions.addToCart(prodKey, qty, basket, itemPrice, totalPrice)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(cards);
