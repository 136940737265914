/*======================================================================================================================================== 
                                                        ORDER ACTIONS
actions to fetch orders from the database                                                         
========================================================================================================================================*/
import * as actionTypes from "../actions/action-types";
import { app } from "../../firebase/firebase";
import { getDatabase, ref as dbref, onValue, update } from "firebase/database";

export const fetchOrdersSuccess = (orders) => {
  return {
    type: actionTypes.FETCH_ORDERS_SUCCESS,
    orders: orders,
  };
};

export const fetchOrdersFail = (err) => {
  return {
    type: actionTypes.FETCH_ORDERS_FAIL,
    error: err,
  };
};

export const fetchOrdersStart = () => {
  return {
    type: actionTypes.FETCH_ORDERS_START,
  };
};

export const fetchOrders = (orders) => {
  return (dispatch) => {
    dispatch(fetchOrdersStart());
    const db = getDatabase(app);
    const ordersRef = dbref(db, "Orders/");
    onValue(ordersRef, (snapshot) => {
      const data = snapshot.val();
      dispatch(fetchOrdersSuccess(data));
    });
    /* firebase
			.database()
			.ref("Orders")
			.on("value", (snapshot) => {
				const data = snapshot.val();
				dispatch(fetchOrdersSuccess(data));
			}); */
  };
};

export const updateOrderStatusStart = () => {
  return {
    type: actionTypes.UPDATE_ORDER_STATUS_START,
  };
};

export const updateOrderStatusSuccess = () => {
  return {
    type: actionTypes.UPDATE_ORDER_STATUS_SUCCESS,
  };
};

export const updateOrderStatusFail = () => {
  return {
    type: actionTypes.UPDATE_ORDER_STATUS_FAIL,
  };
};
// REMINDER
export const updateOrderStatus = (order, orderKey) => {
  return (dispatch) => {
    dispatch(updateOrderStatusStart());
    const updatedOrder = { ...order };
    let updates = {};
    updates[orderKey] = updatedOrder;
    const db = getDatabase(app);
    const ordersRef = dbref(db, "Orders/");
    update(ordersRef, updates)
      .then(() => {
        dispatch(updateOrderStatusSuccess());
      })
      .catch((error) => {
        console.error("Error updating order status", error);
        dispatch(updateOrderStatusFail(error));
      });
    /* firebase
			.database()
			.ref()
			.child("Orders")
			.update(update)
			.then((error) => {
				if (error) {
					dispatch(updateOrderStatusFail(error));
				} else {
					dispatch(updateOrderStatusSuccess());
					/* this.props.history.push("/");
					this.props.history.push("/auth-store"); 
				}
			}); */
  };
};
