import emailjs from "emailjs-com";

// I got the http 200 response back "OK" for this request
// We need to edit the stuff in the email js console next time we have a session

export const sendEmailHandler = (templateParams, templateID) => {
	emailjs
		.send(
			"default_service",
			templateID,
			templateParams,
			"user_VtPHlKm2Iq8hZr5I3Ocqi"
		)
		.then(
			(result) => {
				console.info("Email Sent:", result.text);
			},
			(error) => {
				console.error("ERROR SENDING EMAIL", error.text);
			}
		);
};
