/*======================================================================================================================================== 
                                                        STORE ACTIONS
actions to get products from the database                                                        
========================================================================================================================================*/
import * as actionTypes from "./action-types";
import { app } from "../../firebase/firebase";
import {
  getDatabase,
  ref as dbref,
  onValue,
  update,
  remove,
} from "firebase/database";
import { v4 as uuidv4 } from "uuid";

/* =====================================================================================================================================
                                                        Functions that handle adding new product filters
*/
//=======================================================================================================================================
export const addFilterSuccess = () => {
  return {
    type: actionTypes.ADD_FILTER_SUCCESS,
    adding: false,
  };
};

export const addFilterFail = (error) => {
  return {
    type: actionTypes.ADD_FILTER_FAIL,
    error: error,
  };
};

export const addFilterStart = () => {
  return {
    type: actionTypes.ADD_FILTER_START,
    adding: true,
  };
};
export const addFilter = (newFilter) => {
  return (dispatch) => {
    dispatch(addFilterStart());
    const newFilterKey = uuidv4();
    let newFilterToPush = {};
    newFilterToPush[newFilterKey] = {
      filterKey: newFilterKey,
      value: [...newFilter],
    };
    const db = getDatabase(app);
    const filtersRef = dbref(db, "Filters/");
    update(filtersRef, newFilterToPush)
      .then(() => dispatch(addFilterSuccess()))
      .catch((error) => dispatch(addFilterFail(error)));
    /* firebase
			.database()
			.ref("Filters")
			.update(newFilterToPush)
			.then((error) => {
				if (error) {
					dispatch(addFilterFail(error));
				} else {
					dispatch(addFilterSuccess());
				}
			}); */
  };
};

/* =====================================================================================================================================
                                                        Functions that handle deleting product filters
*/
//=======================================================================================================================================
export const deleteFilterSuccess = () => {
  return {
    type: actionTypes.DELETE_FILTER_SUCCESS,
    deleting: false,
  };
};

export const deleteFilterFail = (error) => {
  return {
    type: actionTypes.DELETE_FILTER_FAIL,
    error: error,
  };
};

export const deleteFilterStart = () => {
  return {
    type: actionTypes.DELETE_FILTER_START,
    deleting: true,
  };
};
export const deleteFilter = (filterKey) => {
  return (dispatch) => {
    dispatch(deleteFilterStart());
    const db = getDatabase(app);
    const filterRef = dbref(db, `Filters/${filterKey}`);
    remove(filterRef)
      .then(() => dispatch(deleteFilterSuccess()))
      .catch((error) => dispatch(deleteFilterFail(error)));
    /* firebase
      .database()
      .ref("Filters/" + filterKey)
      .remove()
      .then((error) => {
        if (error) {
          dispatch(deleteFilterFail(error));
        } else {
          dispatch(deleteFilterSuccess());
        }
      }); */
  };
};

/* =====================================================================================================================================
                                                        Functions that handle adding new product filters
*/
//=======================================================================================================================================
export const fetchFiltersSuccess = (filters) => {
  let filterArray = filters && Object.values(filters);
  return {
    type: actionTypes.FETCH_FILTERS_SUCCESS,
    fetching: false,
    filters: filterArray,
  };
};

export const fetchFiltersFail = (error) => {
  return {
    type: actionTypes.FETCH_FILTERS_FAIL,
    error: error,
  };
};

export const fetchFiltersStart = () => {
  return {
    type: actionTypes.FETCH_FILTERS_START,
    fetching: true,
  };
};
export const fetchFilters = (newFilter) => {
  return (dispatch) => {
    dispatch(fetchFiltersStart());
    const db = getDatabase(app);
    const filterRef = dbref(db, "Filters/");
    onValue(filterRef, (snapshot) => {
      const data = snapshot.val();
      return dispatch(fetchFiltersSuccess(data));
    });
    /* firebase
      .database()
      .ref("Filters")
      .on("value", (snapshot) => {
        const data = snapshot.val();
        dispatch(fetchFiltersSuccess(data));
      }); */
  };
};

/* =====================================================================================================================================
                                                        Functions that handle adding a new product to the backend
*/
//=======================================================================================================================================
export const addNewProductSuccess = () => {
  return {
    type: actionTypes.ADD_NEW_PRODUCT_SUCCESS,
    adding: false,
    addingCode: false,
  };
};

export const addNewProductFail = (error) => {
  return {
    type: actionTypes.ADD_NEW_PRODUCT_FAIL,
    error: error,
  };
};

export const addNewProductStart = () => {
  return {
    type: actionTypes.ADD_NEW_PRODUCT_START,
    adding: true,
  };
};

export const addNewProduct = (product) => {
  return (dispatch) => {
    dispatch(addNewProductStart());
    const addProduct = { ...product };
    const newProdKey = uuidv4();
    const db = getDatabase(app);
    const productsRef = dbref(db, `Products/${newProdKey}`);
    update(productsRef, addProduct)
      .then(() => dispatch(addNewProductSuccess()))
      .catch((error) => dispatch(addNewProductFail(error)));
    /* firebase
      .database()
      .ref("Products")
      .update(newProduct)
      .then((error) => {
        if (error) {
          ;
        } else {
          
          /* this.props.history.push("/");
					this.props.history.push("/auth-store"); 
        }
      }); */
  };
};

/* =====================================================================================================================================
                                                        Functions that handle editing the products from the backend
*/
export const editProductSuccess = (objKey) => {
  return {
    type: actionTypes.EDIT_PRODUCT_SUCCESS,
  };
};

export const editProductFail = (error) => {
  return {
    type: actionTypes.EDIT_PRODUCT_FAIL,
    error: error,
  };
};

export const editProductStart = () => {
  return {
    type: actionTypes.EDIT_PRODUCT_START,
  };
};

export const editProduct = (products, objKey) => {
  return (dispatch) => {
    dispatch(editProductStart());
    const product = { ...products };
    let updates = {};
    updates[objKey] = product;
    const db = getDatabase(app);
    const productsRef = dbref(db, "Products/");
    update(productsRef, updates)
      .then(() => dispatch(editProductSuccess()))
      .catch((error) => dispatch(editProductFail(error)));
    /* firebase
      .database()
      .ref()
      .child("Products")
      .update(update)
      .then((error) => {
        if (error) {
          ;
        } else {
          ;
          /* this.props.history.push("/");
					this.props.history.push("/auth-store"); 
        }
      }); */
  };
};

/* =====================================================================================================================================
                                                        Functions that handle deleting the products from the backend
*/
export const deleteProductSuccess = () => {
  return {
    type: actionTypes.DELETE_PRODUCT_SUCCESS,
    deleting: false,
  };
};

export const deleteProductFail = (error) => {
  return {
    type: actionTypes.DELETE_PRODUCT_FAIL,
    error: error,
  };
};

export const deleteProductStart = () => {
  return {
    type: actionTypes.DELETE_PRODUCT_START,
    deleting: true,
  };
};

export const deleteProduct = (objKey) => {
  return (dispatch) => {
    dispatch(deleteProductStart());
    const db = getDatabase(app);
    const productRef = dbref(db, `Products/${objKey}`);
    remove(productRef)
      .then(() => dispatch(deleteProductSuccess()))
      .catch((error) => dispatch(deleteProductFail(error)));
    /* firebase
      .database()
      .ref("Products/" + objKey)
      .remove()
      .then((error) => {
        if (error) {
          ;
        } else {
          ;
          //this.props.history.push("/");
          //this.props.history.push("/auth-store");
        }
      }); */
  };
};

/* =====================================================================================================================================
                                                        Functions that handle adding promo codes product to the backend
*/
//=======================================================================================================================================
export const addPromoCodeSuccess = () => {
  return {
    type: actionTypes.ADD_PROMO_CODE_SUCCESS,
    addingCode: false,
  };
};

export const addPromoCodeFail = (error) => {
  return {
    type: actionTypes.ADD_PROMO_CODE_FAIL,
    error: error,
  };
};

export const addPromoCodeStart = () => {
  return {
    type: actionTypes.ADD_PROMO_CODE_START,
    addingCode: true,
  };
};

export const addPromoCode = (codes) => {
  return (dispatch) => {
    dispatch(addPromoCodeStart());
    const addCodes = { ...codes };
    const newCodeKey = uuidv4();
    let newCodes = {};
    newCodes[newCodeKey] = addCodes;
    const db = getDatabase(app);
    const codesRef = dbref(db, "PromoCodes/");
    update(codesRef, newCodes)
      .then(() => dispatch(addPromoCodeSuccess()))
      .catch((error) => dispatch(addPromoCodeFail(error)));
    /* firebase
      .database()
      .ref("PromoCodes")
      .update(newCodes)
      .then((error) => {
        if (error) {
          ;
        } else {
          ;
          /* this.props.history.push("/");
					this.props.history.push("/auth-store"); 
        }
      }); */
  };
};

/* =====================================================================================================================================
                                                        Functions that handle fetching the promo codes from the backend
*/
export const fetchPromoCodeSuccess = (promoCodes) => {
  return {
    type: actionTypes.FETCH_PROMO_CODE_SUCCESS,
    promoCodes: promoCodes,
  };
};

export const fetchPromoCodeFail = (err) => {
  return {
    type: actionTypes.FETCH_PROMO_CODE_FAIL,
    error: err,
  };
};

export const fetchPromoCodeStart = () => {
  return {
    type: actionTypes.FETCH_PROMO_CODE_START,
  };
};

export const fetchPromoCode = () => {
  return (dispatch) => {
    dispatch(fetchPromoCodeStart());
    const db = getDatabase(app);
    const codesRef = dbref(db, "PromoCodes/");
    onValue(codesRef, (snapshot) => {
      const data = snapshot.val();
      return dispatch(fetchPromoCodeSuccess(data));
    });
    /* firebase
      .database()
      .ref("PromoCodes")
      .on("value", (snapshot) => {
        
      }); */
  };
};
/* =====================================================================================================================================
                                                        Functions that handle editing the products from the backend
*/
export const editPromoCodeSuccess = (objKey) => {
  return {
    type: actionTypes.EDIT_PROMO_CODE_SUCCESS,
  };
};

export const editPromoCodeFail = (error) => {
  return {
    type: actionTypes.EDIT_PROMO_CODE_FAIL,
    error: error,
  };
};

export const editPromoCodeStart = () => {
  return {
    type: actionTypes.EDIT_PROMO_CODE_START,
  };
};

export const editPromoCode = (promoCodes, promoCode) => {
  return (dispatch) => {
    dispatch(editPromoCodeStart());

    const newPromoCodes = { ...promoCodes };
    const arrayOfKeys = Object.keys(promoCodes);
    let batchKey = null;
    let index = null;

    arrayOfKeys.map((key) => {
      return promoCodes[key].map((item, idx) => {
        if (item.code === promoCode || item.code === promoCode.code) {
          batchKey = key;
          index = idx;
        }
        return null;
      });
    });

    let updatedBatch = newPromoCodes[batchKey];
    updatedBatch[index].used = true;

    let updates = {};
    updates[batchKey] = updatedBatch;
    const db = getDatabase(app);
    const codesRef = dbref(db, "PromoCodes/");
    update(codesRef, updates)
      .then(() => dispatch(editPromoCodeSuccess()))
      .catch((error) => dispatch(editPromoCodeFail(error)));

    /* firebase
      .database()
      .ref()
      .child("PromoCodes")
      .update(update)
      .then((error) => {
        if (error) {
          ;
        } else {
          ;
        }
      }); */
  };
};

/* =====================================================================================================================================
                                                        Functions that handle deleting the products from the backend
*/
export const deletePromoCodeSuccess = () => {
  return {
    type: actionTypes.DELETE_PROMO_CODE_SUCCESS,
    deleting: false,
  };
};

export const deletePromoCodeFail = (error) => {
  return {
    type: actionTypes.DELETE_PROMO_CODE_FAIL,
    error: error,
  };
};

export const deletePromoCodeStart = () => {
  return {
    type: actionTypes.DELETE_PROMO_CODE_START,
    deleting: true,
  };
};

export const deletePromoCode = (promoCodes, batchId) => {
  return (dispatch) => {
    dispatch(deletePromoCodeStart());

    const arrayOfKeys = Object.keys(promoCodes);
    let batchKey = null;

    arrayOfKeys.map((key) => {
      return promoCodes[key].map((item, idx) => {
        if (item.id === batchId) {
          batchKey = key;
        }
        return null;
      });
    });
    const db = getDatabase(app);
    const codesRef = dbref(db, `PromoCodes/${batchKey}`);
    remove(codesRef)
      .then(() => dispatch(deletePromoCodeSuccess()))
      .catch((error) => dispatch(deletePromoCodeFail(error)));

    /* firebase
      .database()
      .ref("PromoCodes/" + batchKey)
      .remove()
      .then((error) => {
        if (error) {
          ;
        } else {
          ;
          //this.props.history.push("/");
          //this.props.history.push("/auth-store");
        }
      }); */
  };
};
