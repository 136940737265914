import React from "react";
import {Row, Col} from 'react-bootstrap';
import classes from './Card.module.css';

const adminCard = (props) => {
	return (
		<Row className="bg-white">
			<Col className="mx-auto text-center" xs={12} sm={12} md={3} lg={3} xl={3}>
				<div className="card h-100">
					<div className={classes.ImageContainer}>
					<img
						className="card-img-top card-img"
						src={props.image || "https://via.placeholder.com/200"}
						alt="Add New Product"
					/>
					</div>
					<div className="card-body">
						<h4 className="card-title text-primary">{props.title}</h4>

						{props.children}
					</div>
				</div>
			</Col>
		</Row>
	);
};
export default adminCard;
