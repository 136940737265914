/*======================================================================================================================================== 
													UTILITY FUNCTION
this utility function helps us to update the necessary properties during an action in an immutable way.
========================================================================================================================================*/


export const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties,
	};
};
