/*======================================================================================================================================== 
                                                        STORE ACTIONS
actions to get products from the database                                                        
========================================================================================================================================*/
import * as actionTypes from "../actions/action-types";
import { app } from "../../firebase/firebase";
import { getDatabase, ref as dbref, onValue } from "firebase/database";
import axios from "axios";
/* =====================================================================================================================================
                                                        Functions that handle language toggling
*/
export const toggleLanguage = (language) => {
  return {
    type: actionTypes.TOGGLE_LANGUAGE,
    language: language,
  };
};

/* =====================================================================================================================================
                                                        Functions that handle fetching the products from the backend
*/
export const fetchProductSuccess = (products, keys, uniqueProdTypes) => {
  return {
    type: actionTypes.FETCH_PRODUCTS_SUCCESS,
    products: products,
    keyArr: keys,
    uniqueProdTypeArr: uniqueProdTypes,
  };
};

export const fetchProductsFail = (err) => {
  return {
    type: actionTypes.FETCH_PRODUCTS_FAIL,
    error: err,
  };
};

export const fetchProductsStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCTS_START,
  };
};

export const fetchProducts = () => {
  return (dispatch) => {
    dispatch(fetchProductsStart());
    const db = getDatabase(app);
    const productsRef = dbref(db, "Products/");
    onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      let ProdTypeArr = [];
      const keyArr = Array.from(Object.keys(data), (k) => [`${k}`]);

      for (let values in keyArr) {
        ProdTypeArr.push(data[keyArr[values]].Type);
      }
      const uniqueProdTypeArr = ProdTypeArr.filter(
        (a, b) => ProdTypeArr.indexOf(a) === b
      );
      dispatch(fetchProductSuccess(data, keyArr, uniqueProdTypeArr));
    });
    /* firebase
			.database()
			.ref("Products")
			.on("value", (snapshot) => {
				const data = snapshot.val();
				let ProdTypeArr = [];
				const keyArr = Array.from(Object.keys(data), (k) => [`${k}`]);

				for (let values in keyArr) {
					ProdTypeArr.push(data[keyArr[values]].Type);
				}
				const uniqueProdTypeArr = ProdTypeArr.filter(
					(a, b) => ProdTypeArr.indexOf(a) === b
				);
				dispatch(fetchProductSuccess(data, keyArr, uniqueProdTypeArr));
			}); */
  };
};

/* =====================================================================================================================================
                                                        Functions that handle adjusting the items in the shopping cart
*/

// handles adding an item from the store into the shopping cart
export const addToCart = (prodKey, qty, basket, itemPrice, totalPrice) => {
  let newBasket = basket ? [...basket] : [];
  const item = {
    key: prodKey,
    qty,
  };
  if (newBasket.length === 0) {
    newBasket.push(item);
  }
  if (basket.length > 0) {
    let index = undefined;
    newBasket.forEach((item, idx) => {
      if (item.key[0] === prodKey[0]) {
        index = idx;
      } else return;
    });
    if (index !== undefined) {
      newBasket[index].qty += +qty;
    } else newBasket.push(item);
  }
  const newTotalPrice = +totalPrice + +itemPrice * qty;
  const newItemQty = newBasket.reduce((currentQty, basketItem) => {
    return currentQty + basketItem.qty;
  }, 0);
  /* //push all the keys of the items in the basket to an array of just the keys
  Array.isArray(basket) &&
    basket.map((item) => {
      return basketKeyArr.push(item.key);
    });

  //extract the index of the targeted item in the basket
  const index = basketKeyArr.indexOf(prodKey);

  // creates a new item that will be added to the basket array
  let item = {
    key: prodKey,
    qty: qty,
  };

  //calculating total price

  
  //checks to see if the product key is already in the basket array,
  //if it is, it will increment the existing item quantity.
  //If not, it will push the new item to the basket array
  if (!basketKeyArr.includes(prodKey)) {
    newBasket.push(item);
  } else {
    newBasket[index].qty = +newBasket[index].qty + +qty;
  }

  newBasket.map((item) => {
    return basketQtyArr.push(+item.qty);
  });

  let newItemQty = 0;
  newItemQty = basketQtyArr.reduce((a, b) => a + b, 0); */

  return {
    type: actionTypes.ADD_TO_CART,
    basket: newBasket,
    basketItemQty: newItemQty,
    totalPrice: newTotalPrice,
  };
};

// handles the increment of the cart items
export const incrementCartItem = (prodKey, basket, itemPrice, totalPrice) => {
  let newBasket = basket;
  let basketKeyArr = [];

  //push all the keys of the items in the basket to an array of just the keys
  basket.map((item) => {
    return basketKeyArr.push(item.key[0]);
  });

  //extract the index of the targeted item in the basket
  const index = basketKeyArr.indexOf(prodKey);

  //calculating total price

  const newTotalPrice = +totalPrice + +itemPrice;

  //increment the quantity of the the item in the basket
  newBasket[index].qty = +newBasket[index].qty + 1;
  let newItemQty = 0;
  for (let items in newBasket) {
    newItemQty = +newItemQty + +newBasket[items].qty;
  }
  //set the basket in redux state to the newBasket
  return {
    type: actionTypes.INCREMENT_CART_ITEM,
    basket: newBasket,
    basketItemQty: newItemQty,
    totalPrice: newTotalPrice,
  };
};

export const decrementCartItem = (prodKey, basket, itemPrice, totalPrice) => {
  let newBasket = basket;
  let basketKeyArr = [];

  //push all the keys of the items in the basket to an array of just the keys
  basket.map((item) => {
    return basketKeyArr.push(item.key[0]);
  });

  //extract the index of the targeted item in the basket
  const index = basketKeyArr.indexOf(prodKey);

  //calculating total price

  const newTotalPrice = totalPrice - +itemPrice;

  //decrement the quantity of the the item in the basket
  if (newBasket[index].qty > 1) {
    newBasket[index].qty = +newBasket[index].qty - 1;
  } else {
    removeCartItem(prodKey, newBasket);
  }
  let newItemQty = 0;
  for (let items in newBasket) {
    newItemQty = +newItemQty + +newBasket[items].qty;
  }

  //set the basket in redux state to the newBasket
  return {
    type: actionTypes.INCREMENT_CART_ITEM,
    basket: newBasket,
    basketItemQty: newItemQty,
    totalPrice: newTotalPrice,
  };
};

export const removeCartItem = (prodKey, basket, itemPrice, totalPrice) => {
  let newBasket = basket;
  let basketKeyArr = [];

  //push all the keys of the items in the basket to an array of just the keys
  basket.map((item) => {
    return basketKeyArr.push(item.key[0]);
  });

  //extract the index of the targeted item in the basket
  const index = basketKeyArr.indexOf(prodKey);

  const removedItemQty = basket[index].qty;

  const newTotalPrice = +totalPrice - removedItemQty * +itemPrice;

  //remove the item in the basket
  newBasket.splice(index, 1);
  let newItemQty = 0;
  for (let items in newBasket) {
    newItemQty = +newItemQty + +newBasket[items].qty;
  }

  if (typeof totalPrice !== "number") {
    return {
      type: actionTypes.INCREMENT_CART_ITEM,
      basket: [],
      basketItemQty: 0,
      totalPrice: 0,
    };
  }
  //set the basket in redux state to the newBasket
  else
    return {
      type: actionTypes.INCREMENT_CART_ITEM,
      basket: newBasket,
      basketItemQty: newItemQty,
      totalPrice: newTotalPrice,
    };
};

/* =====================================================================================================================================
                                                        Functions that handle posting the order to firebase
*/

export const submitOrderStart = () => {
  return {
    type: actionTypes.SUBMIT_ORDER_START,
  };
};

export const submitOrderFail = (error) => {
  return {
    type: actionTypes.SUBMIT_ORDER_FAIL,
    error: error,
  };
};

export const submitOrderSuccess = () => {
  return {
    type: actionTypes.SUBMIT_ORDER_SUCCESS,
  };
};

export const submitOrder = (order, products, basket) => {
  return (dispatch) => {
    dispatch(submitOrderStart());
    axios
      .post(
        "https://thebraaiguy.com/fbServer/",
        {
          order,
          products,
          basket,
        },
        { timeout: 30000 }
      )
      .then(() => {
        return dispatch(submitOrderSuccess());
      })
      .catch((error) => {
        if (typeof error === "object") {
          return dispatch(submitOrderFail(error));
        }
        let formattedError = {
          order: "failed",
          error,
          message: "Oops! Something went wrong when posting your order",
          rampantKey: 103,
          trace:
            "Firebase failed to process this update, the error response may contain more helpful information. This is most likely due to a failed authentication to the database",
        };
        return dispatch(submitOrderFail(formattedError));
      });
    /* const newOrderKey = uuidv4();
    const addOrder = {
      order,
      orderKey: newOrderKey,
    };

    let updatedProducts = { ...products };

    for (let x = 0; x < basket.length; x++) {
      updatedProducts[basket[x].key[0]].Qty =
        +products[basket[x].key[0]].Qty - basket[x].qty;
    }
    const db = getDatabase(app);
    let updates = {};
    updates["/Products/"] = updatedProducts;
    updates[`/Orders/${newOrderKey}`] = addOrder;
    update(dbref(db), updates)
      .then(() => {
        dispatch(submitOrderSuccess());
      })
      .catch((error) => {
        console.error("Error adding order", error);
        dispatch(submitOrderFail(error));
      }); */
  };
};

/* =====================================================================================================================================
                                                        Functions that handle updating total price value
*/

export const updateTotalPrice = (newTotalPrice) => {
  return {
    type: actionTypes.UPDATE_TOTAL_PRICE,
    totalPrice: newTotalPrice,
  };
};
