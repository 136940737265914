/*======================================================================================================================================== 
  PRODUCT CARDS
takes products as props and outputs a single card with title, image and details
Button and input added for edit of qty and add to cart 
NOTE: Should pass a prop down for btn type so that we can edit the stock qty in the back end if the user is authenticated - must discuss
========================================================================================================================================*/
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import React, { Component } from "react";
import classes from "./Card.module.css";
import { TagsFill } from "react-bootstrap-icons";
import { languageHandler } from "../../../../util/language/functions";

class Card extends Component {
	state = {
		isOutOfStock: false,
		serverQty: 0,
		basketQty: 0,
		basketObj: null,
		shouldUpdate: true,
	};

	componentDidMount = () => {
		let tempBasketObj = this.props.basket.find((product) => {
			return product["key"][0] === this.props.objKey[0];
		});

		let serverQty = +this.props.products[this.props.objKey[0]].Qty;

		let basketQty = 0;

		let isOutOfStock = false;

		if (!tempBasketObj) {
			basketQty = 0;
		} else {
			basketQty = +tempBasketObj.qty;
		}

		if (serverQty === 0) {
			isOutOfStock = true;
		} else if (serverQty - basketQty <= 0) {
			isOutOfStock = true;
		} else {
			isOutOfStock = false;
		}

		this.setState({
			serverQty: serverQty,
			basketObj: tempBasketObj,
			basketQty: basketQty,
			isOutOfStock: isOutOfStock,
			shouldUpdate: this.props.shouldUpdate,
		});
	};

	// adds a product to the basket, apdates a dummy state property in the meat store to update the cards to show disabled buttons
	addToCartClicked = (objKey, qty, basket, itemPrice, totalPrice) => {
		/* this.checkStock(objKey, qty, basket); */
		if (!this.state.isOutOfStock) {
			this.props.AddToCartHandler(objKey, qty, basket, itemPrice, totalPrice);
			this.props.updateStoreState();
			this.handleVisible();
			/* this.checkStock(objKey, qty, basket); */
		}
		this.forceUpdate();
	};

	handleVisible = () => {
		this.setState({ alertVisible: true });
		setTimeout(() => {
			this.setState({ alertVisible: false });
		}, 500);
	};

	render() {
		// check the basket length for an empty basket, reenables all buttons
		// then immediate check the stock quantity from firebase, and disable any out of stock products in the store.
		let isOutofStock = false;

		if (
			this.props.basket.length > 0 &&
			this.props.basket.some((product) => {
				return product["key"][0] === this.props.objKey[0];
			})
		) {
			const basketObject = this.props.basket.find((product) => {
				return product["key"][0] === this.props.objKey[0];
			});
			this.props.products[this.props.objKey[0]].Qty - basketObject.qty > 0
				? (isOutofStock = false)
				: this.props.products[this.props.objKey[0]].Qty - basketObject.qty <= 0
				? (isOutofStock = true)
				: (isOutofStock = true);
		} else if (+this.props.products[this.props.objKey[0]].Qty === 0) {
			isOutofStock = true;
		}

		let priceDisplay =
			this.props.salesPercentage === 0 ? (
				<p className="price-display">&#78;&#84;&#36;{this.props.price}</p>
			) : (
				<p className="price-display">
					<del>
						<span className={"hi"}>&#78;&#84;&#36;{this.props.price}</span>
					</del>

					<span style={{ color: "red", marginLeft: "4px" }}>
						&#78;&#84;&#36;
						{Math.ceil(
							(this.props.price * (100 - this.props.salesPercentage)) / 100
						)}
					</span>
				</p>
			);

		let saleIcon =
			this.props.salesPercentage === 0 ? null : (
				<TagsFill className={"sale-icon"} />
			);

		let addToCartBtn = null;
		if (this.props.btnType === "user") {
			addToCartBtn = (
				<>
					{this.state.alertVisible ? (
						<Button
							disabled={/* this.state.isOutOfStock ||  */ isOutofStock}
							className={"btn-light card-btn"}
							onClick={(prodKey, qty, basket, itemPrice, totalPrice) =>
								this.addToCartClicked(
									this.props.objKey,
									1,
									this.props.basket,
									Math.ceil(
										this.props.price *
											((100 - this.props.salesPercentage) / 100)
									),
									this.props.totalPrice
								)
							}
						>
							{languageHandler(
								"store",
								"card-button-added",
								this.props.language
							)}
						</Button>
					) : (
						<Button
							disabled={/* this.state.isOutOfStock ||  */ isOutofStock}
							className={"btn-success card-btn"}
							onClick={(prodKey, qty, basket, itemPrice, totalPrice) =>
								this.addToCartClicked(
									this.props.objKey,
									1,
									this.props.basket,
									Math.ceil(
										this.props.price *
											((100 - this.props.salesPercentage) / 100)
									),
									this.props.totalPrice
								)
							}
						>
							{languageHandler(
								"store",
								"card-button-add-to-cart",
								this.props.language
							)}
						</Button>
					)}
				</>
			);
		} else if (this.props.btnType === null) {
			addToCartBtn = (
				<div>
					<Button
						className={"btn-success card-btn"}
						onClick={(objKey) =>
							this.props.showEditProductModal(this.props.objKey)
						}
					>
						Edit Product
					</Button>
				</div>
			);
		} else addToCartBtn = null;

		return (
			<Col
				xl={2}
				lg={2}
				md={4}
				sm={5}
				xs={5}
				className="mb-2 mt-4 justify-content-center"
			>
				<div className={classes.Card}>
					<Row>
						<div className="card h-100 product-card">
							{saleIcon}
							<Row>
								<div className={classes.ImageContainer}>
									{+this.props.products[this.props.objKey].Qty === 0 && (
										<div
											className="text-center text-white crd-img-overlay"
											style={{
												zIndex: "200",
												position: "absolute",
												bottom: "0",
												width: "100%",
												backgroundColor: "rgba(0,0,0,0.5)",
											}}
										>
											{languageHandler(
												"store",
												"card-stock-warning",
												this.props.language
											)}
										</div>
									)}
									<img
										className={`card-img-top`}
										src={this.props.image}
										alt=""
										onClick={(objKey) => {
											this.props.showProductModal(this.props.objKey);
										}}
									/>
								</div>
							</Row>
							<div className="card-body">
								<Row className="justify-content-center text-center">
									<h4 className="card-title">
										{Array.isArray(this.props.title)
											? this.props.language === 0
												? this.props.title[0]
												: this.props.title[1]
											: this.props.title}
									</h4>
								</Row>
								<Row className="justify-content-center">
									{priceDisplay}
									{this.props.children}
								</Row>

								<Row>
									{this.props.products[this.props.objKey].Qty ? (
										<Col className={"text-center"}>
											{this.props.products[this.props.objKey].Qty}{" "}
											{languageHandler(
												"store",
												"item-stock-info",
												this.props.language
											)}
										</Col>
									) : this.props.products[this.props.objKey].Qty === 0 ? (
										<Col className={"text-center"}>
											{" "}
											{languageHandler(
												"store",
												"card-stock-warning",
												this.props.language
											)}
										</Col>
									) : (
										<Col className={"text-center"}>Unspecified Stock</Col>
									)}
								</Row>

								<Row className={"text-center"}>
									<Col xl={6} lg={6} md={6} sm={6} xs={12} className="btn-col">
										<Button
											className={"btn-primary card-btn"}
											onClick={(objKey) => {
												this.props.showProductModal(this.props.objKey);
											}}
										>
											{languageHandler(
												"store",
												"card-button-details",
												this.props.language
											)}
										</Button>
									</Col>
									<Col xl={6} lg={6} md={6} sm={6} xs={12} className="btn-col">
										{addToCartBtn}
									</Col>
								</Row>
							</div>
						</div>
					</Row>
				</div>
			</Col>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.store.language,
		basket: state.store.basket,
		totalPrice: state.store.totalPrice,
	};
};
// maps actions to props from redux
const mapDispatchToProps = (dispatch) => {
	return {
		AddToCartHandler: (prodKey, qty, basket, itemPrice, totalPrice) =>
			dispatch(actions.addToCart(prodKey, qty, basket, itemPrice, totalPrice)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Card);
