/*======================================================================================================================================== 
                                                    FIREBASE CONFIG AND INIT
initializes firebase with app config and exports the defaults needed
========================================================================================================================================*/
/* import firebase from "firebase/app";
import "firebase/storage";

const config = {
	apiKey: "AIzaSyCBwWqAx8FePbAlSbXXB2WVWlmoNebHEgs",
	authDomain: "braaiguy-153fd.firebaseapp.com",
	databaseURL:
		"https://braaiguy-153fd-default-rtdb.asia-southeast1.firebasedatabase.app",
	projectId: "braaiguy-153fd",
	storageBucket: "braaiguy-153fd.appspot.com",
	messagingSenderId: "360693418182",
	appId: "1:360693418182:web:1d69986f8fe0d088d9afc7",
	measurementId: "G-C4CD8HJKZY",
};

firebase.initializeApp(config);
const storage = firebase.storage();









export { storage, firebase as default }; */

import { initializeApp } from "firebase/app";

// Set the configuration for your app
// TODO: Replace with your project's config object
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);

// Get a reference to the database service by using the app export
//const database = getDatabase(app);
