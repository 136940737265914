/*======================================================================================================================================== 
                                                        ADD TO CART
renders qty input and add to cart button to be rendered on the more info button of product 
productId is set when qty is changed in the qtyChangedHandler
addToCartClicked will send the item to the basket in the redux store                                               
========================================================================================================================================*/
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { languageHandler } from "../../util/language/functions";

class AddToCart extends Component {
	state = {
		basket: [],
		serverQty: 0,
		cartQty: 0,
		inputQty: 0,
		isOutOfStock: false,
	};

	componentDidMount = () => {
		let invQty = +this.props.products[this.props.prodKey[0]].Qty;
		let cartQty = 0;
		let basket = this.props.basket;
		let basketObj = this.props.basket.find((item) => {
			return item["key"][0] === this.props.prodKey[0];
		});
		let isOutOfStock = false;

		if (basket.length === 0) {
			cartQty = 0;
		} else if (basket.length > 0 && basketObj === (null || undefined)) {
			cartQty = 0;
		} else if (basket.length !== 0 && basketObj !== (null || undefined)) {
			cartQty = +basketObj.qty;
		}

		if (invQty === 0) {
			isOutOfStock = true;
		}

		if (basketObj && +invQty <= +basketObj.qty) {
			isOutOfStock = true;
		}

		this.setState({
			serverQty: invQty,
			cartQty: cartQty,
			isOutOfStock: isOutOfStock,
		});
	};

	//handles the two way binding of the input element for adjusting the qty to add to the cart
	qtyChangeHandler = (event) => {
		//checks the stock quantity of the item and limits the qty to the qty in the stock
		if (+this.state.serverQty - +this.state.cartQty >= event.target.value) {
			this.setState({
				inputQty: parseInt(event.target.value, 10),
				outOfStock: false,
			});
		} else {
			this.setState({
				inputQty: +this.state.serverQty - +this.state.cartQty,
				outOfStock: true,
			});
		}
	};

	onAddToCartClicked = (prodKey, basket) => {
		const itemPrice =
			this.props.products[this.props.prodKey].Price *
			((100 - this.props.products[this.props.prodKey].SalesPercentage) / 100);
		if (this.state.inputQty !== 0) {
			this.props.onAddToCartHandler(
				this.props.prodKey,
				this.state.inputQty,
				this.props.basket,
				Math.ceil(itemPrice),
				this.props.totalPrice
			);
		}
		this.props.updateStoreState();
	};

	render() {
		return (
			<div className={"container-fluid"}>
				<div className={"row d-flex justify-content-center"}>
					<input
						className={"form-control col-3"}
						type="number"
						min="0"
						placeholder="qty"
						max={+this.state.serverQty - +this.state.cartQty}
						onChange={(event) => this.qtyChangeHandler(event)}
						value={
							this.state.inputQty > +this.state.serverQty - +this.state.cartQty
								? +this.state.serverQty - +this.state.cartQty
								: +this.state.inputQty
						}
					></input>
					<Button
						disabled={this.state.isOutOfStock}
						className={"col-4"}
						onClick={(prodKey, basket) =>
							this.onAddToCartClicked(prodKey, basket)
						}
					>
						{languageHandler(
							"product-modal",
							"add-to-cart-text",
							this.props.language
						)}
					</Button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		basket: state.store.basket,
		loading: state.store.loading,
		products: state.store.products,
		totalPrice: state.store.totalPrice,
		language: state.store.language,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddToCartHandler: (prodKey, qty, basket, itemPrice, totalPrice) =>
			dispatch(actions.addToCart(prodKey, qty, basket, itemPrice, totalPrice)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
