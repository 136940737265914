/*======================================================================================================================================== 
                                                        CART
the modal to show items currently in the basket. this is a stateful component that uses functions and state from redux to do tasks in the cart:
- increment     - decrement     - remove        - proceed to checkout       - modal state                                              
========================================================================================================================================*/
import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Card,
	Button,
	Modal,
	Row,
	Col,
	Container,
	ButtonGroup,
} from "react-bootstrap";
import { BagPlus, BagDash, Trash } from "react-bootstrap-icons";
import * as actions from "../../store/actions/index";
import { checkStockInStore } from "../../util/util.js";
import { languageHandler } from "../../util/language/functions";
import { validatePrice } from "../../util/util";

class Cart extends Component {
	state = {
		disabled: false,
		anyProductOutOfStock: false,
	};
	// we use these functions to trigger a rerender of the component after adjusting the quantity.
	componentDidMount() {
		this.setState({ basket: this.props.basket });
		this.props.fetchProducts();
		checkStockInStore(
			this.props.basket,
			this.props.products,
			+this.props.totalPrice,
			this.props.removeCartItem
		);
		const updatedPrice = validatePrice(
			this.props.basket,
			null,
			this.props.products
		);
		this.props.updateTotalPrice(updatedPrice);
	}
	// inc, dec and remove all fire off functions in redux
	// increments the qty of the item in the basket by 1
	onIncrementCartItem = (prodKey, basket, itemPrice, totalPrice) => {
		this.props.incrementCartItem(prodKey, basket, itemPrice, totalPrice);
		this.setState({ basket: basket });
		const updatedPrice = validatePrice(
			this.props.basket,
			null,
			this.props.products
		);
		this.props.updateTotalPrice(updatedPrice);
		this.props.updateStoreState();
	};
	// decrements the qty of the item in the basket by 1
	onDecrementCartItem = (prodKey, basket, itemPrice, totalPrice) => {
		this.props.decrementCartItem(prodKey, basket, itemPrice, totalPrice);
		this.setState({ basket: basket });
		const updatedPrice = validatePrice(
			this.props.basket,
			null,
			this.props.products
		);
		this.props.updateTotalPrice(updatedPrice);
		this.props.updateStoreState();
	};
	// removes item from the basket
	onRemoveCartItem = (prodKey, basket, itemPrice, totalPrice) => {
		//this.props.decrementCartItem(prodKey, basket);
		this.props.removeCartItem(prodKey, basket, itemPrice, totalPrice);
		this.setState({ basket: basket });
		const updatedPrice = validatePrice(
			this.props.basket,
			null,
			this.props.products
		);
		this.props.updateTotalPrice(updatedPrice);
		this.props.updateStoreState();
	};

	handleProceedToCheckout = () => {
		checkStockInStore(
			this.props.basket,
			this.props.products,
			+this.props.totalPrice,
			this.props.removeCartItem
		);
		setTimeout(() => {
			this.props.history.push("/checkout");
		}, 1000);
	};

	render() {
		//mapping of the basket state into item cards that will be rendered as a list of items in the basket
		let basket = this.props.basket.map((item) => {
			let stockCheck = +this.props.products[item.key[0]].Qty === item.qty;

			return (
				<Card key={item.key} className="container-fluid p-1">
					<Row>
						<Col xs={2} sm={2} md={2} lg={2} xl={2}>
							<img
								className="img-fluid"
								src={this.props.products[item.key[0]].Image}
								alt="Product"
							/>
						</Col>
						<Col xs={10} sm={10} md={10} lg={10} xl={10}>
							<Container fluid>
								<Row className={"pb-2"}>
									<Col xs={6} sm={6} md={6} lg={6} xl={6}>
										{this.props.products[item.key[0]].Title}
									</Col>
									<Col
										xs={6}
										sm={6}
										md={6}
										lg={6}
										xl={6}
										className={"text-right"}
									>
										<ButtonGroup>
											<Button
												onClick={(prodKey, basket, itemPrice, totalPrice) =>
													this.onRemoveCartItem(
														item.key[0],
														this.props.basket,
														Math.ceil(
															this.props.products[item.key[0]].Price *
																((100 -
																	this.props.products[item.key[0]]
																		.SalesPercentage) /
																	100)
														),
														this.props.totalPrice
													)
												}
												className="btn-danger cart-btn-cart"
											>
												<Trash />
											</Button>

											<Button
												onClick={(prodKey, basket, itemPrice, totalPrice) =>
													this.onDecrementCartItem(
														item.key[0],
														this.props.basket,
														Math.ceil(
															this.props.products[item.key[0]].Price *
																((100 -
																	this.props.products[item.key[0]]
																		.SalesPercentage) /
																	100)
														),
														this.props.totalPrice
													)
												}
												className="btn-warning cart-btn-cart text-white"
											>
												<BagDash />
											</Button>
											<span className={"qty-span"}>{item.qty}</span>

											<Button
												disabled={stockCheck}
												onClick={(prodKey, basket, itemPrice, totalPrice) =>
													this.onIncrementCartItem(
														item.key[0],
														this.props.basket,
														Math.ceil(
															this.props.products[item.key[0]].Price *
																((100 -
																	this.props.products[item.key[0]]
																		.SalesPercentage) /
																	100)
														),
														this.props.totalPrice
													)
												}
												className="btn-success cart-btn-cart"
											>
												<BagPlus className={"align-middle"} />
											</Button>
										</ButtonGroup>
									</Col>
								</Row>
								<Row>
									<Col xs={6} sm={6} md={6} lg={6} xl={6}>
										&#78;&#84;&#36;
										{Math.ceil(
											(this.props.products[item.key[0]].Price *
												(100 -
													this.props.products[item.key[0]].SalesPercentage)) /
												100
										)}
									</Col>
									<Col
										xs={6}
										sm={6}
										md={6}
										lg={6}
										xl={6}
										className={"text-right"}
									>
										{/* Total Price:  */}&#78;&#84;&#36;
										{Math.ceil(
											(this.props.products[item.key[0]].Price *
												(100 -
													this.props.products[item.key[0]].SalesPercentage)) /
												100
										) * item.qty}
									</Col>
									{stockCheck && (
										<Col xs={12} sm={12} md={12} lg={12} xl={12}>
											<div className="text-primary cart-btn"> *Max Stock </div>
										</Col>
									)}
								</Row>
							</Container>
						</Col>
					</Row>
				</Card>
			);
		});

		return (
			<Modal
				show={this.props.show}
				onHide={this.props.handleClose}
				animation={false}
				className={"d-flex justify-content-center"}
			>
				<Modal.Header>
					<Modal.Title className="text-dark">
						{" "}
						{languageHandler("cart", "title", this.props.language)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						{this.props.basket.length === 0
							? languageHandler("cart", "instruction", this.props.language)
							: basket}
					</div>
				</Modal.Body>

				<Modal.Footer>
					<h2 className="text-dark">
						{languageHandler("cart", "subtotal", this.props.language)} :
						&#78;&#84;&#36;{this.props.totalPrice}
					</h2>
					<Button className="btn-warning" onClick={this.props.hide}>
						{languageHandler(
							"cart",
							"button-text-continue",
							this.props.language
						)}
					</Button>
					<Button
						disabled={this.props.basket.length === 0}
						onClick={() => this.handleProceedToCheckout()}
						className="btn-success"
					>
						{languageHandler(
							"cart",
							"button-text-proceed",
							this.props.language
						)}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
// maps state to props from redux
const mapStateToProps = (state) => {
	return {
		products: state.store.products, //this is an object
		keyArr: state.store.keyArr, //this is an array
		basket: state.store.basket, //this is an array
		loading: state.store.loading, // this is a boolean
		totalPrice: state.store.totalPrice,
		language: state.store.language,
	};
};
// maps actions to props from redux
const mapDispatchToProps = (dispatch) => {
	return {
		// increment item qty
		incrementCartItem: (prodKey, basket, itemPrice, totalPrice) =>
			dispatch(
				actions.incrementCartItem(prodKey, basket, itemPrice, totalPrice)
			),
		// decrement item qty
		decrementCartItem: (prodKey, basket, itemPrice, totalPrice) =>
			dispatch(
				actions.decrementCartItem(prodKey, basket, itemPrice, totalPrice)
			),
		// remove item
		removeCartItem: (prodKey, basket, itemPrice, totalPrice) =>
			dispatch(actions.removeCartItem(prodKey, basket, itemPrice, totalPrice)),

		fetchProducts: () => dispatch(actions.fetchProducts()),
		updateTotalPrice: (newTotalPrice) =>
			dispatch(actions.updateTotalPrice(newTotalPrice)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
