import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import authReducer from "./store/reducers/authReducer";
import storeReducer from "./store/reducers/storeReducer";
import adminReducer from "./store/reducers/adminReducer";
// redux persist stuff
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import ordersReducer from "./store/reducers/ordersReducer";
import "./custom.scss";
import Spinner from "./components/Spinner/Spinner";

import { BrowserRouter } from "react-router-dom";

//compose enhancers allows us to access REDUX devTools in the browser
const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ /* window.location.href.includes("localhost")
	? 
	: */ || compose;
//redux-persist config

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  blacklist: ["auth"],
};

//this combines our reducers together so we can have seperate and more focused and specific reducers
const rootReducer = combineReducers({
  auth: authReducer,
  store: storeReducer,
  admin: adminReducer,
  orders: ordersReducer,
});

//create pReducer to pass to createStore function
//persistReducer will wrap root reducer
const pReducer = persistReducer(persistConfig, rootReducer);

//we create the store, using middleware and thunk, which allow us to run async code in redux
export const store = createStore(
  pReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export const persistor = persistStore(store);

//need to wrap root component with PersistGate (delays rendering until persisted state retrieved)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Spinner />} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
