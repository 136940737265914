export const checkStockInStore = (
	basket,
	products,
	totalPrice,
	removeCartItem
) => {
	if (Array.isArray(basket) && basket.length === 0) return null;
	basket.map((basketItem) => {
		const thisProdQty = products[basketItem.key[0]].Qty;
		if (thisProdQty < basketItem.qty) {
			removeCartItem(
				basketItem.key[0],
				basket,
				Math.ceil(
					products[basketItem.key[0]].Price *
						((100 - products[basketItem.key[0]].SalesPercentage) / 100)
				),
				totalPrice
			);
		} else return null;
		return null;
	});
};

// basket = [{product:"randomKey1", qty:4}, {product:"randomKey2", qty:2},]

export const validatePrice = (basket = [], promo, products) => {
	if (basket.length === 0) return 0;
	// check items in basket against products and get price, salesPercentage
	const calculatedPriceArray = basket.map((item) => {
		let returnObject = {
			product: item.key[0],
			qty: item.qty,
			calculatedPrice: 0,
		};

		const priceCalc =
			item.qty *
			(products[item.key[0]].Price *
				((100 - products[item.key[0]].SalesPercentage) / 100));
		returnObject.calculatedPrice = parseInt(priceCalc);

		return returnObject;
	});
	let calculatedTotalPrice;
	if (Array.isArray(calculatedPriceArray)) {
		calculatedTotalPrice = calculatedPriceArray.reduce(
			(previousVal, currentVal) => {
				if (typeof previousVal === "object") {
					return previousVal.calculatedPrice + currentVal.calculatedPrice;
				} else return previousVal + currentVal.calculatedPrice;
			}
		);
	}
	// total items price in basket

	if (typeof calculatedTotalPrice === "object")
		return calculatedTotalPrice.calculatedPrice;
	else return calculatedTotalPrice;
};
