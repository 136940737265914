/*======================================================================================================================================== 
																ORDER CARD
creates order card component
accordion style (from bootstrap)
has various order details from database with color change on order status
========================================================================================================================================*/
import React from "react";
import { Accordion, Card, Container, Row, Col } from "react-bootstrap";
import classes from "./SingleOrderCard.module.css";
import OrderSummaryCard from "../../UI/Cards/OrderSummaryCard/OrderSummaryCard";

const SingleOrderCard = (props) => {
	let status = props.status;
	switch (props.status) {
		case "newOrder":
			status = "New Order";
			break;
		case "awaitingPayment":
			status = "Awaiting Payment";
			break;
		case "followUp":
			status = "Follow Up";
			break;
		case "completed":
			status = "Completed";
			break;
		case "inTransit":
			status = "In Transit";
			break;
		case "cancelled":
			status = "Cancelled";
			break;
		default:
			status = props.status;
	}

	return (
		<Card
			className={classes.SingleOrderCard}
			style={{
				backgroundColor:
					props.status === "cancelled"
						? "#f8d7da" //red
						: props.status === "completed"
						? "#f7f7f7" //faded grey
						: props.status === "newOrder"
						? "#5cb85c" //green
						: props.status === "inTransit"
						? "#5bc0de" //blue
						: props.status === "awaitingPayment"
						? "#FF8C00" //orange
						: props.status === "followUp"
						? "fed8b1" //pale orange
						: "#FFF3CD", //yellow
			}}
		>
			<Accordion.Toggle
				as={Card.Header}
				variant="link"
				eventKey={props.orderKey}
			>
				<Container>
					<Row className={"text-center pb-3"}>
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
							<strong>{props.orderDate}</strong>
						</Col>
					</Row>
					<Row>
						<Col xs={6} sm={6} md={4} lg={4} xl={4}>
							{status}
						</Col>
						<Col xs={6} sm={6} md={8} lg={4} xl={4}>
							{props.orderReference}
						</Col>
						<Col xs={12} sm={12} md={8} lg={4} xl={4}>
							{props.contactInfo.Name}
						</Col>
					</Row>
				</Container>
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={props.orderKey}>
				<Card.Body>
					<div>
						<OrderSummaryCard
							basket={props.basket}
							contactInfo={props.contactInfo}
							order={props.order}
							orderKey={props.orderKey}
							orderDate={props.orderDate}
							totalPrice={props.totalPrice}
							shippingOption={props.shippingOption}
							paymentMethod={props.paymentMethod}
							{...props}
							orderReference={props.orderReference}
							currentFilter={props.currentFilter}
							promoCode={props.promoCode}
							discount={props.discount}
							orderMessage={props.orderMessage}
						/>
					</div>
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	);
};

export default SingleOrderCard;
