/*======================================================================================================================================== 
                                                    ADD PRODUCT CARD
Modal to upload new product to store. Includes image input as well as all required fields for a new product
Includes validation on the product Id. If it matches any product Id in the store then it will not be allowed to be uploaded nor added
to the store.
========================================================================================================================================*/

import React, { Component } from "react";
import { app } from "../../firebase/firebase";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import Compress from "compress.js";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import classes from "./AddProductCard.module.css";
import { Button, Modal, Form, InputGroup, FormControl } from "react-bootstrap";

class AddProductCard extends Component {
  state = {
    Products: {
      Id: "",
      Image: "",
      Price: "",
      Qty: "",
      SalesPercentage: "",
      Title: ["", ""],
      Type: "",
      Description: ["", ""],
    },
    url: "",
    progress: 0,
    image: null,
    isValid: false,
    /* message: "", */
    filters: ["Sausage", "Chicken", "Biltong", "Lamb", "Beef", "Pork", "Other"],
  };

  // handles input of image - resizes image based on the config in the compress method and sets the image state to that of the resized inputted image
  handleChange = async (e) => {
    const compress = new Compress();
    if (e.target.files[0]) {
      const resizedImage = await compress.compress([e.target.files[0]], {
        size: 2, // the max size in MB, defaults to 2MB
        quality: 1, // the quality of the image, max is 1,
        maxWidth: 300, // the max width of the output image, defaults to 1920px
        maxHeight: 300, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
      });
      const img = resizedImage[0];
      const base64str = img.data;
      const imgExt = img.ext;
      const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
      this.setState({ image: resizedFiile });
    }
  };

  // handles upload of image to firebase storage. This image is referenced in the product data to be input in the source tag of the image in the product card
  // includes progress handling
  // NOTE: the product Id must be set before the image is uploaded. the validation handles that
  handleUpload = (e) => {
    e.preventDefault();
    const storage = getStorage(app);
    const imageRef = storageRef(storage, `images/${this.state.Products.Id}`);
    const uploadTask = uploadBytesResumable(imageRef, this.state.image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress: progress });
      },
      (error) => {
        console.error("ERROR UPLOADING IMAGE", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          const productObj = { ...this.state.Products };
          productObj.Image = url;
          return this.setState({ Products: productObj, url: url });
        });
        /* storage
					.ref("images")
					.child(this.state.Products.Id)
					.getDownloadURL()
					.then((url) => {
						
					}); */
      }
    );
    /* const uploadTask = storage
			.ref(`images/${this.state.Products.Id}`)
			.put(this.state.image);
		 */
  };

  // handles adding a new product to the firebase database. The image reference is that of the uploaded image
  // need acces to props.history to push the store page back on to the stack of pages
  addProductHandler = (event) => {
    event.preventDefault();
    this.props.addProduct(this.state.Products);
    this.props.history.push("/");
    this.props.history.push("/auth-store");
    this.props.handleClose();
  };
  editProductHandler = (event) => {
    event.preventDefault();
    this.props.editProduct(this.state.Products, this.props.objKey);
    this.props.history.push("/");
    this.props.history.push("/auth-store");
    this.props.handleClose();
  };

  deleteProductHandler = (event) => {
    event.preventDefault();
    this.props.deleteProduct(this.props.objKey);
    this.props.fetchProducts();
    this.props.history.push("/");
    this.props.history.push("/auth-store");
    this.props.handleClose();
  };

  // handles the change of the Id input. Includes validation against current store product Ids
  onInputChangeHandler = (event) => {
    //const value = event.target.value;
    const inputForm = { ...this.state.Products };
    const stateValue = event.target.name;
    if (event.target.name === "chineseTitle") {
      inputForm["Title"][1] = event.target.value;
      return this.setState({ Products: inputForm });
    }
    if (event.target.name === "chineseDescription") {
      inputForm["Description"][1] = event.target.value;
      return this.setState({ Products: inputForm });
    }
    if (event.target.name === "Title" || event.target.name === "Description") {
      inputForm[stateValue][0] = event.target.value;
      return this.setState({ Products: inputForm });
    }

    inputForm[stateValue] = event.target.value;
    this.setState({ Products: inputForm });
  };

  onNumberChangeHandler = (event) => {
    const inputForm = { ...this.state.Products };
    const stateValue = event.target.name;
    inputForm[stateValue] = parseInt(event.target.value, 10);
    this.setState({ Products: inputForm });
  };

  onIdChangeHandler = (event) => {
    const inputForm = { ...this.state.Products };
    const storeProducts = this.props.storeProducts;
    const storeProdKeyArr = Object.keys(storeProducts);

    const storeProductIdArr = [];

    storeProdKeyArr.map((keys) => {
      return storeProductIdArr.push(storeProducts[keys].Id);
    });

    if (storeProductIdArr.includes(event.target.value)) {
      this.setState({ isValid: false });
    } else this.setState({ isValid: true });
    inputForm.Id = event.target.value;
    this.setState({ Products: inputForm });
  };

  componentDidMount() {
    if (this.props.objKey) {
      if (!Array.isArray(this.props.storeProducts[this.props.objKey].Title)) {
        let newProduct = {
          Id: this.props.storeProducts[this.props.objKey].Id,
          Image: this.props.storeProducts[this.props.objKey].Image,
          Price: this.props.storeProducts[this.props.objKey].Price,
          Qty: this.props.storeProducts[this.props.objKey].Qty,
          SalesPercentage:
            this.props.storeProducts[this.props.objKey].SalesPercentage,
          Title: [this.props.storeProducts[this.props.objKey].Title, ""],
          Type: this.props.storeProducts[this.props.objKey].Type,
          Description: [
            this.props.storeProducts[this.props.objKey].Description,
            "",
          ],
        };

        this.setState({
          Products: newProduct,
        });
      } else
        this.setState({
          Products: this.props.storeProducts[this.props.objKey],
        });
    } else {
      return null;
    }
  }

  render() {
    let addOrEdit = (
      <Button
        disabled={!this.state.isValid}
        variant="primary"
        onClick={(event) => {
          this.addProductHandler(event);
        }}
      >
        Add Product
      </Button>
    );
    if (this.props.objKey !== null) {
      addOrEdit = (
        <div>
          <Button
            variant="primary"
            onClick={(event) => {
              this.editProductHandler(event);
            }}
          >
            Edit Product
          </Button>
          <Button
            variant="danger"
            onClick={(event) => {
              this.deleteProductHandler(event);
            }}
          >
            Delete Product
          </Button>
        </div>
      );
    }
    return (
      <Modal show={true} onHide={this.props.handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div>
              <progress value={this.state.progress} max="100" />
              <br />
              <Form.File
                onChange={(e) => {
                  this.handleChange(e);
                }}
              />
              <br />
              <Button
                disabled={!this.state.isValid}
                onClick={(e) => this.handleUpload(e)}
              >
                Upload
              </Button>
              <br />
              {this.state.url}
              <br />
              <img
                src={this.state.url || "http://via.placeholder.com/300"}
                alt="firebase"
              />

              <InputGroup className="mb-3 mt-1">
                <InputGroup.Text className={classes.Label} id="id">
                  Id
                </InputGroup.Text>
                <FormControl
                  placeholder="ID"
                  aria-label="ID"
                  aria-describedby="id"
                  type="text"
                  name="Id"
                  value={this.state.Products.Id}
                  onChange={this.onIdChangeHandler}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text className={classes.Label} id="type">
                  Prod. type
                </InputGroup.Text>
                <FormControl
                  type="select"
                  as="select"
                  name="Type"
                  placeholder="Product Type"
                  value={this.state.Products.Type}
                  onChange={this.onInputChangeHandler}
                  aria-label="Product type"
                  aria-describedby="type"
                >
                  {this.props.filters.map((filter) => {
                    return (
                      <option
                        key={filter.filterKey}
                        value={filter.value[0]}
                        name={filter.value[0]}
                      >
                        {filter.value[0]}
                      </option>
                    );
                  })}
                </FormControl>
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text className={classes.Label} id="price">
                  Price
                </InputGroup.Text>
                <FormControl
                  type="number"
                  name="Price"
                  placeholder={"Price"}
                  value={this.state.Products.Price}
                  onChange={this.onNumberChangeHandler}
                  aria-label="Price"
                  aria-describedby="price"
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text className={classes.Label} id="qty">
                  Qty
                </InputGroup.Text>
                <FormControl
                  type="number"
                  name="Qty"
                  placeholder="Quantity"
                  value={this.state.Products.Qty}
                  onChange={this.onNumberChangeHandler}
                  aria-label="Quantity"
                  aria-describedby="qty"
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text className={classes.Label} id="englishTitle">
                  Title
                </InputGroup.Text>
                <FormControl
                  type="text"
                  name="Title"
                  placeholder="Title"
                  value={this.state.Products.Title[0]}
                  onChange={this.onInputChangeHandler}
                  aria-label="English title"
                  aria-describedby="englishTitle"
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text className={classes.Label} id="chineseTitle">
                  中文 Title
                </InputGroup.Text>
                <FormControl
                  type="text"
                  name="chineseTitle"
                  placeholder="Chinese Title"
                  value={this.state.Products.Title[1]}
                  onChange={this.onInputChangeHandler}
                  aria-label="Chinese Title"
                  aria-describedby="chineseTitle"
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text className={classes.Label} id="salesPercentage">
                  Sales %
                </InputGroup.Text>
                <FormControl
                  type="number"
                  name="SalesPercentage"
                  placeholder="Sales Percentage"
                  value={this.state.Products.SalesPercentage}
                  onChange={this.onNumberChangeHandler}
                  aria-label="Sales Percentage"
                  aria-describedby="salesPercentage"
                />
              </InputGroup>

              <InputGroup>
                <InputGroup.Text className={classes.Label} id="description">
                  Desc.
                </InputGroup.Text>
                <FormControl
                  type="text"
                  name="Description"
                  placeholder="English Description"
                  value={this.state.Products.Description[0]}
                  onChange={this.onInputChangeHandler}
                  as="textarea"
                  aria-label="English Description"
                  aria-describedby="description"
                />
              </InputGroup>

              <InputGroup>
                <InputGroup.Text
                  className={classes.Label}
                  id="chineseDescription"
                >
                  中文 Desc.
                </InputGroup.Text>
                <FormControl
                  type="text"
                  name="chineseDescription"
                  placeholder="Chinese Description"
                  value={this.state.Products.Description[1]}
                  onChange={this.onInputChangeHandler}
                  as="textarea"
                  aria-label="Chinese Description"
                  aria-describedby="chineseDescription"
                />
              </InputGroup>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
          {addOrEdit}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.admin.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: (product) => dispatch(actions.addNewProduct(product)),
    editProduct: (product, objKey) =>
      dispatch(actions.editProduct(product, objKey)),
    deleteProduct: (objKey) => dispatch(actions.deleteProduct(objKey)),
    fetchProducts: () => dispatch(actions.fetchProducts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProductCard);
