/*======================================================================================================================================== 
														AUTH REDUCER
reducer for updating the auth state 														
========================================================================================================================================*/
import * as actionTypes from "../actions/action-types";
import { updateObject } from "../utility";

const initialState = {
	//token: null, // the token received back from firebase. This is used to guard routes.
	//userId: null, //stores the id of the user
	error: null, //catched the error from an unsuccessful login attempt
	loading: false, //used to display a loading spinner
	user: null,
	isSignedIn: false,
	logoutError: null,
	logoutLoading: false,
	listeningLoading: false,
	listeningError: null,
	ListeningDetachError: null,
	listeningDetachLoading: false,
};

//=======================================================================================================================================================
// START AUTHENTICATION
//=======================================================================================================================================================

//authStart allows us to display a loading Spinner from initializing a login until we capture either a successful or unsuccessful login
const authStart = (state, action) => {
	return updateObject(state, { error: null, loading: true });
};

// updates the state after a successful login
const authSuccess = (state, action) => {
	return updateObject(state, {
		/* token: action.idToken,
		userId: action.userId, */
		user: action.user,
		isSignedIn: true,
		error: null,
		loading: false,
	});
};

// updates the state after a failed auth
const authFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};

//=======================================================================================================================================================
// LOGGING OUT
//=======================================================================================================================================================

const logoutStart = (state, action) => {
	return updateObject(state, { error: null, logoutLoading: true });
};

// updates the state after a successful logout
const logoutSuccess = (state, action) => {
	return updateObject(state, {
		user: null,
		isSignedIn: false,
		error: null,
		logoutLoading: false,
	});
};

// updates the state after a failed logout
const logoutFail = (state, action) => {
	return updateObject(state, {
		elogoutEror: action.error,
		logoutLoading: false,
	});
};

//=======================================================================================================================================================
// LISTENING
//=======================================================================================================================================================
// initiate auth listener from firebase
const listeningStart = (state, action) => {
	return updateObject(state, {
		error: null,
		listening: action.listening,
	});
};

// if listener attaches successfully it will return user and stop the loading state
const listeningSuccess = (state, action) => {
	return updateObject(state, {
		/* token: action.idToken,
		userId: action.userId, */
		user: action.user,
		isSignedIn: true,
		listeningError: null,
		listeningLoading: false,
	});
};

// updates the state after a failed auth listener attachment
const listeningFail = (state, action) => {
	return updateObject(state, {
		listeningError: action.error,
		listeningLoading: false,
	});
};

//=======================================================================================================================================================
// DETACH
//=======================================================================================================================================================
// detaches the auth listener for use on component unmount
const detachListeningSuccess = (state, action) => {
	return updateObject(state, {
		ListeningDetachError: null,
		listeningDetachLoading: false,
	});
};

//=======================================================================================================================================================

//=======================================================================================================================================================

//Switch statement for the reducer. as usual
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_START:
			return authStart(state, action);
		case actionTypes.AUTH_SUCCESS:
			return authSuccess(state, action);
		case actionTypes.AUTH_FAIL:
			return authFail(state, action);
		case actionTypes.LOGOUT_START:
			return logoutStart(state, action);
		case actionTypes.LOGOUT_SUCCESS:
			return logoutSuccess(state, action);
		case actionTypes.LOGOUT_FAIL:
			return logoutFail(state, action);
		case actionTypes.LISTENING_START:
			return listeningStart(state, action);
		case actionTypes.LISTENING_SUCCESS:
			return listeningSuccess(state, action);
		case actionTypes.LISTENING_FAIL:
			return listeningFail(state, action);
		case actionTypes.DETACH_LISTENING_SUCCESS:
			return detachListeningSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
