/*======================================================================================================================================== 
																JUMBOTRON
creates jumbotron component (bootstrap)																
========================================================================================================================================*/
import React from "react";
import { Jumbotron, Button } from "react-bootstrap";
import classes from "./Jumbotron.module.css";

import { languageHandler } from "../../util/language/functions";
import LanguageToggler from "../LanguageToggler/LanguageToggler";

const jumbotron = (props) => {
	const enterClickHandler = () => {
		props.history.push("/store");
	};
	return (
		<Jumbotron className={classes.Jumbotron}>
			<div>
				<h1>{props.heading}</h1>
				<p>{props.text}</p>
				<p>
					<Button variant="primary" onClick={enterClickHandler}>
						{languageHandler("landing", "button-text", props.language)}
					</Button>
				</p>
			</div>
			<LanguageToggler />
		</Jumbotron>
	);
};

/* const mapStateToProps = (state) => {
	return {
		language: state.store.language,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleLanguage: (language) => dispatch(actions.toggleLanguage(language)),
	};
}; */

export default jumbotron;
