import React, { Component } from "react";
import {
	Container,
	Button,
	Row,
	Col,
	Form,
	Card,
	Accordion,
	InputGroup,
	FormControl,
} from "react-bootstrap";
import { connect } from "react-redux";
import Navigation from "../../components/Navigation/Navigation";
import Footer from "../../components/Footer/Footer";
import { v4 as uuidv4 } from "uuid";
import { Trash } from "react-bootstrap-icons";
import * as actions from "../../store/actions/index";
import classes from "./AdminConsole.module.css";
import { Redirect } from "react-router-dom";

export class AdminConsole extends Component {
	state = {
		promo: { batchQty: "", discount: "", id: "" },
		promoCodes: {},
		newFilter: ["", ""],
	};

	componentDidMount = () => {
		this.props.fetchPromoCode();
		this.props.fetchFilters();
		this.setState({ promoCodes: this.props.promoCodes });
	};

	randomKeyGenerator(keyLength = 3) {
		const randomString = Math.random()
			.toString(36)
			.replace(/[^a-z]+/g, "")
			.substr(0, keyLength)
			.toUpperCase();
		const randomNum = Math.floor(Math.random() * 1000 + 100).toString();
		const randomKey = randomString.concat(randomNum);
		return randomKey;
	}
	codeGenerator(qty, discount, id) {
		const codeArray = [...Array(qty).keys()].map((code) => {
			const properties = {
				code: this.randomKeyGenerator(4),
				used: false,
				id: id,
				discount: discount,
			};
			return properties;
		});
		this.props.addPromoCode(codeArray);
	}

	onChangeHandler = (e) => {
		const promo = { ...this.state.promo };
		const stateValue = e.target.name;
		if (e.target.name === "id") {
			promo[stateValue] = e.target.value;
		} else promo[stateValue] = parseInt(e.target.value);
		this.setState({ promo: promo });
	};

	onNewTypeInputHandler = (e) => {
		let value = e.target.value;
		const lang = e.target.name;
		let typeToPass = [...this.state.newFilter];
		if (lang === "0") {
			typeToPass[0] = value;
		} else {
			typeToPass[1] = value;
		}
		this.setState({ newFilter: typeToPass });
	};

	addFilterHandler = (e) => {
		e.preventDefault();
		this.props.addFilter(this.state.newFilter);
		this.setState({ newFilter: ["", ""] });
	};

	style = { backgroundColor: "white" };

	render() {
		let accordions = null;

		if (this.props.promoCodes) {
			accordions = Object.values(this.props.promoCodes).map((batch) => {
				return (
					<Col key={uuidv4()} xs={6} sm={6} md={3} lg={3} xl={3}>
						<Accordion defaultActiveKey="0">
							<Card>
								<Accordion.Toggle as={Card.Header} eventKey="1">
									<strong>{batch[0].id}</strong>
								</Accordion.Toggle>
								<Accordion.Collapse eventKey="1">
									<>
										{batch.map((promoCode) => {
											return (
												<Card.Body
													key={uuidv4()}
													className={
														promoCode.used ? classes.Used : classes.UnUsed
													}
												>
													<InputGroup style={{ width: "100%" }} size="sm">
														<InputGroup.Text style={{ width: "80%" }}>
															{promoCode.code}
														</InputGroup.Text>

														<InputGroup.Append>
															<Button
																disabled={promoCode.used}
																onClick={() =>
																	this.props.usePromoCode(
																		this.props.promoCodes,
																		promoCode.code
																	)
																}
																variant="danger"
															>
																<Trash />
															</Button>
														</InputGroup.Append>
													</InputGroup>
												</Card.Body>
											);
										})}
										<Card.Body className="text-center">
											<Button
												onClick={(promoCodes) =>
													this.props.deletePromoCode(
														this.props.promoCodes,
														batch[0].id
													)
												}
												variant="danger"
											>
												Delete Batch
											</Button>
										</Card.Body>
									</>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>
				);
			});
		}

		let filters =
			this.props.filters &&
			this.props.filters.map((filter) => {
				return (
					<li key={filter.filterKey}>
						<Container fluid>
							<Row className="text-center justify-content-center">
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
									{" "}
									{filter.value[0]}
								</Col>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
									{" "}
									{filter.value[1]}
								</Col>

								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
									<Button
										onClick={() => this.props.deleteFilter(filter.filterKey)}
										variant="danger"
									>
										Delete
									</Button>
								</Col>
							</Row>
						</Container>
					</li>
				);
			});

		return this.props.isSignedIn ? (
			<>
				<Navigation />
				<div className={`container-fluid bg-primary`}>
					<h3 className="text-white">Admin Console</h3>

					<Container fluid className={classes.MainContent}>
						<Row className="justify-content-center pb-3">
							<Col className="text-center">
								<h4>Product Filters</h4>
							</Col>
						</Row>
						<Row className="justify-content-center pb-3">
							<Form onSubmit={this.addFilterHandler}>
								<Form.Row className="justify-content-center pb-3">
									<Col xs={12} sm={12} md={6} lg={6} xl={6}>
										<InputGroup className="mb-3 mt-1">
											<InputGroup.Text className={classes.Label} id="type">
												English Filter
											</InputGroup.Text>
											<FormControl
												placeholder="Product Type"
												aria-label="Product type"
												aria-describedby="type"
												type="text"
												name="0"
												value={this.state.newFilter[0]}
												onChange={this.onNewTypeInputHandler}
											/>
										</InputGroup>
									</Col>
									<Col xs={12} sm={12} md={6} lg={6} xl={6}>
										<InputGroup className="mb-3 mt-1">
											<InputGroup.Text
												className={classes.Label}
												id="chinesetype"
											>
												Chinese Filter
											</InputGroup.Text>
											<FormControl
												placeholder="Chinese product Type"
												aria-label="Product type chinese"
												aria-describedby="chinesetype"
												type="text"
												name="1"
												value={this.state.newFilter[1]}
												onChange={this.onNewTypeInputHandler}
											/>
										</InputGroup>
									</Col>
								</Form.Row>
								<Form.Row className="text-center justify-content-center">
									<Col xs={6} sm={6} md={3} lg={3} xl={3}>
										<Button variant="light" type="submit">
											Add Filter
										</Button>
									</Col>
								</Form.Row>
							</Form>
						</Row>
						<Row className="text-center justify-content-center mb-5">
							<ul className={classes.FilterList}>{filters}</ul>
						</Row>
						<Form>
							<Form.Row className="justify-content-center pb-3">
								<Col xs={6} sm={6} md={2} lg={2} xl={2}>
									<input
										className="form-control"
										placeholder="number of codes"
										type="number"
										name="batchQty"
										value={this.state.promo.batchQty}
										onChange={(e) => this.onChangeHandler(e)}
									></input>
								</Col>
								<Col xs={6} sm={6} md={2} lg={2} xl={2}>
									<input
										className="form-control"
										placeholder="sales percentage"
										type="number"
										name="discount"
										value={this.state.promo.discount}
										onChange={(e) => this.onChangeHandler(e)}
									></input>
								</Col>
								<Col xs={6} sm={6} md={2} lg={2} xl={2}>
									<input
										className="form-control"
										placeholder="id"
										type="string"
										name="id"
										value={this.state.promo.id}
										onChange={(e) => this.onChangeHandler(e)}
									></input>
								</Col>
							</Form.Row>
						</Form>
						<Row className="justify-content-center pb-3">
							<Col xs={6} sm={6} md={2} lg={2} xl={2} className="text-center">
								<Button
									size="lg"
									variant="secondary"
									onClick={(batchQty, discount, id) =>
										this.codeGenerator(
											this.state.promo.batchQty,
											this.state.promo.discount,
											this.state.promo.id
										)
									}
								>
									Generate
								</Button>
							</Col>
						</Row>
						<Row className={"justify-content-center"}>{accordions}</Row>
					</Container>
					<Footer />
				</div>
			</>
		) : (
			/* {React.createElement(Container, {}, React.createElement(Button), {}, 'Generate Codes')} */
			<Redirect to="/login" />
		);
	}
}

const mapStateToProps = (state) => {
	return {
		promoCodes: state.admin.promoCodes,
		filters: state.admin.filters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addPromoCode: (codes) => dispatch(actions.addPromoCode(codes)),
		fetchPromoCode: () => dispatch(actions.fetchPromoCode()),
		usePromoCode: (promoCodes, promoCode) =>
			dispatch(actions.editPromoCode(promoCodes, promoCode)),
		deletePromoCode: (promoCodes, batchId) =>
			dispatch(actions.deletePromoCode(promoCodes, batchId)),
		addFilter: (filter) => dispatch(actions.addFilter(filter)),
		fetchFilters: () => dispatch(actions.fetchFilters()),
		deleteFilter: (key) => dispatch(actions.deleteFilter(key)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminConsole);
