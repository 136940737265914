import React, { Component } from "react";
import Navigation from "../../components/Navigation/Navigation";
import ContactForm from "../ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import Spinner from "../../components/Spinner/Spinner";

import {
  Card,
  Button,
  Row,
  Col,
  Container,
  ButtonGroup,
  InputGroup,
  FormControl,
  Alert,
} from "react-bootstrap";

import { BagPlus, BagDash, Trash, Truck, Gift } from "react-bootstrap-icons";
import * as actions from "../../store/actions/index";
import { Link, Redirect } from "react-router-dom";
import classes from "./Checkout.module.css";
import moment from "moment";
import { sendEmailHandler } from "../../util/mail/mail";
import EmailTemplates from "../../util/mail/emailTemplates";
import { languageHandler } from "../../util/language/functions";
import { checkStockInStore, validatePrice } from "../../util/util";

class Checkout extends Component {
  state = {
    disabled: false,
    orderSubmitted: false,
    isCOD: false,
    shippingOption: "RegularAM",
    promoCode: "",
    discount: 0,
    disableInput: false,
    codeErrorMessage: null,
    orderFailed: false,
    failedOrderSent: false,
  };
  // we use these functions to trigger a rerender of the component after adjusting the quantity.
  componentDidMount() {
    this.props.fetchProducts();
    this.props.fetchPromoCode();
    checkStockInStore(
      this.props.basket,
      this.props.products,
      this.props.totalPrice,
      this.props.removeCartItem
    );
    const updatedPrice = validatePrice(
      this.props.basket,
      null,
      this.props.products
    );
    this.props.updateTotalPrice(updatedPrice);
    // run updateTotalPrice with value from previous line
  }

  // inc, dec and remove all fire off functions in redux
  // increments the qty of the item in the basket by 1
  onIncrementCartItem = (prodKey, basket, itemPrice, totalPrice) => {
    this.props.incrementCartItem(prodKey, basket, itemPrice, totalPrice);
    const updatedPrice = validatePrice(
      this.props.basket,
      null,
      this.props.products
    );
    this.props.updateTotalPrice(updatedPrice);
  };
  // decrements the qty of the item in the basket by 1
  onDecrementCartItem = (prodKey, basket, itemPrice, totalPrice) => {
    this.props.decrementCartItem(prodKey, basket, itemPrice, totalPrice);
    const updatedPrice = validatePrice(
      this.props.basket,
      null,
      this.props.products
    );
    this.props.updateTotalPrice(updatedPrice);
  };
  // removes item from the basket
  onRemoveCartItem = (prodKey, basket, itemPrice, totalPrice) => {
    //this.props.decrementCartItem(prodKey, basket, itemPrice, totalPrice);
    this.props.removeCartItem(prodKey, basket, itemPrice, totalPrice);
    const updatedPrice = validatePrice(
      this.props.basket,
      null,
      this.props.products
    );
    this.props.updateTotalPrice(updatedPrice);
  };

  checkStockThenSubmit = (event, contactDetails) => {
    const initialBasketLength = this.props.basket.length;
    checkStockInStore(
      this.props.basket,
      this.props.products,
      +this.props.totalPrice,
      this.props.removeCartItem
    );
    const newBasketLength = this.props.basket.length;
    if (initialBasketLength === newBasketLength) {
      const updatedPrice = validatePrice(
        this.props.basket,
        null,
        this.props.products
      );
      this.props.updateTotalPrice(updatedPrice);
      this.handleSubmitOrder(event, contactDetails);
    } else if (initialBasketLength > newBasketLength) {
      this.props.history.push("/store");
    }
  };

  handleSubmitOrder = (event, contactDetails) => {
    //basket = {...this.props.basket};
    //contactDetails = {...contactDetails};
    event.preventDefault();
    const referenceNum = this.randomKeyGenerator();
    const order = {
      basket: null,
      contactInfo: null,
      status: "newOrder",
      totalPrice: this.props.totalPrice,
      orderDate: null,
      paymentMethod: contactDetails.PaymentMethod,
      shippingOption: "",
      orderReference: referenceNum,
      discount: 0,
      promoCode: "",
    };

    order.orderDate = moment().format("LLLL");

    order.basket = this.props.basket.map((item) => ({
      product: this.props.products[item.key[0]],
      qty: item.qty,
    }));
    order.contactInfo = { ...contactDetails };
    order.shippingOption = this.state.shippingOption;
    order.promoCode = this.state.discount === 0 ? "" : this.state.promoCode;
    order.discount = this.state.discount;
    this.setState({ order: order, contactDetails: contactDetails });
    this.props.submitOrder(order, this.props.products, this.props.basket);
    /* if (
			this.props.orderPostError &&
			this.props.orderPostError.order === "failed"
		) {
			this.setState({ orderFailed: true, orderSubmitted: false });
		}
		if (!this.props.orderPostError) {
			this.setState({ orderSubmitted: true });
		} */
    this.setState({ orderReference: referenceNum });

    const emailLogoImage = ReactDOMServer.renderToStaticMarkup(
      <img
        alt="The Braai Guy"
        src="https://thebraaiguy.com/static/media/RectangleLogo.2b9b054a.jpg"
      />
    );

    let templateParams = {
      logo: emailLogoImage,
      from_name: "The Braai Guy",
      to_name: order.contactInfo.Name,
      message: ReactDOMServer.renderToStaticMarkup(
        <EmailTemplates
          to_name={order.contactInfo.Name}
          status={"newOrder"}
          orderNumber={referenceNum}
          basket={order.basket}
          shippingOption={this.state.shippingOption}
          totalPrice={this.props.totalPrice}
          promoCode={
            this.state.discount === 0 ? "No Promo" : this.state.promoCode
          }
          discount={this.state.discount}
          contactInfo={order.contactInfo}
        />
      ),
      reply_to: "derik@thebraaiguy.com",
      to_email: order.contactInfo.Email,
      order_number: referenceNum,
    };

    if (this.state.discount !== 0) {
      this.props.usePromoCode(this.props.promoCodes, this.state.promoCode);
    }

    sendEmailHandler(templateParams, "template_op0advk");
    contactDetails.PaymentMethod === "Cash on Delivery"
      ? this.setState({ isCOD: true })
      : this.setState({ isCOD: false });
  };

  onFailedOrderEmailSend = (e) => {
    e.preventDefault();
    let templateParams = {
      from_name: "The Braai Guy",
      to_name: "Derik",
      message: ReactDOMServer.renderToStaticMarkup(
        <EmailTemplates
          to_name={this.state.order.contactInfo.Name}
          status={"failedOrder"}
          orderNumber={"failed"}
          basket={this.state.order.basket}
          shippingOption={this.state.shippingOption}
          totalPrice={this.props.totalPrice}
          promoCode={
            this.state.discount === 0 ? "No Promo" : this.state.promoCode
          }
          discount={this.state.discount}
          contactInfo={this.state.order.contactInfo}
          order={this.state.order}
        />
      ),
      reply_to: this.state.order.contactInfo.Email,
      to_email: "alex@rampant.digital", // replace with derik@thebraaiguy.com
      order_number: "failed",
    };
    sendEmailHandler(templateParams, "template_op0advk");
    this.setState({ failedOrderSent: true });
  };

  onShippingChangeHandler = (event) => {
    this.setState({ shippingOption: event.target.value });
  };

  onPromoCodeChangeHandler = (event) => {
    this.setState({ promoCode: event.target.value });
  };

  checkPromoCode = () => {
    this.props.promoCodes &&
      Object.values(this.props.promoCodes).map((batch) => {
        return batch.map((codeArrayItem) => {
          if (
            codeArrayItem.code === this.state.promoCode &&
            !codeArrayItem.used
          ) {
            return this.setState({
              discount: codeArrayItem.discount,
              disableInput: true,
              codeErrorMessage: null,
            });
          } else if (
            codeArrayItem.code === this.state.promoCode &&
            codeArrayItem.used
          ) {
            return this.setState({ codeErrorMessage: "invalid code" });
          } else return null;
        });
      });
  };

  randomKeyGenerator = (keyLength = 3) => {
    const randomString = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, keyLength)
      .toUpperCase();
    const randomNum = Math.floor(Math.random() * 1000 + 100).toString();
    const randomKey = randomString.concat(randomNum);
    return randomKey;
  };

  render() {
    let basket = this.props.basket.map((item) => {
      let stockCheck = +this.props.products[item.key[0]].Qty === item.qty;

      return (
        <Card key={item.key} className="container-fluid p-1">
          <Row>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
              <img
                className="img-fluid ml-2"
                src={this.props.products[item.key[0]].Image}
                alt="Product"
              />
            </Col>
            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
              <Container fluid>
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    {this.props.products[item.key[0]].Title}
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className={"text-center"}
                  >
                    <ButtonGroup>
                      <Button
                        onClick={(prodKey, basket, itemPrice, totalPrice) =>
                          this.onRemoveCartItem(
                            item.key[0],
                            this.props.basket,
                            Math.ceil(
                              this.props.products[item.key[0]].Price *
                                ((100 -
                                  this.props.products[item.key[0]]
                                    .SalesPercentage) /
                                  100)
                            ),
                            this.props.totalPrice
                          )
                        }
                        className="btn-danger cart-btn-cart"
                      >
                        <Trash />
                      </Button>

                      <Button
                        onClick={(prodKey, basket, itemPrice, totalPrice) =>
                          this.onDecrementCartItem(
                            item.key[0],
                            this.props.basket,
                            Math.ceil(
                              this.props.products[item.key[0]].Price *
                                ((100 -
                                  this.props.products[item.key[0]]
                                    .SalesPercentage) /
                                  100)
                            ),
                            this.props.totalPrice
                          )
                        }
                        className="btn-warning cart-btn-cart text-white"
                      >
                        <BagDash />
                      </Button>
                      <span className={"qty-span"}>{item.qty}</span>

                      <Button
                        disabled={stockCheck}
                        onClick={(prodKey, basket, itemPrice, totalPrice) =>
                          this.onIncrementCartItem(
                            item.key[0],
                            this.props.basket,
                            Math.ceil(
                              this.props.products[item.key[0]].Price *
                                ((100 -
                                  this.props.products[item.key[0]]
                                    .SalesPercentage) /
                                  100)
                            ),
                            this.props.totalPrice
                          )
                        }
                        className="btn-success cart-btn-cart"
                      >
                        <BagPlus className={"align-middle"} />
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    &#78;&#84;&#36;
                    {Math.ceil(
                      (this.props.products[item.key[0]].Price *
                        (100 -
                          this.props.products[item.key[0]].SalesPercentage)) /
                        100
                    )}
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className={"text-center"}
                  >
                    {/* Total Price:  */}&#78;&#84;&#36;
                    {Math.ceil(
                      (this.props.products[item.key[0]].Price *
                        (100 -
                          this.props.products[item.key[0]].SalesPercentage)) /
                        100
                    ) * item.qty}
                  </Col>
                  {stockCheck && (
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="text-primary cart-btn">
                        {" "}
                        *
                        {languageHandler(
                          "checkout",
                          "form-checkout-max-stock",
                          this.props.language
                        )}{" "}
                      </div>
                    </Col>
                  )}
                </Row>
              </Container>
            </Col>
          </Row>
        </Card>
      );
    });

    let shippingCard = null;

    let shippingPrice = 0;

    if (this.state.shippingOption === "PickUp") {
      shippingPrice = 0;
    } else if (this.state.shippingOption === "RegularAM") {
      shippingPrice = 160;
    } else if (this.state.shippingOption === "RegularPM") {
      shippingPrice = 160;
    }

    if (this.props.basket.length > 0) {
      shippingCard = (
        <Card className="container-fluid p-1">
          <Row>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} className={"text-center"}>
              <h5 className={"ml-3"}>
                <Truck className={"text-dark"} />
              </h5>
            </Col>
            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
              <Container fluid>
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <select
                      className="form-control"
                      type="select"
                      name="Shipping option"
                      placeholder="Select your shipping option"
                      value={this.state.ShippingOption}
                      onChange={this.onShippingChangeHandler}
                    >
                      {" "}
                      <option disabled value="">
                        {languageHandler(
                          "checkout",
                          "shipping-instruction",
                          this.props.language
                        )}
                      </option>
                      <option value="RegularAM">
                        {languageHandler(
                          "checkout",
                          "delivery-am",
                          this.props.language
                        )}
                      </option>
                      <option value="RegularPM">
                        {languageHandler(
                          "checkout",
                          "delivery-pm",
                          this.props.language
                        )}
                      </option>
                      <option value="PickUp">
                        {languageHandler(
                          "checkout",
                          "delivery-pickup",
                          this.props.language
                        )}
                      </option>
                    </select>
                  </Col>

                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className={"text-center"}
                  >
                    {" "}
                    &#78;&#84;&#36; {shippingPrice}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Card>
      );
    }

    let promoCard = null;

    if (this.props.basket.length > 0) {
      promoCard = (
        <Card className="container-fluid p-1">
          <Row>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} className={"text-center"}>
              <h5 className={"ml-3"}>
                <Gift className={"text-dark"} />
              </h5>
            </Col>
            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
              <Container fluid>
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <InputGroup className="mb-3">
                      <FormControl
                        name="promoCode"
                        placeholder={languageHandler(
                          "checkout",
                          "promo-code",
                          this.props.language
                        )}
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={this.state.promoCode}
                        onChange={(e) => this.onPromoCodeChangeHandler(e)}
                        readOnly={this.state.disableInput}
                      />
                      <InputGroup.Append>
                        <Button
                          onClick={this.checkPromoCode}
                          variant="outline-primary"
                        >
                          {languageHandler(
                            "checkout",
                            "button-enter",
                            this.props.language
                          )}
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>

                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className={"text-center"}
                  >
                    {this.state.discount === 0 ? null : (
                      <span>
                        - &#78;&#84;&#36;
                        {Math.floor(
                          (this.props.totalPrice / 100) * this.state.discount
                        )}
                      </span>
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row className="justify-content-start">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="text-center"
            >
              {this.state.codeErrorMessage ? (
                <Alert variant={"danger"}>{this.state.codeErrorMessage}</Alert>
              ) : null}
            </Col>
          </Row>
        </Card>
      );
    }

    let mainContent = (
      <>
        <div>
          <h2 className="text-white">
            {languageHandler("checkout", "title", this.props.language)}
          </h2>
        </div>
        <div className={"container checkout-container"}>
          {basket}
          {promoCard}
          {shippingCard}

          {this.props.basket && this.props.basket.length !== 0 ? (
            <div className={`${classes.totalPrice}`}>
              {languageHandler("checkout", "total-price", this.props.language)}:
              &#78;&#84;&#36;
              {Math.ceil(
                this.props.totalPrice * ((100 - this.state.discount) / 100)
              ) + shippingPrice}
            </div>
          ) : (
            <Redirect to="/store" />
          )}
          {this.state.shippingOption === "PickUp" ? (
            <>
              <div className={`${classes.Pickup} `}>
                <p>
                  {languageHandler(
                    "checkout",
                    "pick-up-title",
                    this.props.language
                  )}
                </p>
                <div className={classes.address}>
                  <p>
                    <strong>
                      {languageHandler(
                        "email",
                        "message-pickup-address-change",
                        this.props.language
                      )}
                    </strong>
                  </p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    title="Link to The Braai Guy pickup location"
                    href="https://maps.app.goo.gl/WJLuHu7EvvhpikXa7"
                  >
                    {languageHandler(
                      "email",
                      "message-pickup-link-text",
                      this.props.language
                    )}
                  </a>
                  {/*
									//* service request 2022-04-25 addition of maps link for new address
									<p>
										{languageHandler(
											"address",
											"address-line-1",
											this.props.language
										)}{" "}
									</p>
									<p>
										{languageHandler(
											"address",
											"address-line-2",
											this.props.language
										)}
									</p>
									<p>
										{" "}
										{languageHandler(
											"address",
											"address-line-3",
											this.props.language
										)}
									</p>
									<p>973</p> */}
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className={"container"}>
          <ContactForm
            shippingOption={this.state.shippingOption}
            handleSubmitOrder={(event, contactDetails) => {
              this.checkStockThenSubmit(event, contactDetails);
            }}
          />
        </div>
      </>
    );

    if (this.props.orderPosting) {
      mainContent = (
        <>
          <Container className="text-white text-center">
            <h3>Your order is posting</h3>
            <h4>Please do not refresh this page during this process. </h4>
            <Spinner></Spinner>
          </Container>
        </>
      );
    }

    if (this.props.orderPosted) {
      mainContent = (
        <div className="container p-5 m-auto">
          <div className="card text-center p-5">
            {this.state.isCOD && (
              <>
                <h1 className="text-primary">
                  {languageHandler(
                    "address",
                    "thanks-text",
                    this.props.language
                  )}
                  {/* Thank you for placing your order! */}
                </h1>
                <p>
                  {languageHandler(
                    "email",
                    "check-email-reminder",
                    this.props.language
                  )}
                  {/* You should receive a confirmation email as well as updates on
									your order status. These emails may sometimes be sent to your
									spam folder. */}
                </p>

                <div className="container">
                  <Link to="/store" className="btn btn-primary">
                    {languageHandler(
                      "contact",
                      "form-return-button-text",
                      this.props.language
                    )}
                    {/* Return to the store */}
                  </Link>
                </div>
              </>
            )}

            {!this.state.isCOD && (
              <>
                <Container>
                  <h4>
                    {languageHandler(
                      "banking",
                      "transfer-selection-text",
                      this.props.language
                    )}
                    {/* You have selected a bank transfer option */}
                  </h4>
                  <p>
                    {languageHandler(
                      "banking",
                      "payment-instruction-text",
                      this.props.language
                    )}
                    {/* Please make your payment to the following bank account: */}
                  </p>

                  <Row>
                    <Col
                      className={"text-md-right"}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      {languageHandler(
                        "banking",
                        "acc-number-label",
                        this.props.language
                      )}
                      {/* Account Number */} :
                    </Col>
                    <Col
                      className={"text-md-left"}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <strong>0325 968 059356</strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className={"text-md-right"}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      {languageHandler(
                        "banking",
                        "acc-name-label",
                        this.props.language
                      )}
                      {/* Accountholder's Name: */}
                    </Col>
                    <Col
                      className={"text-md-left"}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <strong>DUPLESSIS JAKOB FREDERIK</strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className={"text-md-right"}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      {languageHandler(
                        "banking",
                        "bank-name-label",
                        this.props.language
                      )}
                      {/* Bank Name */}:
                    </Col>
                    <Col
                      className={"text-md-left"}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <strong>
                        {languageHandler(
                          "banking",
                          "bank-name-text",
                          this.props.language
                        )}
                        {/* Esun */}
                      </strong>{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className={"text-md-right"}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      {languageHandler(
                        "banking",
                        "bank-code-label",
                        this.props.language
                      )}
                      :{/* Bank Code */}
                    </Col>
                    <Col
                      className={"text-md-left"}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <strong>808</strong>{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className={"text-md-right"}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      {languageHandler(
                        "banking",
                        "payment-ref-label",
                        this.props.language
                      )}
                      {/* Reference */}:
                    </Col>
                    <Col
                      className={"text-md-left"}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <strong>{this.state.orderReference}</strong>
                    </Col>
                  </Row>
                </Container>
                <br />
                <p>
                  {languageHandler(
                    "email",
                    "message-reference-number",
                    this.props.language
                  ).replace("ORDER_NUMBER", this.state.orderReference)}
                </p>
                <p>
                  {languageHandler(
                    "email",
                    "message-transfer-confirmation",
                    this.props.language
                  )}
                  {/* In order to speed up the processing of your order. Please
									reply to our email with confirmation of your payment, or the
									last four digits of your account number. */}
                </p>

                <p>
                  {languageHandler(
                    "email",
                    "check-email-reminder",
                    this.props.language
                  )}
                  {/*  You should receive a confirmation email
									as well as updates on your order status. These emails may
									sometimes be sent to your spam folder. */}
                </p>
                <div className="container">
                  <Link to="/store" className="btn btn-primary">
                    {languageHandler(
                      "contact",
                      "form-return-button-text",
                      this.props.language
                    )}
                    {/* Return to the store */}
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      );
    }

    if (this.props.orderPostError) {
      mainContent = (
        <div className="container p-5 m-auto">
          <div className="card text-center p-5">
            <ErrorComponent
              errorCode={this.props.orderPostError.rampantKey}
              errorMessage={this.props.orderPostError.error}
              instruction={`${this.props.orderPostError.message}, ${
                this.props.orderPostError.message ===
                "timeout of 30000ms exceeded"
                  ? "for an unforeseen reason, please try to submit your order again and if the problem persists, please report this error"
                  : "however we have a reference to your order and we will email you to confirm your order with you. Do not try to resubmit your order as we may receive it more than once."
              }`}
              linkRoute={"/store"}
              linkDestination={"the store"}
              submitFailedOrder={this.onFailedOrderEmailSend}
              failedOrderSent={this.state.failedOrderSent}
            />
            {this.state.failedOrderSent && (
              <Alert variant="success">
                Message submitted successfully, we will email you to confirm
                your order.
              </Alert>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className={` bg-primary`}>
        <Navigation />
        <div className={classes.Checkout}>{mainContent}</div>
        <Footer />
      </div>
    );
  }
}

// maps state to props from redux
const mapStateToProps = (state) => {
  return {
    products: state.store.products, //this is an object
    keyArr: state.store.keyArr, //this is an array
    basket: state.store.basket, //this is an array
    loading: state.store.loading, // this is a boolean
    totalPrice: state.store.totalPrice, // this is a number
    promoCodes: state.admin.promoCodes, // this is an object
    language: state.store.language, //this is a number that represents the language
    orderPostError: state.store.orderPostError,
    orderPosted: state.store.orderPosted,
    orderPosting: state.store.orderPosting,
  };
};
// maps actions to props from redux
const mapDispatchToProps = (dispatch) => {
  return {
    // increment item qty
    incrementCartItem: (prodKey, basket, itemPrice, totalPrice) =>
      dispatch(
        actions.incrementCartItem(prodKey, basket, itemPrice, totalPrice)
      ),
    // decrement item qty
    decrementCartItem: (prodKey, basket, itemPrice, totalPrice) =>
      dispatch(
        actions.decrementCartItem(prodKey, basket, itemPrice, totalPrice)
      ),
    // remove item
    removeCartItem: (prodKey, basket, itemPrice, totalPrice) =>
      dispatch(actions.removeCartItem(prodKey, basket, itemPrice, totalPrice)),
    submitOrder: (order, products, basket) => {
      dispatch(actions.submitOrder(order, products, basket));
    },
    fetchProducts: () => dispatch(actions.fetchProducts()),
    fetchPromoCode: () => dispatch(actions.fetchPromoCode()),
    usePromoCode: (promoCodes, promoCode) =>
      dispatch(actions.editPromoCode(promoCodes, promoCode)),
    updateTotalPrice: (newTotalPrice) =>
      dispatch(actions.updateTotalPrice(newTotalPrice)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
