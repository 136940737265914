import * as actions from "../../store/actions/index";
import React, { Component } from "react";
import { connect } from "react-redux";
import SingleOrderCard from "../../components/Orders/Order/SingleOrderCard";
import { Accordion, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import Navigation from "../../components/Navigation/Navigation";
import classes from "./Orders.module.css";
import { Redirect } from "react-router-dom";
export class Orders extends Component {
	state = {
		arrayToUse: [],
		listArr: [],
		currentPage: 1,
		allOrders: [],
		filteredOrders: [],
		OrdersPerPage: 15,
		statuses: [
			"newOrder",
			"awaitingPayment",
			"followUp",
			"inTransit",
			"completed",
			"cancelled",
		],
	};

	getStatusName = (status) => {
		switch (status) {
			case "newOrder":
				return "New Order";
			case "awaitingPayment":
				return "Awaiting Payment";
			case "followUp":
				return "Follow Up";
			case "inTransit":
				return "In Transit";
			case "completed":
				return "Completed";
			case "cancelled":
				return "Cancelled";
			default:
				return "All Orders";
		}
	};

	componentDidMount() {
		this.props.onFetchProducts();
		this.props.onFetchOrders();
		//take the orders which is an object of objects and mutate it to be an array of objects
		let listArr = [];

		this.props.isSignedIn &&
			setTimeout(() => {
				if (this.props.orders != null || this.props.orders !== undefined) {
					const obj = this.props.orders;
					const arr = Array.from(Object.keys(obj), (k) => [`${k}`]);

					const unorderedArrObj = [];
					arr.map((objItem) => unorderedArrObj.push(obj[objItem]));

					// sorts the array of objects by alphabetical order, and names of order status is named in such a way that alphabetical sorting is the correct order for order statuses
					const orderedArrObj = unorderedArrObj.sort((a, b) =>
						a.order.status < b.order.status ? 1 : -1
					);
					this.setState({
						filteredOrders: orderedArrObj,
						allOrders: orderedArrObj,
					});
					// splits the orders array into the number of pages as specified in the state and can be maniuplated if needed

					const a = [...this.state.allOrders];
					while (a.length) {
						listArr.push(a.splice(0, this.state.OrdersPerPage));
					}
				}

				//sets the initial state to show the first page of orders
				this.setState({
					currentPage: 1,
					arrayToUse: listArr[0],
					listArr: listArr,
				});
				if (this.props.preFilter !== (null || undefined)) {
					this.filterOrdersHandler(this.props.preFilter);
				}
			}, 500);
	}

	// manipulates the current page number
	switchPageHandler = (pageNumber) => {
		this.setState({
			currentPage: pageNumber,
			arrayToUse: this.state.listArr[pageNumber - 1],
		});
	};

	filterOrdersHandler = (type) => {
		let listArr = [];

		let filteredArr = [];
		type === ""
			? (filteredArr = this.state.allOrders)
			: (filteredArr = this.state.allOrders.filter((order) => {
					return type === order.order.status;
			  }));
		this.setState({ filteredOrders: filteredArr }, () => {
			const a = [...this.state.filteredOrders];
			while (a.length) {
				listArr.push(a.splice(0, this.state.OrdersPerPage));
			}

			//sets the initial state to show the first page of orders
			this.setState({
				currentPage: 1,
				arrayToUse: listArr[0],
				listArr: listArr,
				currentFilter: type,
			});
		});
	};

	/**
	 * props.status === "cancelled"
						? "#f8d7da" //red
						: props.status === "completed"
						? "#f7f7f7" //faded grey
						: props.status === "newOrder"
						? "#5cb85c" //green
						: props.status === "inTransit"
						? "#5bc0de" //blue
						: "#FFF3CD", //yellow
	 */

	render() {
		let OrderCardList, PageNumberList;
		//checks for a null value and then maps the current pages array into single order card components
		OrderCardList =
			this.state.arrayToUse &&
			this.state.arrayToUse.map((a) => {
				return (
					<SingleOrderCard
						key={a.orderKey}
						name={a.order.contactInfo.Name}
						contactInfo={a.order.contactInfo}
						orderKey={a.orderKey}
						basket={a.order.basket}
						status={a.order.status}
						order={a.order}
						shippingOption={a.order.shippingOption}
						orderDate={a.order.orderDate}
						totalPrice={a.order.totalPrice}
						paymentMethod={a.order.paymentMethod}
						{...this.props}
						orderReference={a.order.orderReference}
						currentFilter={this.state.currentFilter}
						promoCode={a.order.promoCode}
						discount={a.order.discount}
						orderMessage={a.order.orderMessage && a.order.orderMessage}
					></SingleOrderCard>
				);
			});

		// checks for null state and then maps the list of page numbers to be rendered below the current page contents
		PageNumberList =
			this.state.listArr &&
			this.state.listArr.map((page, idx) => {
				return (
					<Button
						key={idx}
						onClick={(pageNumber) => this.switchPageHandler(idx + 1)}
					>{`${idx + 1}`}</Button>
				);
			});

		const statusSelectOptions = this.state.statuses.map((status, idx) => {
			return (
				<Dropdown.Item
					className="m-1"
					key={idx}
					onClick={(type) => this.filterOrdersHandler(`${status}`)}
				>
					{this.getStatusName(status)}
				</Dropdown.Item>
			);
		});

		const statusFilterButtons = this.state.statuses.map((status, idx) => {
			return (
				<Button
					className="m-1"
					key={idx}
					onClick={(type) => this.filterOrdersHandler(`${status}`)}
				>
					{this.getStatusName(status)}
				</Button>
			);
		});

		const mainContent = (
			<>
				<Navigation />
				<div
					className={classes.FilterButtons + " container text-center mt-2 mb-2"}
				>
					{statusFilterButtons}
					<Button
						onClick={(type) => {
							this.filterOrdersHandler("");
						}}
					>
						All Orders
					</Button>
				</div>
				<div
					className={classes.SelectOptions + " container text-center mt-2 mb-2"}
				>
					<DropdownButton id="dropdown-basic-button" title="Filter">
						{statusSelectOptions}
						<Dropdown.Item
							onClick={(type) => {
								this.filterOrdersHandler("");
							}}
						>
							All Orders
						</Dropdown.Item>
					</DropdownButton>
				</div>
				<Accordion>{OrderCardList}</Accordion> <br />
				<div className={"container text-center"}>
					{PageNumberList}{" "}
					<Button
						onClick={(pageNumber) =>
							this.switchPageHandler(this.state.currentPage + 1)
						}
					>
						<ArrowRightCircle />
					</Button>
				</div>
			</>
		);

		return (
			<>{this.props.isSignedIn ? mainContent : <Redirect to="/login" />}</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		orders: state.orders.orders,
		loading: state.orders.loading,
		isSignedIn: state.auth.isSignedIn,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrders: () => dispatch(actions.fetchOrders()),
		onFetchProducts: () => dispatch(actions.fetchProducts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
